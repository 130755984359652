import React, { FunctionComponent } from "react";
import {
  MenuContentItem,
  AppContentItem,
} from "../../../../../providers/MenuProvider/types";
import styles from "./ItemTile.module.css";

interface Props {
  active?: boolean;
  item: MenuContentItem;
}
// Item tiles in the Touch Menu
const TileWithThumbnail = (item: AppContentItem, active?: boolean) => {
  const isCanvas = item.slug === "canvas";
  return isCanvas ? (
    <div
      className={`${styles.imageTile} ${active ? styles.active : ""}`}
      style={{ backgroundImage: `url(${item.thumbnail})` }}
    ></div>
  ) : (
    <div
      className={`${styles.appIconContainer} ${active ? styles.active : ""}`}
    >
      {item.thumbnail ? <img src={item.thumbnail} alt={item.type} /> : null}
    </div>
  );
};

const ItemTile: FunctionComponent<Props> = ({ item, active }: Props) => {
  switch (item.type) {
    case "app":
      return TileWithThumbnail(item, active);
    case "link":
    case "site":
    case "file":
      return (
        <div
          className={`${styles.imageTile} ${active ? styles.active : ""}`}
          style={{ backgroundImage: `url(${item.thumbnail})` }}
        ></div>
      );

    default:
      return <></>;
  }
};

export default ItemTile;
