import { RemoteInterface } from "@screencloud/postmessage-interface";
import { getLogger } from "./debugLog";

const log = getLogger("hc");

class HealthCheck {
  private static _instance: HealthCheck;
  private remote?: RemoteInterface;
  private intervalRef? = 0;
  private timeInterval = 5 * 1000; // 5s
  private readonly standalone: boolean;

  static getInstance(): HealthCheck {
    if (!HealthCheck._instance) {
      HealthCheck._instance = new HealthCheck();
    }
    return HealthCheck._instance;
  }

  constructor() {
    this.standalone = window.parent === window;
  }

  isStandalone(): boolean {
    return this.standalone;
  }

  setRemoteApi(remote: RemoteInterface): void {
    this.remote = remote;
  }

  start(): void {
    if (this.isStandalone()) {
      return;
    }
    this.stop();
    log("start");
    this.intervalRef = window.setInterval(
      this.report.bind(this),
      this.timeInterval
    );
  }

  stop(): void {
    log("stop");
    if (this.intervalRef) {
      window.clearInterval(this.intervalRef);
      this.intervalRef = undefined;
    }
  }

  report(): void {
    const dataReport = { report_time: Date.now() };
    log("call remote healthReport", dataReport);

    this.remote
      ?.call("healthReport", [dataReport])
      .then(() => {
        log("sent report");
      })
      .catch((e) => {
        if (e?.toString().indexOf("missing api method") > -1) {
          this.stop(); // force stop
        }
      });
  }
}

export default HealthCheck;
