import React from "react";
import Slider from "react-slick";
import { MenuContentItem } from "../../../../../providers/MenuProvider/types";
import { DEFAULT_SLIDER_MENU_PADDING } from "../../../constants";
import styles from "./Playlist.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemTile from "../ItemTile";

export const PlaylistSwiper = ({
  playlistItems,
  allItems,
  navigateToItem,
  currentIndex,
}: {
  playlistItems?: MenuContentItem[];
  allItems: MenuContentItem[];
  navigateToItem: (itemIndex: number) => void;
  currentIndex: number;
}) => {
  let isDragging = false;
  const itemsToShow = playlistItems ? playlistItems : allItems;
  return (
    <Slider
      slidesToShow={(window.innerWidth - DEFAULT_SLIDER_MENU_PADDING) / 176}
      className={styles.mySwiper}
      arrows={false}
      infinite={false}
      swipeToSlide={true}
      beforeChange={() => (isDragging = true)}
      afterChange={() => (isDragging = false)}
    >
      {itemsToShow.map((item, index) => {
        const active =
          allItems.findIndex((element) => element === item) === currentIndex;
        return item.type !== "void" ? (
          <div
            key={item.id + index}
            onClick={() => {
              if (!isDragging) {
                navigateToItem(
                  allItems.findIndex((element) => {
                    if (element.type !== "void") {
                      return element.listId === item.listId;
                    }
                    return false;
                  })
                );
              }
            }}
            className={styles.itemTileWrapper}
          >
            <ItemTile item={item} active={active} />

            <div
              className={`${styles.itemLabel} ${active ? styles.active : ""}`}
            >
              <span>{item.name}</span>
            </div>
          </div>
        ) : (
          <div />
        );
      })}
    </Slider>
  );
};
