import { useCallback, useState } from "react";
import { Logger } from "../../../../logger/logger";

const log = new Logger("getTimeOffset");

interface TimeOffset {
  hasError: boolean;
  getTimeOffset: () => void;
  timeOffset: number | undefined;
}

export const useTimeOffset = (timeServerUrl: string): TimeOffset => {
  const [hasError, setHasError] = useState(false);
  const [timeOffset, setTimeOffset] = useState<number | undefined>(undefined);
  const getTimeOffset = useCallback(async () => {
    try {
      const totalCalls = 3;
      let totalCalculateTimeOffset = 0;
      let totalTravelTime = 0;
      for (let i = 0; i < totalCalls; i++) {
        const requestStartTime = new Date().getTime();
        const response = await window.fetch(
          `${timeServerUrl}?nc=${new Date().getTime()}`
        );

        const requestEndTime = new Date().getTime();
        const timeServerResponse: number = await response.json();
        const responseTravelTime = Math.round(
          (requestEndTime - requestStartTime) / 2
        );

        let calculateTimeOffset = timeServerResponse - requestStartTime;
        totalTravelTime += responseTravelTime;
        totalCalculateTimeOffset += calculateTimeOffset;
      }

      let finalTimeOffset = Math.round(
        totalCalculateTimeOffset / totalCalls - totalTravelTime / totalCalls
      );
      if (finalTimeOffset <= 50) {
        finalTimeOffset = 0;
      }

      setHasError(false);
      setTimeOffset(finalTimeOffset);
    } catch (err) {
      log.warn({
        message: `Failed to fetch ${timeServerUrl}: ${err}`,
        context: {
          timeServerUrl: timeServerUrl,
        },
        proofOfPlayFlag: true,
      });
      setHasError(true);
      setTimeOffset(undefined);
    }
  }, [timeServerUrl]);

  return {
    hasError,
    getTimeOffset,
    timeOffset,
  };
};
