import { EntityType } from "@screencloud/signage-firestore-client";

export interface LiveUpdatesState {
  byId: {
    [compositeId: string]: LiveUpdateItem;
  };
}

export interface LiveUpdateItem {
  id: string; // composite unique id
  timestamp: number;
  isLoaded: boolean; // todo: should we just remove the item if it's already loaded??
}

export type LiveUpdateItemType =
  | EntityType.APP_INSTANCE
  | EntityType.FILE
  | EntityType.CHANNEL
  | EntityType.PLAYLIST
  | EntityType.SCREEN
  | EntityType.LINK
  | EntityType.SPACE
  | EntityType.LAYOUT
  | EntityType.THEME;

export const ADD_LIVE_UPDATE_NOTIFICATION = "ADD_LIVE_UPDATE_NOTIFICATION";
export const LIVE_UPDATE_LOADED = "LIVE_UPDATE_LOADED";
export const CLEANUP_NOTIFICATIONS = "CLEANUP_NOTIFICATIONS";

export type LiveUpdateAction =
  | AddNotificationAction
  | LiveUpdateLoadedAction
  | CleanupNotificationsAction;

export type AddNotificationAction = {
  type: typeof ADD_LIVE_UPDATE_NOTIFICATION;
  payload: {
    id: string;
    timestamp: number;
  };
};

export type LiveUpdateLoadedAction = {
  type: typeof LIVE_UPDATE_LOADED;
  payload: {
    id: string;
  };
};

export type CleanupNotificationsAction = {
  type: typeof CLEANUP_NOTIFICATIONS;
  payload: {
    ids: string[];
  };
};
