import { NormalizationUtilities } from "../storeTypes";
import { fileNormalization } from "../files/normalization";
import {
  getEmptyTraversalObject,
  mergeTraversalResult,
} from "../normalizationUtils";
import { PlayerState } from "../rootReducer";
import { FileFragment } from "../../queries";
import { NormalizedSiteFragment } from "./types";
import { siteSchema } from "../normalizationSchemas";

export const siteNormalization: NormalizationUtilities = {
  schema: siteSchema,
  traverseFunction: (siteId: string, state: PlayerState) => {
    let result = getEmptyTraversalObject();
    result.sites.add(siteId);

    const targetSite = state.sites.byId[siteId];

    if (!targetSite) {
      return result;
    }

    if (targetSite.fileByThumbnailId) {
      result = mergeTraversalResult(
        result,
        fileNormalization.traverseFunction(targetSite.fileByThumbnailId, state)
      );
    }

    return result;
  },
};

export type NormalizedSiteEntities = {
  files: { [key: string]: FileFragment } | null;
  sites: { [key: string]: NormalizedSiteFragment } | null;
};
