import { useCallback } from "react";
import { Timeline, TimelineItem } from "../../../../../store/timelines/types";
import { Dispatch } from "redux";
import { contentFailureAction } from "../../../../../store/timelines/actions";
import { getDateIsoShort, TimeOptions } from "../../../../../utils/timeManager";
import { generateItemsForBreakpoints } from "../../../../../utils/scheduleFilter";
import { DAY_DURATION_MS } from "../../../../../constants";
import {
  ContentItemLeaf,
  ContentList,
} from "../../../../../store/contentLists/types";
import { BreakpointWithItems } from "../../../../../store/playback/types";
import { Logger } from "../../../../../logger/logger";
import { produceTimelineItem } from "../../../../timeline/utils";
import { ContentFailureCallback } from "../types";

const log = new Logger("uesContentFailureCallback");

export type LogFailedContentCb = (
  failedTimelineItem: TimelineItem,
  replacement: TimelineItem[]
) => void;

/**
 * Returns a callback to use to report a timeline item failure for a particular timeline.
 * The callback function figures out the replacement items for a failed item and dispatches the content failure action.
 *
 */
export function useContentFailureCallback(
  timeline: Timeline | undefined, // target timeline
  targetContentList: ContentList | undefined, // content list that was used to generate the target timeline
  timeOptions: TimeOptions,
  dispatch: Dispatch,
  onLogFailure?: LogFailedContentCb
): ContentFailureCallback {
  return useCallback(
    (failedItemIndex: number) => {
      if (!timeline || !targetContentList) {
        return;
      }

      const failedTimelineItem = timeline.items[failedItemIndex];
      const previousItem = timeline.items[failedItemIndex - 1];

      const targetItemEndTime =
        failedTimelineItem.startTimestampMs + failedTimelineItem.showDurationMs;

      const targetContentListItemIndex = targetContentList.items.findIndex(
        (contentListItem) => {
          return (
            contentListItem.type === failedTimelineItem.type &&
            contentListItem.listId === failedTimelineItem.listId &&
            contentListItem.id === failedTimelineItem.id
          );
        }
      );
      const targetContentListItem: ContentItemLeaf | undefined =
        targetContentList.items[targetContentListItemIndex];

      if (!targetContentListItem) {
        log.debug({
          message: `Failed to find replacement items for failed content. Can't find content list item.`,
          context: {
            timelineId: timeline.id,
          },
        });
        throw new Error(
          `Failed to find replacement items for failed content. Can't find content list item.`
        );
      }

      const hasPreviousItemFailed =
        previousItem && previousItem.type !== "void" && previousItem.hasFailed;
      const previousContentListItem:
        | ContentItemLeaf
        | undefined = hasPreviousItemFailed
        ? undefined
        : targetContentList.items.find(
            (contentListItem) =>
              previousItem &&
              contentListItem.type === previousItem.type &&
              contentListItem.listId === previousItem.listId &&
              contentListItem.id === previousItem.id
          );

      const replacementItems = findReplacementItems(
        failedTimelineItem.startTimestampMs,
        targetItemEndTime,
        targetContentListItemIndex,
        previousContentListItem?.listId,
        timeOptions,
        targetContentList
      );

      logContentFailure(failedTimelineItem, replacementItems);

      if (onLogFailure) {
        onLogFailure(failedTimelineItem, replacementItems);
      }

      dispatch(
        contentFailureAction(timeline.id, failedItemIndex, replacementItems)
      );
    },
    [timeline, timeOptions, targetContentList, dispatch, onLogFailure]
  );
}

export function logContentFailure(
  failedTimelineItem: TimelineItem,
  replacementItems: TimelineItem[]
): void {
  const failedTimelineItemString = `Type: ${failedTimelineItem.type}, id: ${
    failedTimelineItem.type !== "void" ? failedTimelineItem.id : undefined
  }`;

  // log first 10 items of the replacement
  const contentReplacementString = replacementItems
    .slice(0, 10)
    .reduce<string>((sum, replacementItem, idx) => {
      return (
        sum +
        `${idx > 0 ? ", " : ""}Type: ${replacementItem.type} Id: ${
          replacementItem.type !== "void" ? replacementItem.id : undefined
        }`
      );
    }, "");
  // keep this as warn to highlight and look back for confirm logic working ok
  log.warn({
    message: `Replacing failed content.`,
    context: {
      failedTimelineItem: failedTimelineItemString,
      replacementItems: contentReplacementString,
    },
  });
}

export function findReplacementItems(
  failedItemStartTime: number,
  failedItemEndTime: number,
  failedItemContentListIndex: number,
  previousItemListId: string | undefined,
  timeOptions: TimeOptions,
  contentList: ContentList
): TimelineItem[] {
  const breakpointsWithItemsForPeriod = getScheduleBreakpointsWithItemsForTimeRange(
    failedItemStartTime,
    failedItemEndTime,
    timeOptions,
    contentList
  );

  const failedContentListItem = contentList.items[failedItemContentListIndex];

  const replacement: TimelineItem[] = breakpointsWithItemsForPeriod.map<
    TimelineItem
  >((breakpointWithItems, idx) => {
    // An iteration of this cycle produces 1 replacement item for 1 schedule period. The duration of a replacement
    //  item is not taken into consideration, it will always occupy the whole schedule period duration.
    //  This simplification is made on purpose and is a subject for future improvements.

    let replacementContentItem: ContentItemLeaf | undefined = undefined;

    const previousContentListItem =
      previousItemListId === undefined
        ? undefined
        : breakpointWithItems.items.find(
            (item) => item.listId === previousItemListId
          );

    if (idx === 0 && previousContentListItem) {
      // if we're in the first schedule period and
      // previous item (an item before the failed one in the timeline) is valid in this schedule period
      // then keep this previous item on screen as a replacement
      replacementContentItem = previousContentListItem;
    }

    // replacement iteration lookup should start at the index of a failed item (to look items ahead instead of looking
    //  from the very beginning of the content list)
    const iterationStartIndex = breakpointWithItems.items.findIndex(
      (item, idx) => {
        return (
          item.listId === failedContentListItem.listId &&
          item.type === failedContentListItem.type &&
          item.id === failedContentListItem.id
        );
      }
    );

    if (iterationStartIndex === -1) {
      throw new Error(
        `Failed content item should be always present in filtering result for each schedule breakpoint within it's own on-screen time frame.`
      );
    }

    let index =
      iterationStartIndex >= breakpointWithItems.items.length - 1
        ? 0
        : iterationStartIndex + 1;

    // This is extra protection from infinite loop
    const maxIterations = breakpointWithItems.items.length + 1;
    let iterationCounter = 0;

    if (window.isNaN(maxIterations) || maxIterations === Infinity) {
      throw new Error(
        `Logic error in content failure callback. Max iterations is not a number. Value: ${maxIterations}.`
      );
    }

    while (
      !replacementContentItem &&
      index !== iterationStartIndex &&
      index < breakpointWithItems.items.length &&
      breakpointWithItems.items.length > 1 &&
      iterationCounter <= maxIterations
    ) {
      // look for the replacement item in the filterResult starting from index `i`
      const targetContentListItem: ContentItemLeaf | undefined =
        breakpointWithItems.items[index];

      if (
        targetContentListItem &&
        targetContentListItem.listId !== failedContentListItem.listId
      ) {
        replacementContentItem = targetContentListItem;
      }

      if (index + 1 < breakpointWithItems.items.length) {
        index++;
      } else {
        index = 0;
      }

      iterationCounter++;
    }

    let replacementTimelineItem: TimelineItem;

    const replacementItemStart: number =
      failedItemStartTime > breakpointWithItems.breakpointTimestamp
        ? failedItemStartTime
        : breakpointWithItems.breakpointTimestamp;
    const nextFilterResult: BreakpointWithItems | undefined =
      breakpointsWithItemsForPeriod[idx + 1];
    const currentFilterPeriodEndTime =
      nextFilterResult &&
      nextFilterResult.breakpointTimestamp < failedItemEndTime
        ? nextFilterResult.breakpointTimestamp
        : failedItemEndTime;
    const replacementItemEnd: number = currentFilterPeriodEndTime;
    const replacementItemActualDuration: number =
      replacementItemEnd - replacementItemStart;

    if (!replacementContentItem) {
      replacementTimelineItem = {
        type: "void",
        isInfinite: false,
        fullDurationMs: replacementItemActualDuration,
        showDurationMs: replacementItemActualDuration,
        startTimestampMs: breakpointWithItems.breakpointTimestamp,
        breakpointId: breakpointWithItems.id,
      };
    } else {
      const startTimestamp = breakpointWithItems.breakpointTimestamp;
      const isInfinite = false;
      const showDurationMs = replacementItemActualDuration;

      replacementTimelineItem = produceTimelineItem(
        replacementContentItem,
        startTimestamp,
        isInfinite,
        showDurationMs,
        breakpointWithItems.id
      );
    }

    return replacementTimelineItem;
  });

  return replacement;
}

/**
 * Gets schedule breakpoints within the time range (including the time range boundaries)
 * Returns a sorted array.
 */
function getScheduleBreakpointsWithItemsForTimeRange(
  startTimestamp: number,
  endTimestamp: number,
  timeOptions: TimeOptions,
  contentList: ContentList
): BreakpointWithItems[] {
  const numberOfDays = Math.ceil(
    (endTimestamp - startTimestamp) / DAY_DURATION_MS
  );

  let result: BreakpointWithItems[] = [];

  for (let i = 0; i < numberOfDays; i++) {
    const targetDay = getDateIsoShort(
      timeOptions,
      startTimestamp + DAY_DURATION_MS * i
    );
    const breakpointsWithItems = generateItemsForBreakpoints(
      contentList,
      timeOptions,
      targetDay
    );
    result = result.concat(breakpointsWithItems);
  }

  const filteredResult: BreakpointWithItems[] = [];

  result.forEach((item, idx) => {
    if (
      item.breakpointTimestamp <= startTimestamp &&
      result[idx + 1]?.breakpointTimestamp > startTimestamp
    ) {
      filteredResult.push({
        ...item,
        breakpointTimestamp: startTimestamp,
      });
    } else if (
      item.breakpointTimestamp > startTimestamp &&
      item.breakpointTimestamp < endTimestamp
    ) {
      filteredResult.push(item);
    }
  });

  return filteredResult;
}
