import axios from "axios";

import {
  Maybe,
  UpdateScreenDeviceInfoMutationVariables,
  updateScreenDeviceInfo,
} from "../../queries";
import { ConfigState } from "../../store/config/types";
import { PERSIST_KEY_PREFIX, ROOT_STORAGE_KEY } from "../../store/rootReducer";
import RemoteApi from "../../remoteApi";
import { DeviceInfo } from "../../remoteApi.d";

import { isLoadedInPairedDevice } from "..";

const getStudioPlayerConfigState = (): ConfigState => {
  const studioPlayerReduxPersistKey = `${PERSIST_KEY_PREFIX}${ROOT_STORAGE_KEY}`;
  const playerState = JSON.parse(
    localStorage[studioPlayerReduxPersistKey] ?? `{}`
  );
  return JSON.parse(playerState.config ?? "{}");
};

export const updateDeviceInfo = async () => {
  const config = getStudioPlayerConfigState();
  if (!config.graphqlEndpoint || !config.graphqlToken) {
    console.error(
      `Couldn't report screen device info. Missing graphqlEndpoint or graphqlToken. ${JSON.stringify(
        config
      )}`
    );
    return;
  }

  const isInPairedDevice = isLoadedInPairedDevice(
    config.contentConfig,
    config.device,
    config.graphqlToken
  );
  if (!isInPairedDevice) {
    return; // eg. Preview mode
  }

  const remoteApi = new RemoteApi();
  const deviceInfo = await remoteApi.getDeviceInfoFromJSPlayer();
  await updateSelfScreenDeviceInfoMutation(config, deviceInfo);
};

const updateSelfScreenDeviceInfoMutation = async (
  config: ConfigState,
  deviceInfo: Maybe<DeviceInfo>
) => {
  const variables: UpdateScreenDeviceInfoMutationVariables = {
    input: { deviceInfo },
  };

  const requestBody = {
    query: updateScreenDeviceInfo,
    variables,
  };
  try {
    await axios.post(config.graphqlEndpoint, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.graphqlToken}`,
      },
    });
  } catch (err) {
    // @ts-ignore
    console.error("Failed to update screen device info", err.message);
  }
};
