import React, { FunctionComponent } from "react";
import { useEmbedMenu } from "../../../../../providers/MenuProvider/MenuProvider";
import { MenuViews } from "../../../../../providers/MenuProvider/types";
import GridIcon from "../../../../../img/grid-view.svg";
import styles from "./Playlist.module.css";
import { PlaylistSwiper } from "./PlaylistSwiper";

const AllItemsPlaylist: FunctionComponent = () => {
  const { items, navigateToItem, setView, currentIndex } = useEmbedMenu();

  return (
    <div className={styles.playlistRowContainer}>
      <div className={styles.playlistHeader}>
        <div
          className={styles.playlistName}
          onClick={() => {
            setView(MenuViews.GRID);
          }}
        >
          <img src={GridIcon} className={styles.gridIcon} alt="Images" />
          All Items
        </div>
      </div>

      <PlaylistSwiper
        allItems={items}
        navigateToItem={navigateToItem}
        currentIndex={currentIndex}
      />
    </div>
  );
};

export default AllItemsPlaylist;
