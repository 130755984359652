import React from "react";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Logger } from "../../../../logger/logger";
import { ChannelsState } from "../../../../store/channels/types";
import { LayoutsState } from "../../../../store/layouts/types";
import {
  PlaybackState,
  TimelinePlaybackState,
} from "../../../../store/playback/types";
import { PlayerState } from "../../../../store/rootReducer";
import { updateCurrentActiveItemWithChild } from "../../../../store/screen/actions";
import { ActiveItem, ScreenState } from "../../../../store/screen/types";
import { TimelinesState } from "../../../../store/timelines/types";

export const log = new Logger("timelinePlayBackLogs");

interface TimelinePlayback {
  [key: string]: TimelinePlaybackState;
}

interface Timeline {
  id: string;
  zone: string;
}

export const ActiveContentContainer = (): ReactElement => {
  const playbackState = useSelector<PlayerState, PlaybackState>(
    (state) => state.playback
  );
  const timelines = useSelector<PlayerState, TimelinesState>(
    (state) => state.timelines
  );

  const layouts = useSelector<PlayerState, LayoutsState>(
    (state) => state.layouts
  );
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const channels = useSelector<PlayerState, ChannelsState>(
    (state) => state.channels
  );

  const [previousTimelinesPlayback, setPreviousTimelinesPlayback] = useState<
    TimelinePlayback
  >({});

  const activeContentItem = screen?.activeContentItem;
  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.config.contentConfig.isPreview
  );
  const activeTimelinesWithZoneData = useMemo<Timeline[]>(() => {
    if (!activeContentItem || !activeContentItem.type) return [];

    if (activeContentItem.type === "channel") {
      const channelById = channels.byId[activeContentItem.id];
      const zones =
        (channelById && layouts.byId[channelById.layoutId]?.zones) || [];
      return zones.map((zone) => {
        return {
          zone: zone.id,
          id: zone.contentListId,
        };
      });
    } else if (activeContentItem.type === "playlist") {
      return [
        {
          zone: "root",
          id: activeContentItem.id,
        },
      ];
    }
    return [];
  }, [activeContentItem, channels, layouts]);

  const activeItems: ActiveItem[] = useMemo(() => [], []);
  if (
    !isPreview &&
    activeContentItem &&
    (activeContentItem.type === "channel" ||
      activeContentItem.type === "playlist")
  ) {
    activeTimelinesWithZoneData.forEach((activeTimeline) => {
      if (
        previousTimelinesPlayback[activeTimeline.id]?.activeIndex !==
        playbackState.timelines[activeTimeline.id]?.activeIndex
      ) {
        const activePlaybackTimeline =
          playbackState.timelines[activeTimeline.id];
        const activeTimelineItem =
          timelines.byId[activeTimeline.id]?.items?.[
            activePlaybackTimeline.activeIndex || 0
          ];

        if (!activeTimelineItem) {
          // Screen is empty. No active item assigned.
          return;
        } else if (activeTimelineItem.type === "void") {
          // Screen is empty. No item assigned to screen.
          return;
        }

        activeItems.push({
          contentId: activeTimelineItem.id,
          contentType: activeTimelineItem.type,
          zoneId: activeTimeline.zone,
        });
      }
    });
  } else {
    log.info({
      message: "No active item assigned.",
      context: {
        isPreview: isPreview,
        playbackType: "timeline",
      },
    });
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (activeItems.length > 0) {
      activeItems.forEach((activeItem) => {
        dispatch(updateCurrentActiveItemWithChild(activeItem));
      });
    }
  }, [dispatch, activeItems]);

  useEffect(() => {
    // reset previous timeline playback state when content item is changed to a single content
    if (
      activeContentItem &&
      activeContentItem.type !== "channel" &&
      activeContentItem.type !== "playlist"
    ) {
      setPreviousTimelinesPlayback({});
    } else {
      if (Object.keys(playbackState.timelines).length === 0) return;
      setPreviousTimelinesPlayback(playbackState.timelines);
    }
  }, [activeContentItem, playbackState.timelines]);
  return <></>;
};
