import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { parseContentListId } from "../../../../store/contentLists/utils";
import { ContentListReportItem, ContentListUpdateReport } from "./types";
import ClientApiContext from "../../../../ClientApiContext";
import {
  ContentItemLeaf,
  ContentList,
} from "../../../../store/contentLists/types";
import { Timeline } from "../../../../store/timelines/types";
import { TimelinePlaybackState } from "../../../../store/playback/types";
import { filteredListWithApplyRulesV2 } from "../../../../utils/scheduleFilter";
import { useTimeOptions } from "../../../../utils/useTimeOptions";

interface ContentListUpdateReportContainerProps {
  contentListId: string;
  timeline: Timeline;
}

function produceReportItem(
  contentListItem: ContentItemLeaf
): ContentListReportItem {
  return {
    id: contentListItem.id,
    listId: contentListItem.listId,
    type: contentListItem.type,
    parentEntityListId: contentListItem.parent?.listId,
    parentEntityId: contentListItem.parent?.id,
  };
}

export const ContentListUpdateReportContainer: FunctionComponent<ContentListUpdateReportContainerProps> = (
  props
) => {
  const [inSchedule, setinSchedule] = useState<boolean[]>([]);
  const clientApiContext = useContext(ClientApiContext);
  const remoteApi = clientApiContext?.remoteApi;
  const { contentListId } = props;

  const targetContentList = useSelector<PlayerState, ContentList | undefined>(
    (state) => state.contentLists.byId[contentListId]
  );
  const targetItems = targetContentList?.items;

  const targetZoneId = useMemo<string | undefined>(() => {
    const parsedContentListId = parseContentListId(contentListId);
    if (parsedContentListId.type !== "channel-zone") {
      return undefined;
    }
    return parsedContentListId.zoneId;
  }, [contentListId]);

  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) => {
    return state.playback.timelines[props.timeline.id];
  });

  const timeoptions = useTimeOptions();

  const activeTimelineItem =
    props.timeline.items[playbackState?.activeIndex ?? -1];

  const report = useMemo<ContentListUpdateReport | undefined>(() => {
    if (!targetItems) {
      return undefined;
    }

    return {
      zoneId: targetZoneId,
      items: targetItems
        .filter((a, index) => inSchedule[index]) // filter  out of schedule items
        .map(produceReportItem),
    };
  }, [inSchedule, targetItems, targetZoneId]);

  useEffect(() => {
    report && remoteApi?.reportContentListUpdate(report);
  }, [remoteApi, report, targetZoneId]);

  // calculate in schedule itenms
  useEffect(() => {
    targetContentList &&
      activeTimelineItem &&
      setinSchedule(
        filteredListWithApplyRulesV2(
          targetContentList.items,
          timeoptions,
          Date.now()
        )
      );
  }, [
    activeTimelineItem,
    activeTimelineItem?.breakpointId,
    targetContentList,
    timeoptions,
  ]);

  return <></>;
};
