import { TimelineItem } from "../timelines/types";
import {
  NEXT_ITEM,
  NextItemAction,
  SET_HAS_PRELOADING_STARTED,
  SET_IS_ACTIVE_ITEM_TRANSITIONING_OUT,
  SET_TIMELINE_CONTROL_OFFSET,
  SET_TIMELINE_PREVIEW_ITEM,
  SetTimelineControlOffsetAction,
  TIMELINE_SKIP_BACK,
  TIMELINE_SKIP_FORWARD,
  TimelinePreviewItem,
  TimelineSetActiveItemTransitioningOutAction,
  TimelineSetHasPreloadingStartedAction,
  TimelineSkipBackAction,
  TimelineSkipForwardAction,
} from "./types";

export function nextItemAction(
  timelineId: string,
  targetTimestamp: number,
  deterministicTimelineStartTimestamp?: number
): NextItemAction {
  return {
    type: NEXT_ITEM,
    payload: {
      timelineId,
      targetTimestamp,
      deterministicTimelineStartTimestamp,
    },
  };
}

export function setTimelineControlOffset(
  offsetValue: number
): SetTimelineControlOffsetAction {
  return {
    type: SET_TIMELINE_CONTROL_OFFSET,
    payload: {
      offsetValue,
    },
  };
}

export function timelineSkipForward(): TimelineSkipForwardAction {
  return {
    type: TIMELINE_SKIP_FORWARD,
  };
}

export function timelineSkipBack(): TimelineSkipBackAction {
  return {
    type: TIMELINE_SKIP_BACK,
  };
}
export function setTimelinePreviewItem(
  previewItem: TimelineItem | null
): TimelinePreviewItem {
  return {
    type: SET_TIMELINE_PREVIEW_ITEM,
    payload: {
      previewItem,
    },
  };
}

export function setActiveItemTransitioningOut(
  value: boolean,
  timelineId: string
): TimelineSetActiveItemTransitioningOutAction {
  return {
    type: SET_IS_ACTIVE_ITEM_TRANSITIONING_OUT,
    timelineId,
    payload: value,
  };
}

export function setHasPreloadingStarted(
  value: boolean,
  timelineId: string
): TimelineSetHasPreloadingStartedAction {
  return {
    type: SET_HAS_PRELOADING_STARTED,
    timelineId,
    payload: value,
  };
}
