import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Logger } from "../../../../logger/logger";
import { Maybe, Scalars } from "../../../../queries";
import { QRCodeConfig } from "../../../../types/qrcode";
import {
  dataValueToQueryString,
  parsingURLWithQueryString,
} from "../../../../utils/helpers";
import { useGetStudioVariables } from "../../../../utils/useGetStudioVariables";
import { useInterval } from "../../../../utils/useInterval";
import { QrCodeViewer } from "../QrCodeViewer/QrCodeViewer";

const log = new Logger("linkViewer");

export interface LinkViewerStandardProps {
  url: string;
  name: string;
  cacheBusting?: Maybe<boolean>;
  autoReload?: Maybe<boolean>;
  autoReloadDurationMs?: Maybe<number>;
  params?: Scalars["JSON"];
  qrcodeEnabled?: boolean | null;
  qrcodeConfig?: QRCodeConfig | null;
}

export const LinkViewerStandard: FunctionComponent<LinkViewerStandardProps> = memo(
  (props) => {
    const {
      url,
      name,
      cacheBusting,
      autoReload,
      autoReloadDurationMs,
      params,
      qrcodeConfig,
      qrcodeEnabled,
    } = props;

    const { resolveStudioVariablesInTargetURL } = useGetStudioVariables();

    const [finalURL, setFinalURL] = useState("");
    const [newQrCodeConfig, setNewQrCodeConfig] = useState<
      QRCodeConfig | null | undefined
    >(qrcodeConfig);

    function getAutoReloadFinalDuration() {
      const finalDuration =
        autoReloadDurationMs && autoReloadDurationMs >= 5000
          ? autoReloadDurationMs
          : 3600000;
      return finalDuration;
    }

    const getFinalURL = useCallback(() => {
      const nocache = cacheBusting ? "nocache=" + new Date().getTime() : null;

      // data value from link advance settings
      const urlQueryStr = dataValueToQueryString(params);

      const finalURL = parsingURLWithQueryString(url, [nocache, urlQueryStr]);

      return finalURL;
    }, [params, cacheBusting, url]);

    const getFinalQrConfig = useCallback(() => {
      // data value from link advance settings
      const urlQueryStr = dataValueToQueryString(params);

      // get final url
      const finalURL = parsingURLWithQueryString(url, [urlQueryStr]);
      if (qrcodeConfig) {
        const newQrConfig = {
          ...qrcodeConfig,
          payload: {
            ...qrcodeConfig.payload,
            target_url: finalURL,
          },
        };
        return newQrConfig;
      }
      return null;
    }, [params, qrcodeConfig, url]);

    useInterval(
      () => {
        setFinalURL(getFinalURL());
      },
      autoReload && autoReloadDurationMs ? getAutoReloadFinalDuration() : 0
    );

    useEffect(() => {
      setFinalURL(getFinalURL());
      if (qrcodeEnabled) {
        setNewQrCodeConfig(getFinalQrConfig());
      }
    }, [getFinalURL, getFinalQrConfig, qrcodeEnabled]);

    useEffect(() => {
      log.info({
        message: `Show Link ${url}`,
        context: {
          name,
          contentType: "Link",
          linkType: "STANDARD",
        },
      });
    }, [url, name]);

    // get final url and wrap with resolve query params for studio variables
    const resolvedURL = resolveStudioVariablesInTargetURL(finalURL);
    const iframe = (
      <>
        {!!qrcodeEnabled && newQrCodeConfig?.payload?.target_url && (
          <QrCodeViewer qrcodeConfig={newQrCodeConfig} />
        )}
        <iframe
          data-testid="link-viewer"
          src={resolvedURL}
          title={name}
          style={{
            border: "none",
            height: "100%",
            width: "100%",
          }}
        />
      </>
    );
    return iframe;
  }
);
LinkViewerStandard.displayName = "LinkViewerStandard";
