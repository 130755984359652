import { normalize } from "normalizr";
import {
  REMOVE_APP_INSTANCE,
  RemoveAppInstanceAction,
  REQUEST_APP,
  REQUEST_APP_SUCCESS,
  RequestAppAction,
  RequestAppSuccessAction,
} from "./types";
import { AppInstanceFragment } from "../../queries";
import {
  appNormalization,
  NormalizedAppInstanceEntities,
} from "./normalization";

export function requestApp(): RequestAppAction {
  return {
    type: REQUEST_APP,
  };
}

/**
 * This action creator will parse the API response into the various normalized objects here.
 * More efficient as each reducer doesn't then have to parse the entire response itself.
 *
 * NB - Normalizr will replace a normalized child with its ID.
 * Look at NormalizedGqlChannel in types.ts as an example of safely informing TS of this.
 */
export function requestAppSuccess(
  appInstanceById: AppInstanceFragment
): RequestAppSuccessAction {
  const normalizedData = normalize<unknown, NormalizedAppInstanceEntities>(
    appInstanceById,
    appNormalization.schema
  );

  const organization = normalizedData.entities.orgs
    ? Object.values(normalizedData.entities.orgs)[0]
    : undefined;

  return {
    type: REQUEST_APP_SUCCESS,
    payload: {
      organization,
      apps: normalizedData.entities.apps || {},
      files: normalizedData.entities.files || {},
      orgs: normalizedData.entities.orgs || {},
      themes: normalizedData.entities.themes || {},
    },
  };
}

export function removeAppInstance(id: string): RemoveAppInstanceAction {
  return {
    type: REMOVE_APP_INSTANCE,
    payload: {
      id,
    },
  };
}
