import { Modify } from "../storeTypes";
import {
  FileFragment,
  LinkFragment,
  LinkType,
  Maybe,
  Scalars,
} from "../../queries";

export interface LinksState {
  byId: {
    [id: string]: Link;
  };
}

export interface Link {
  id: string;
  name: string;
  url: string;
  cloudConfigCredential?: string;
  linkType?: LinkType | undefined | null;
  fileId?: string | undefined | null;
  cacheBusting?: Maybe<boolean>;
  autoReload?: Maybe<boolean>;
  autoReloadDurationMs?: Maybe<number>;
  params?: Scalars["JSON"];
  qrcodeEnabled?: boolean | null;
  qrcodeConfig?: Scalars["JSON"] | null;
}

export const REQUEST_LINK_SUCCESS = "REQUEST_LINK_SUCCESS";

export type LinkAction = RequestLinkSuccessAction;

export interface RequestLinkSuccessAction {
  type: typeof REQUEST_LINK_SUCCESS;
  payload: {
    links: { [id: string]: NormalizedLinkFragment };
    files: { [id: string]: FileFragment };
  };
}

export type NormalizedLinkFragment = Modify<
  LinkFragment,
  {
    fileByFileId: string | null | undefined;
  }
>;
