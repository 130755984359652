import { FileProcessingClientInterface } from "../types/filesClient";
import { IFileUrlOptions } from "@screencloud/signage-files-client/build/classes/FileprocessingClient";
import {
  FileprocessingClient,
  SignedUrlClient,
} from "@screencloud/signage-files-client";
import { produceSecureUrl } from "@screencloud/studio-media-client";

export interface Config {
  imgixDomain: string;
  mediaOrigin: string;
  secureMediaServiceUrl: string | undefined;
}

/**
 * This class is for refactoring purpose. Signage-files-client will be deprecated and replaced by studio-media-client
 */
export class FileProcessingClientWrapped
  implements FileProcessingClientInterface {
  private wrappedClient: FileprocessingClient;
  private secureMediaServiceUrl: string | undefined;

  constructor(config: Config) {
    this.wrappedClient = new FileprocessingClient({
      imgixDomain: config.imgixDomain,
      mediaOrigin: config.mediaOrigin,
      signedUrlClient: new SignedUrlClient({}),
    });
    this.secureMediaServiceUrl = config.secureMediaServiceUrl;
  }

  public getImgixUrl(
    fileKey: string,
    options: IFileUrlOptions,
    secureMediaPolicy?: string
  ) {
    const baseUrl = this.wrappedClient.getImgixUrl(fileKey, options);

    if (secureMediaPolicy && options.size) {
      let urlWithPolicy = produceSecureUrl({
        baseUrl,
        policy: secureMediaPolicy,
      });

      urlWithPolicy = getResizedMedia(
        urlWithPolicy,
        options.size?.h,
        options.size?.w,
        options.sizingType
      );
      return replaceWithSecureURL(
        this.wrappedClient.config,
        urlWithPolicy,
        this.secureMediaServiceUrl
      );
    } else {
      return baseUrl;
    }
  }

  public getMediaUrlForKey(urlKey: string, secureMediaPolicy?: string) {
    const baseUrl = this.wrappedClient.getMediaUrlForKey(urlKey);

    if (secureMediaPolicy) {
      const urlWithPolicy = produceSecureUrl({
        baseUrl,
        policy: secureMediaPolicy,
      });

      return replaceWithSecureURL(
        this.wrappedClient.config,
        urlWithPolicy,
        this.secureMediaServiceUrl
      );
    } else {
      return baseUrl;
    }
  }
}

export const replaceWithSecureURL = (
  config: {
    imgixDomain: string;
    mediaOrigin: string;
  },
  targetUrl: string,
  secureMediaServiceUrl: string | undefined
) => {
  if (!secureMediaServiceUrl) {
    // If secureMediaServiceUrl is not provided, return the original URL
    return targetUrl;
  }

  // Ensure targetUrl is a valid URL
  try {
    new URL(targetUrl);
  } catch (e) {
    console.error("Invalid target URL:", targetUrl);
    return targetUrl; // Return the original URL if it's invalid
  }

  const { imgixDomain, mediaOrigin } = config;
  // Filter out any undefined or empty values
  const validOrigins = [imgixDomain, mediaOrigin].filter(Boolean);
  const targetUrlLower = targetUrl.toLowerCase();
  const domainMatched = validOrigins.some((domain) => {
    const domainLower = domain.toLowerCase();
    // Use startsWith for a more accurate domain match
    // Note: This simplistic approach may need adjustments to handle subdomains correctly
    return (
      targetUrlLower.startsWith(`http://${domainLower}`) ||
      targetUrlLower.startsWith(`https://${domainLower}`) ||
      targetUrlLower.startsWith(domainLower)
    );
  });

  if (domainMatched) {
    const reg = new RegExp("^https?://[^#?/]+", "i"); // Make the regex case-insensitive
    return targetUrl.replace(reg, secureMediaServiceUrl);
  }

  return targetUrl;
};

/**
 * returns resized media url
 */

export const getResizedMedia = (
  url: string,
  height: number,
  width: number,
  fit = "fit"
) => {
  let sizeType = "inside"; // default to inside is equal to clip in imgix
  if (fit === "fill") {
    // cover is equal to crop in imgix
    sizeType = "cover";
  }

  return url?.replace("originals", `${width}x${height}_${sizeType}`);
};
