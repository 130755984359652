import { useTimeOptions } from "./useTimeOptions";
import { useMemo } from "react";
import { playbackNowTimestamp } from "./timeManager";

/**
 * Gives you the initial playback position in milliseconds based on start timestamp of an item
 * Effectively happens on first render only, cause itemStartTimestamp doesn't change unless data has changed.
 *
 * Main drawback: the item has to be preloaded for playback position to be applied precisely. Otherwise loading
 * time may make your content be late and cut in the end (depends on content rendering component's logic).
 */
export function useInitialPlaybackPosition(
  itemStartTimestamp: number | undefined,
  fullDurationMs: number | undefined,
  nowTimestamp?: number
): number {
  const timeOptions = useTimeOptions();

  const initialPlaybackPositionMs = useMemo<number>(() => {
    if (
      itemStartTimestamp === undefined ||
      itemStartTimestamp === Infinity ||
      itemStartTimestamp === -Infinity
    ) {
      return 0;
    }
    const now = nowTimestamp ?? playbackNowTimestamp(timeOptions);
    const timeDiff = now - itemStartTimestamp;

    // make sure the offset is less than fullDurationMs
    const playbackOffset =
      fullDurationMs !== undefined && fullDurationMs !== 0
        ? timeDiff % fullDurationMs
        : timeDiff;

    if (playbackOffset < 0) {
      return 0;
    }

    return playbackOffset;
  }, [timeOptions, itemStartTimestamp, fullDurationMs, nowTimestamp]);

  return initialPlaybackPositionMs;
}
