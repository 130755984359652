import { RequestScreenSuccessAction } from "../screen/types";
import { ScheduleFilterResult } from "../../utils/scheduleFilter";
import { ContentItemLeaf } from "../contentLists/types";
import { TimelineItem } from "../timelines/types";

// Action types
export const NEXT_ITEM = "NEXT_ITEM";
export const SET_TIMELINE_CONTROL_OFFSET = "SET_TIMELINE_CONTROL_OFFSET";
export const TIMELINE_SKIP_FORWARD = "TIMELINE_SKIP_FORWARD";
export const TIMELINE_SKIP_BACK = "TIMELINE_SKIP_BACK";
export const SET_TIMELINE_PREVIEW_ITEM = "TIMELINE_PREVIEW_ITEM";
export const SET_IS_ACTIVE_ITEM_TRANSITIONING_OUT =
  "SET_IS_ACTIVE_ITEM_TRANSITIONING_OUT";
export const SET_HAS_PRELOADING_STARTED = "SET_HAS_PRELOADING_STARTED";

export type PlaybackAction =
  | RequestScreenSuccessAction
  | NextItemAction
  | SetTimelineControlOffsetAction
  | TimelineSkipForwardAction
  | TimelineSkipBackAction
  | TimelinePreviewItem
  | TimelineSetActiveItemTransitioningOutAction
  | TimelineSetHasPreloadingStartedAction;

export interface NextItemAction {
  type: typeof NEXT_ITEM;
  payload: {
    timelineId: string;
    targetTimestamp: number;
    deterministicTimelineStartTimestamp?: number;
  };
}

export interface SetTimelineControlOffsetAction {
  type: typeof SET_TIMELINE_CONTROL_OFFSET;
  payload: {
    offsetValue: number;
  };
}

export type PlaybackState = {
  timelines: TimelinesPlaybackState;
  controls: PlaybackControls;
};

export interface TimelinesPlaybackState {
  [key: string]: TimelinePlaybackState;
}

export interface PlaybackControls {
  timelineOffset: number;
  previewItem: TimelineItem | null; // preview will overide timeline for its duration and then will be cleared
}

export interface TimelinePlaybackState {
  activeIndex: number | undefined; // undefined => timeline is empty
  isActiveItemTransitioningOut: boolean; // indicates if an active is in process of an out transition
  hasPreloadingStarted: boolean; // indicates if preload item should start preloading
  activeScreenTimeMs: number | undefined; // undefined = the item was indicated to stay on screen until further timeline update
  nextIndex: number | undefined;
  id: string;
  nextFragmentRequestTimestamp: number | undefined; // when should the timeline operator request the next timeline fragment
}

export type FilteringMemo = FilterMemoItem[];

export interface FilterMemoItem {
  result: ScheduleFilterResult | undefined;
  filteredListFullDurationMs?: number;
  periodStart: number;
  periodEnd: number;
}

// for use in the timeline generator with list playback control
// store the stage of picked items
export interface ListPlaybackControl {
  listId: string; // listId of the playlist
  items: ContentItemLeaf[]; // the items that available in the breakpoint, this one will get shuffle if playback.mode is 'random'
  timelineItems?: TimelineItem[]; // the timelineItems generated base from items above
  currentPickIdx: number; // default = -1, not start to pick yet
  currentPickCount: number; // default = 0
  playback: {
    mode?: "random" | "sequence";
    pickToPlay?: number;
  };
}

export interface ListIdWithPlaybackControl {
  [list_id: string]: ListPlaybackControl;
}

export interface BreakpointWithItems {
  id: number; // index of breakpoints
  breakpointTimestamp: number; // the point in time of the breakpoint
  items: ContentItemLeaf[]; // the available content items, or link to list with playback control
  totalDurationMs: number; // total duration of available content
  listPlaybackControls?: { [list_id: string]: ListPlaybackControl }; // the optional controls of how each list(s) will be play
}

export interface TimelineSkipForwardAction {
  type: typeof TIMELINE_SKIP_FORWARD;
}

export interface TimelineSkipBackAction {
  type: typeof TIMELINE_SKIP_BACK;
}

export interface TimelinePreviewItem {
  type: typeof SET_TIMELINE_PREVIEW_ITEM;
  payload: {
    previewItem: TimelineItem | null;
  };
}

export interface TimelineSetActiveItemTransitioningOutAction {
  type: typeof SET_IS_ACTIVE_ITEM_TRANSITIONING_OUT;
  timelineId: string;
  payload: boolean;
}

export interface TimelineSetHasPreloadingStartedAction {
  type: typeof SET_HAS_PRELOADING_STARTED;
  timelineId: string;
  payload: boolean;
}
