import { useEffect } from "react";
import {
  playbackNowTimestamp,
  TimeOptions,
} from "../../../../../utils/timeManager";
import { Dispatch } from "redux";
import { PlayerAction } from "../../../../../store/storeTypes";
import { setHasPreloadingStarted } from "../../../../../store/playback/actions";

/**
 * Takes care of setting a flag that indicates an actual preloading start for a preload item.
 */
export function useSetHasPreloadingStarted(
  timelineId: string,
  contentStartTimestamp: number | undefined,
  itemPreloadingDurationMs: number | undefined,
  timeOptions: TimeOptions,
  dispatch: Dispatch<PlayerAction>
): void {
  const contentStart =
    contentStartTimestamp !== undefined ? contentStartTimestamp : 0;

  useEffect(() => {
    const now = playbackNowTimestamp(timeOptions);
    const preloadingStartTime =
      itemPreloadingDurationMs === undefined
        ? undefined
        : contentStart - itemPreloadingDurationMs;
    if (preloadingStartTime !== undefined) {
      const timeUntilPreloadingStart = preloadingStartTime - now;
      if (timeUntilPreloadingStart > 0) {
        dispatch(setHasPreloadingStarted(false, timelineId));

        const timeout = window.setTimeout(() => {
          dispatch(setHasPreloadingStarted(true, timelineId));
        }, timeUntilPreloadingStart);

        return () => {
          window.clearTimeout(timeout);
        };
      } else {
        dispatch(setHasPreloadingStarted(false, timelineId));
      }
    } else {
      dispatch(setHasPreloadingStarted(false, timelineId));
    }
  }, [
    timeOptions,
    contentStart,
    itemPreloadingDurationMs,
    dispatch,
    timelineId,
  ]);
}
