import { ContentViewItem } from "../types/content";
import { ContentConfig } from "../store/config/types";

/**
 * Gets root content item, assigned to a screen (via contentConfig object from state)
 */
export function getRootContent(contentConfig: ContentConfig): ContentViewItem {
  switch (contentConfig.type) {
    case "screen":
      return {
        type: contentConfig.type,
        id: contentConfig.id,
      };
    case "channel":
    case "playlist":
      return {
        type: contentConfig.type,
        id: contentConfig.id,
        activeItems: [],
      };
    case "app":
      return {
        type: contentConfig.type,
        id: contentConfig.id,
        fullDurationMs: Infinity, //Note: Infinity gets transformed to null when sent through PMI. JSON.stringify({fullDurationMs: Infinity}) === "{"fullDurationMs":null}"
      };
    case "editor":
      return {
        type: contentConfig.type,
        id: contentConfig.id,
        fullDurationMs: Infinity, //Note: Infinity gets transformed to null when sent through PMI. JSON.stringify({fullDurationMs: Infinity}) === "{"fullDurationMs":null}"
      };
    case "site":
      return {
        type: contentConfig.type,
        id: contentConfig.id,
        fullDurationMs: Infinity,
      };
  }
}
