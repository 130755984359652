import { datadogLogs } from "@screencloud/datadog-browser-logs";
import { Context } from "@datadog/browser-core";
import { Maybe } from "../queries";
import { actualUtcNow, TimeOptions } from "../utils/timeManager";
import { LogLevel, LogOutput, RemoteLoggerConfig } from "./logger";
import { FEATURE_FLAGS_ENUM } from "../featureFlags";

interface Event {
  level: LogLevel;
  message: string;
  context?: Context;
}

type EventQueues = Event[];

const MAX_QUEUES_LOG = parseInt(
  process.env.REACT_APP_EVENT_QUEUES_LIMIT || "100"
);

export interface GetLogger {
  debug: (message: string, context?: Context) => void;
  info: (message: string, context?: Context) => void;
  warn: (message: string, context?: Context) => void;
  error: (message: string, context?: Context) => void;
}

class DataDogLogger {
  public isInitialized = false;
  public isProofOfPlayLoggingInitialized = false;

  private isEnableRemoteLogger = false;
  private timeOptions: TimeOptions = {
    timeOffset: 0,
    timelineControlOffset: 0,
  };

  // using for store the events that are called before initialized
  private eventQueues: EventQueues = [];

  public init = (
    config: RemoteLoggerConfig,
    featureFlags: Maybe<string>[]
  ): void => {
    this.isEnableRemoteLogger = this.shouldEnableRemoteLogger(featureFlags);

    if (this.isInitialized) return;

    datadogLogs.logger.addContext("proxyHost", config.proxyHost);
    datadogLogs.logger.addContext("platform", config.platform);
    datadogLogs.logger.addContext("deviceId", config.deviceId);
    datadogLogs.logger.addContext("screenId", config.screenId);
    datadogLogs.logger.addContext("orgId", config.orgId);
    datadogLogs.logger.addContext("service", "studio-player");

    this.timeOptions = config.timeOptions;
    this.isInitialized = true;
    this.flushEventQueues();

    // register event to flush buffer when back online
    window.addEventListener("online", () => this.flushEventQueues());
  };

  public log = (level: LogLevel, message: string, context?: Context): void => {
    // push logs to event queue when offline
    if (!!navigator && !navigator?.onLine) {
      this.addEventToQueues({
        level,
        message: "[offline]" + message,
        context,
      });
      return;
    }

    /**
     * handle when events are sent before initialized
     * need to add an event to the event queues to make it be able to sync later
     */

    /**
     * only push datadog events here
     * */
    if (!this.isInitialized) {
      this.addEventToQueues({
        level,
        message,
        context,
      });
      return;
    }

    this.eventLogger(level, message, {
      ...context,
      timestamp: actualUtcNow(this.timeOptions), // start using UTC Time
    });
  };

  public shouldEnableRemoteLogger = (
    featureFlags: Maybe<string>[]
  ): boolean => {
    return featureFlags.includes(FEATURE_FLAGS_ENUM.PLAYER_LOG_ENABLE);
  };

  private addEventToQueues = ({ level, message, context }: Event): void => {
    // need to have a limitation of event stack because it could effect a memory leak
    if (this.eventQueues.length > MAX_QUEUES_LOG) {
      this.eventQueues.shift();
    }

    this.eventQueues.push({
      level,
      message,
      context: {
        ...context,
        timestamp: actualUtcNow(this.timeOptions),
      },
    });
  };

  private flushEventQueues = (): void => {
    this.eventQueues.forEach((event) => {
      this.eventLogger(event.level, event.message, event.context);
    });
    this.clearEventQueues();
  };

  private clearEventQueues = (): void => {
    this.eventQueues = [];
  };

  private eventLogger = (
    level: LogLevel,
    message: string,
    context?: Context
  ): void => {
    if (!this.isInitialized || !this.isEnableRemoteLogger) {
      return;
    }

    switch (level) {
      case LogLevel.Debug:
        this.isEnableRemoteLogger && datadogLogs.logger.debug(message, context);
        break;
      case LogLevel.Info:
        this.isEnableRemoteLogger && datadogLogs.logger.info(message, context);
        break;
      case LogLevel.Warning:
        this.isEnableRemoteLogger && datadogLogs.logger.warn(message, context);
        break;
      case LogLevel.Error:
        // case error always send data to data dog for debugging
        datadogLogs.logger.error(message, context);
        break;
    }
  };

  public setTimeOptions = (timeOptions: TimeOptions): void => {
    this.timeOptions = timeOptions;
  };

  public getEventQueues = (): EventQueues => {
    return this.eventQueues;
  };

  public getLogger = (): LogOutput => {
    return this.log;
  };
}

const datadog = new DataDogLogger();
export default datadog;
