import { InitializeMessagePayload } from "./../../modules/viewers/containers/AppViewer/types";
// Action types
export const SET_CONFIG = "SET_CONFIG";
export const REQUEST_APP_VIEWER_TOKEN_SUCCESS =
  "REQUEST_APP_VIEWER_TOKEN_SUCCESS";

// Export all action types here.
export type ConfigAction = SetConfigAction | RequestAppViewerTokenSuccessAction;

export interface MediaProxyEndpointConf {
  baseUrl: string;
  cacheImages?: boolean;
  cacheVideos?: boolean; // any video but streaming video protocols (ex: mp4 but not HLS)
  cacheStreamingMedia?: boolean; // HLS/Dash
}
export interface ConfigState {
  graphqlEndpoint: string;
  graphqlToken: string;
  secureMediaServiceUrl?: string;
  secureMediaPolicy?: string;
  appViewerToken?: string;
  appManagementToken?: string;
  lastAppViewerTokenSuccess?: number;
  orientation: "landscape" | "portrait";
  timeOffset: number;
  imgixDomain: string;
  mediaProxyEndpoint?: MediaProxyEndpointConf;
  mediacacheBaseUrl?: string;
  linkCloudServiceUrl: string;
  contentConfig: ContentConfig;
  overrideAppInitialize?: Partial<InitializeMessagePayload>;
  spaceId?: string;
  contextConfig?: ContextConfig;
  region: string;
  mediaOrigin: string;
  features?: FeaturesConfig;
  device?: DeviceConfig;
  lokiEndpoint: string;
  version: string;
  rumSampleRate: number;
  // TODO - entryPoint?
  // TODO - environment?
}

export interface ContextConfig {
  [key: string]: unknown;
}

export interface FeaturesConfig {
  offlineStorage: boolean;
  enablePlaybackControlInterface?: boolean;
  menuEnabled?: boolean;
}

export type Platform =
  | "studio"
  | "android"
  | "firetv"
  | "chrome"
  | "ios"
  | "embeddable"
  | "msteams";

export interface DeviceConfig {
  platform?: Platform;
  model?: string;
  version?: string;
}

export type ContentConfig =
  | AppContentConfig
  | SiteContentConfig
  | ScreenContentConfig
  | PlaylistContentConfig
  | ChannelContentConfig;
export interface ScreenContentConfig {
  type: "screen";
  id: string;
  isPreview: boolean;
}
export interface PlaylistContentConfig {
  type: "playlist";
  id: string;
  isPreview: boolean;
}
export interface ChannelContentConfig {
  type: "channel";
  id: string;
  isPreview: boolean;
}
export interface AppContentConfig {
  type: "app" | "editor";
  id: string;
  appId: string;
  isPreview: boolean;
  appData?: unknown;
}
export interface SiteContentConfig {
  type: "site";
  id: string;
  isPreview: boolean;
}
export interface SetConfigAction {
  type: typeof SET_CONFIG;
  payload: Partial<ConfigState>;
}

export interface RequestAppViewerTokenSuccessAction {
  type: typeof REQUEST_APP_VIEWER_TOKEN_SUCCESS;
  payload: {
    appViewerToken: string;
    lastAppViewerTokenSuccess: number;
  };
}
export interface GraphqlToken extends Record<string, string | undefined> {
  screen_id?: string;
  org_id?: string;
  role?: string;
}
