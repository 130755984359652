import { NormalizationUtilities, TraversalResult } from "../storeTypes";
import { spaceNormalization } from "../spaces/normalization";
import { PlayerState } from "../rootReducer";
import {
  getEmptyTraversalObject,
  mergeTraversalResult,
  traverseEntity,
} from "../normalizationUtils";
import {
  FileFragment,
  LayoutFragment,
  SpaceFragment,
  ThemeFragment,
} from "../../queries";
import { NormalizedLinkFragment } from "../links/types";
import { NormalizedSiteFragment } from "../sites/types";
import { NormalizedAppInstanceFragment } from "../apps/types";
import { NormalizedPlaylistFragment } from "../playlists/types";
import { NormalizedChannelFragment } from "../channels/types";
import { NormalizedOrgFragment } from "../organization/types";
import { screenSchema } from "../normalizationSchemas";

export const screenNormalization: NormalizationUtilities = {
  schema: screenSchema,
  traverseFunction: (screenId: string, state: PlayerState): TraversalResult => {
    const targetScreen = state.screen;
    if (targetScreen.id !== screenId) {
      return getEmptyTraversalObject();
    }

    let result: TraversalResult =
      targetScreen.activeContentItem &&
      targetScreen.activeContentItem.type !== "void"
        ? traverseEntity(
            targetScreen.activeContentItem.type,
            targetScreen.activeContentItem.id,
            state
          )
        : getEmptyTraversalObject();

    if (targetScreen.spaceId) {
      result = mergeTraversalResult(
        result,
        spaceNormalization.traverseFunction(targetScreen.spaceId, state)
      );
    }

    return result;
  },
};

export type NormalizedScreenEntities = {
  files: { [key: string]: FileFragment } | null;
  layouts: { [key: string]: LayoutFragment } | null;
  themes: { [key: string]: ThemeFragment } | null;
  links: { [key: string]: NormalizedLinkFragment } | null;
  spaces: { [key: string]: SpaceFragment } | null;
  sites: { [key: string]: NormalizedSiteFragment } | null;
  apps: { [key: string]: NormalizedAppInstanceFragment } | null;
  playlists: { [key: string]: NormalizedPlaylistFragment } | null;
  channels: { [key: string]: NormalizedChannelFragment } | null;
  orgs: { [key: string]: NormalizedOrgFragment } | null;
  cast: unknown | null;
  screen: unknown | null;
};
