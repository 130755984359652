import { useCallback, useEffect, useRef } from "react";
import { Logger } from "../logger/logger";
import { checkTokenErrors, createAppTokenQuery } from "./appTokenHelper";
import { TokenRetryError, TokenType } from "../types/appToken";
import { useDispatch, useSelector } from "react-redux";
import { PlayerState } from "../store/rootReducer";
import { shouldRefetchAppViewerToken } from "./appTokenHelper";
import { useCreateAppViewerToken } from "../queries";
import { requestAppViewerTokenSuccess } from "../store/config/actions";
import { MINUTE_DURATION_MS } from "../constants";
import { generateAppTokenError } from "./generateAppTokenError";
import { getErrorStatusCode } from "./getErrorStatusCode";
import { APIError } from "graphql-hooks";

const TOKEN_REFRESH_INTERVAL = MINUTE_DURATION_MS * 5; // 5 mins
const RETRY_PERIODS_MS = [1000, 2000, 4000, 8000, 16000, 32000, 64000, 128000]; // 2 ** retryCount * 1000
const HTTP_UNAUTHORISED = 401;

const log = new Logger("useGetAppViewerToken");

/**
 * This hook allows you to request an app viewer token with retry
 * This should be a one off request for the appViewerToken at the top level player core container
 * Only if the request fails we retry
 * Will exponentially backoff, once exhausted retries we use the TOKEN_REFRESH_INTERVAL
 * @param spaceId
 * @param screenId
 * @param successCallback - function to call when token is received
 * @param failureCallback - function to call when failure retrieving token
 */
export const useGetAppViewerToken = (
  spaceId: string | undefined,
  screenId: string | undefined,
  successCallback: (retryCount: number) => unknown,
  failureCallback: (error: TokenRetryError) => unknown
): void => {
  const dispatch = useDispatch();
  const query = createAppTokenQuery("viewer", spaceId, screenId);
  const [createAppViewerToken, { data, error }] = useCreateAppViewerToken(
    query
  );
  // reference for the retry count - we don't want to have this cause a rerender in a useeffect
  const retryCountRef = useRef<number>(0);
  const appViewerTokenRequestInProgressRef = useRef<boolean>(false);

  const lastAppViewerTokenSuccess = useSelector<
    PlayerState,
    number | undefined
  >((state) => state.config.lastAppViewerTokenSuccess);

  const fetchToken = useCallback(() => {
    /* FETCH TOKEN LOGIC */
    if (appViewerTokenRequestInProgressRef.current) {
      // Do nothing if request to GQL is in progress
      return;
    }

    if (
      !lastAppViewerTokenSuccess ||
      (lastAppViewerTokenSuccess &&
        shouldRefetchAppViewerToken(lastAppViewerTokenSuccess))
    ) {
      createAppViewerToken();
    }
  }, [lastAppViewerTokenSuccess, createAppViewerToken]);

  useEffect(() => {
    /* FIRST LOAD
      kicks off attempting to fetch the token but only after we have
      a solid spaceId */
    if (!spaceId) {
      return;
    }
    fetchToken();
  }, [spaceId, fetchToken]);

  useEffect(() => {
    /* REFRESH LOOP */
    let refreshInterval: number | null = null;
    // Check every 5 mins if we should refetch the appViewerToken
    refreshInterval = window.setInterval(() => {
      fetchToken();
    }, TOKEN_REFRESH_INTERVAL);

    return (): void => {
      if (refreshInterval !== null) {
        window.clearInterval(refreshInterval);
      }
    };
  }, [fetchToken]);

  const handleOnlineEvent = useCallback(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    /* ONLINE AGAIN */
    window.addEventListener("online", handleOnlineEvent);
    return () => {
      window.removeEventListener("online", handleOnlineEvent);
    };
  }, [handleOnlineEvent]);

  useEffect(() => {
    /* SUCCESS */
    if (data?.createSignedAppViewerJwt) {
      const token = data.createSignedAppViewerJwt.signedAppViewerToken;

      const tokenError = checkTokenErrors(token, TokenType.Viewer);
      if (tokenError) log.error(tokenError);

      if (token) {
        successCallback(retryCountRef.current);
        appViewerTokenRequestInProgressRef.current = false;
        dispatch(requestAppViewerTokenSuccess(token));
      }
      retryCountRef.current = 0;
    }
  }, [data, successCallback, dispatch]);

  useEffect(() => {
    /* FAIL */
    if (error?.fetchError || error?.httpError || error?.graphQLErrors) {
      appViewerTokenRequestInProgressRef.current = false;

      const responseError = generateAppTokenError(error, retryCountRef.current);
      failureCallback(responseError);
      retryCountRef.current++;
    }
  }, [error, failureCallback]);

  useEffect(() => {
    /* RETRY */
    if (error?.fetchError || error?.httpError || error?.graphQLErrors) {
      // Stop retrying based on the options
      // 401 - User has expired session inside studio
      const code = getErrorStatusCode(error as APIError);
      if (code && code === HTTP_UNAUTHORISED) {
        return;
      }
      // Exhausted retries - Let refresh loop kick in
      if (retryCountRef.current >= RETRY_PERIODS_MS.length) {
        return;
      }

      // Retry
      const retryTimeout = window.setTimeout(() => {
        fetchToken();
      }, RETRY_PERIODS_MS[retryCountRef.current]);
      return (): void => {
        window.clearTimeout(retryTimeout);
      };
    }
  }, [error, fetchToken]);
};
