import { NormalizationUtilities } from "../storeTypes";
import { PlayerState } from "../rootReducer";
import { getEmptyTraversalObject } from "../normalizationUtils";
import { layoutSchema } from "../normalizationSchemas";

export const layoutNormalization: NormalizationUtilities = {
  schema: layoutSchema,
  traverseFunction: (layoutId: string, state: PlayerState) => {
    const result = getEmptyTraversalObject();
    result.layouts.add(layoutId);
    return result;
  },
};
