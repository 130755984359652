import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useMemo,
  useCallback,
  FunctionComponent,
  ReactNode,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import ClientApiContext from "../../ClientApiContext";
import LandingMenu from "../../modules/menu/LandingMenu";
import InteractiveMenu from "../../modules/menu/InteractiveMenu";
import { AppsState } from "../../store/apps/types";
import { ContentConfig } from "../../store/config/types";
import { ContentList } from "../../store/contentLists/types";
import { FilesState } from "../../store/files/types";
import { LinksState } from "../../store/links/types";
import { PlayerState } from "../../store/rootReducer";
import { Timeline } from "../../store/timelines/types";
import {
  mapMenuContentItems,
  getCurrentItemId,
  filterItems,
  searchItems,
  loadFont,
} from "./utils";
import {
  MenuContext as MenuContextType,
  MenuViews,
  PlaylistContent,
} from "./types";
import { ContentViewChannelItem, ContentViewItem } from "../../types/content";
import { MenuItemType } from "../../modules/menu/InteractiveMenu/types";
import { SitesState } from "../../store/sites/types";
import { makeTimelineIdForContentList } from "../../store/contentLists/utils";
import { ChannelsState } from "../../store/channels/types";
import styles from "./MenuProvider.module.css";
import { Theme } from "../../store/themes/types";
import useThemeId from "../../utils/useThemeId";

export const MenuContext = createContext<MenuContextType>({
  show: () => null,
  items: [],
  filteredItems: [],
  searchedItems: [],
  currentIndex: 0,
  navigateToItem: () => null,
  navigateToNextItem: () => null,
  navigateToPreviousItem: () => null,
  liveMode: true,
  returnToLiveMode: () => null,
  onClose: () => null,
  toggleFullscreen: () => null,
  isFullscreen: false,
  playlistContent: [],
  setPlaylist: () => null,
  setType: () => null,
  view: MenuViews.MAIN,
  setView: () => null,
});

interface Props {
  children: ReactNode;
}

const MenuProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const [isFullscreen, setIsFullscreen] = useState(
    !window.screenTop && !window.screenY
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [landingMenuVisible, setLandingMenuVisible] = useState(true);
  const [, setMainMenuVisible] = useState(true);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [liveMode, setLiveMode] = useState(true);
  const clientApis = useContext(ClientApiContext);
  const [view, setView] = useState(MenuViews.MAIN);
  const contentConfig = useSelector<PlayerState, ContentConfig>(
    (state) => state.config.contentConfig
  );

  const activeContentItem = useSelector<
    PlayerState,
    ContentViewItem | undefined
  >((state) => state.screen.activeContentItem);
  const secureMediaPolicy = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaPolicy
  );

  const isMenuSupported =
    contentConfig.type === "channel" ||
    (contentConfig.type === "screen" && activeContentItem?.type === "channel");

  const layoutId = useSelector<PlayerState, string>((state) => {
    if (contentConfig.type === "channel") {
      return state.channels.byId[contentConfig.id]?.layoutId || "";
    } else if (contentConfig.type === "screen") {
      // TODO: remove this after testing
      if (activeContentItem?.type === "channel") {
        return state.channels.byId[activeContentItem.id].layoutId;
      }
    }
    return "";
  });
  const contentListId = useSelector<PlayerState, string>(
    (state) => state.layouts.byId[layoutId]?.zones[0].contentListId
  );
  const timeline = useSelector<PlayerState, Timeline | undefined>(
    (state) => state.timelines.byId[makeTimelineIdForContentList(contentListId)]
  );
  const timelineItems = timeline?.items;
  const currentActiveIndex = useSelector<PlayerState, number | undefined>(
    (state) => state.playback.timelines[contentListId]?.activeIndex
  );
  const contentList = useSelector<PlayerState, ContentList | undefined>(
    (state) => state.contentLists.byId[contentListId]
  );
  const isLandingMenuEnabled = useSelector<PlayerState, boolean>(
    (state) =>
      String(state.screen.screenData?.sc_menuEnabled) === "true" &&
      state.screen.screenData?.sc_menuSplashEnabled !== "false" // enable splash screen by default
  );

  const playlistIds = contentList?.nestedPlaylistIds;
  const playlistContent = useSelector<
    PlayerState,
    PlaylistContent[] | undefined
  >((state) => {
    return playlistIds?.map((playlistId) => {
      const playlist = state.playlists.byId[playlistId];
      const contentList = state.contentLists.byId[playlistId];
      return {
        ...contentList,
        name: playlist.name,
      };
    });
  });

  const apps = useSelector<PlayerState, AppsState>((state) => state.apps);
  const files = useSelector<PlayerState, FilesState>((state) => state.files);
  const links = useSelector<PlayerState, LinksState>((state) => state.links);
  const sites = useSelector<PlayerState, SitesState>((state) => state.sites);
  const currentItemId =
    getCurrentItemId(timelineItems || [], currentActiveIndex) || "";

  const [search, setSearch] = useState("");
  const [type, setType] = useState(MenuItemType.All);
  const [playlistId, setPlayListId] = useState("");
  const [selectedPlaylist, setSelectedPlaylist] = useState<PlaylistContent>();

  const channelId = (activeContentItem as ContentViewChannelItem)?.id;
  const themeId = useThemeId(channelId);
  const theme = useSelector<PlayerState, Theme | undefined>((state) =>
    themeId ? state.themes.byId[themeId] : undefined
  );

  if (theme) {
    if (theme.bodyFont) {
      loadFont(theme.bodyFont.family, theme.bodyFont.url);
    }
    if (theme.headingFont) {
      loadFont(theme.headingFont.family, theme.headingFont.url);
    }
  }

  const channels = useSelector<PlayerState, ChannelsState | undefined>(
    (state) => state.channels
  );

  const channelName = useMemo(() => {
    const channelId = (activeContentItem as ContentViewChannelItem)?.id;
    if (channelId) {
      return channels?.byId[channelId]?.name || "";
    } else {
      return "";
    }
  }, [activeContentItem, channels?.byId]);

  const items = useMemo(() => {
    if (contentList) {
      if (contentConfig.type === "channel") {
        return mapMenuContentItems(
          clientApis?.fileProcessingClient,
          contentList?.items,
          apps,
          files,
          links,
          sites,
          secureMediaPolicy
        );
      } else if (
        contentConfig.type === "screen" &&
        activeContentItem?.type === "channel"
      ) {
        // TODO: remove this after testing
        return mapMenuContentItems(
          clientApis?.fileProcessingClient,
          contentList?.items,
          apps,
          files,
          links,
          sites,
          secureMediaPolicy
        );
      }
    }
    return [];
  }, [
    apps,
    files,
    links,
    sites,
    clientApis,
    contentConfig?.type,
    activeContentItem,
    contentList,
    secureMediaPolicy,
  ]);

  const searchedItems = useMemo(() => {
    return searchItems(items, search);
  }, [search, items]);

  const filteredItems = useMemo(() => {
    return filterItems(searchedItems, type, playlistId);
  }, [type, searchedItems, playlistId]);

  useEffect(() => {
    if (isMenuSupported) {
      setCurrentItemIndex(
        items.findIndex(
          (item) => item.type !== "void" && item.listId === currentItemId
        )
      );
    }
  }, [timeline?.items, currentItemId, items, isMenuSupported]);

  useEffect(() => {
    if (search || playlistId) {
      setView(MenuViews.GRID);
    } else {
      setView(MenuViews.MAIN);
    }
  }, [search, playlistId]);

  const show = useCallback(() => {
    setMainMenuVisible(true);
  }, []);

  const hide = useCallback(() => {
    setMainMenuVisible(false);
  }, []);

  const navigateToItem = useCallback(
    (itemIndex: number) => {
      //make this faster
      window.scrollTo({ top: 0, behavior: "smooth" });
      setCurrentItemIndex(itemIndex);
      setLiveMode(false);
      hide();
    },
    [hide]
  );

  useEffect(() => {
    const fullscreenHandler = () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };
    document.addEventListener("fullscreenchange", fullscreenHandler);
    return () => {
      document.removeEventListener("fullscreenchange", fullscreenHandler);
    };
  });

  if (!isMenuSupported) {
    return <>{children}</>;
  }

  const toggleFullscreen = () => {
    // check is full screen already if not request full screen
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      containerRef.current?.requestFullscreen();
    }
  };

  const setPlaylist = (id: string) => {
    setPlayListId(id);
    if (id) {
      setSelectedPlaylist(
        playlistContent?.find((playlist) => playlist.id === id)
      );
      setType(MenuItemType.Playlist);
    } else {
      setSelectedPlaylist(undefined);
      setView(MenuViews.MAIN);
      setType(MenuItemType.All);
    }
  };

  const findIndexForFilteredItemInItemsList = (
    positionInFilteredItems: number
  ) => {
    return items.findIndex((item) => {
      if (item.type !== "void") {
        const filteredListItem = filteredItems[positionInFilteredItems];
        if (
          filteredListItem.type !== "void" &&
          filteredListItem.listId === item.listId
        ) {
          return true;
        }
      }
      return false;
    });
  };

  const navigateToPreviousItem = () => {
    const item = items[currentItemIndex];

    const positionInFilteredItems = filteredItems.findIndex((filteredItem) => {
      if (item.type !== "void") {
        if (
          filteredItem.type !== "void" &&
          filteredItem.listId === item.listId
        ) {
          return true;
        }
      }
      return false;
    });
    navigateToItem(
      findIndexForFilteredItemInItemsList(
        positionInFilteredItems <= 0
          ? filteredItems.length - 1
          : positionInFilteredItems - 1
      )
    );
  };

  const navigateToNextItem = () => {
    const item = items[currentItemIndex];

    const positionInFilteredItems = filteredItems.findIndex((filteredItem) => {
      if (item.type !== "void") {
        if (
          filteredItem.type !== "void" &&
          filteredItem.listId === item.listId
        ) {
          return true;
        }
      }
      return false;
    });
    navigateToItem(
      findIndexForFilteredItemInItemsList(
        positionInFilteredItems >= filteredItems.length - 1
          ? 0
          : positionInFilteredItems + 1
      )
    );
  };

  return (
    <MenuContext.Provider
      value={{
        show,
        currentIndex: currentItemIndex,
        items,
        searchedItems,
        filteredItems,
        liveMode,
        navigateToItem,
        navigateToNextItem,
        navigateToPreviousItem,
        returnToLiveMode: () => {
          setLiveMode(true);
          setCurrentItemIndex(
            items.findIndex(
              (item) => item.type !== "void" && item.listId === currentItemId
            )
          );
        },
        onClose: hide,
        toggleFullscreen,
        isFullscreen,
        playlistContent,
        selectedPlaylist,
        playlistId,
        setPlaylist,
        type,
        setType,
        view,
        setView,
        channelName,
        theme,
      }}
    >
      <div
        data-testid="container"
        ref={containerRef}
        className={styles.container}
      >
        {children}

        <InteractiveMenu
          search={search}
          setSearch={setSearch}
          navigateToNextItem={navigateToNextItem}
          navigateToPreviousItem={navigateToPreviousItem}
        />

        {landingMenuVisible && isLandingMenuEnabled && (
          <LandingMenu
            channelName={channelName}
            channelId={channelId}
            onInitialise={() => setLandingMenuVisible(false)}
          />
        )}
      </div>
    </MenuContext.Provider>
  );
};

export default MenuProvider;

export const useEmbedMenu = (): MenuContextType => useContext(MenuContext);
