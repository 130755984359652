import {
  CONTENT_FAILURE,
  ContentFailureAction,
  REPLACE_TIMELINE,
  ReplaceTimelineAction,
  TimelineItem,
  UPDATE_TIMELINE,
  UpdateTimelineAction,
} from "./types";

export const updateTimelineAction = (
  items: TimelineItem[],
  id: string,
  targetTimestamp: number,
  cleanupAmount: number | undefined,
  nextFragmentRequestTimestamp: number
): UpdateTimelineAction => {
  return {
    type: UPDATE_TIMELINE,
    payload: {
      id,
      items,
      cleanupAmount,
      targetTimestampMs: targetTimestamp,
      nextFragmentRequestTimestamp,
    },
  };
};

export const replaceTimelineAction = (
  items: TimelineItem[],
  id: string,
  targetTimestamp: number,
  nextFragmentRequestTimestamp: number
): ReplaceTimelineAction => {
  return {
    type: REPLACE_TIMELINE,
    payload: {
      id,
      items,
      targetTimestampMs: targetTimestamp,
      nextFragmentRequestTimestamp: nextFragmentRequestTimestamp,
    },
  };
};

export const contentFailureAction = (
  timelineId: string,
  itemIndex: number,
  replacement: TimelineItem[]
): ContentFailureAction => {
  return {
    type: CONTENT_FAILURE,
    payload: {
      timelineId,
      itemIndex,
      replacement,
    },
  };
};
