import { Screen } from "./../../queries/types";
import { Operating } from "../graphqlTypes";

import { ContentViewItem, LeafContentType } from "../../types/content";
import {
  FileFragment,
  LayoutFragment,
  ScreenStatus,
  SpaceFragment,
  ThemeFragment,
} from "../../queries";
import { NormalizedAppInstanceFragment } from "../apps/types";
import { NormalizedPlaylistFragment } from "../playlists/types";
import { NormalizedChannelFragment } from "../channels/types";
import { NormalizedSiteFragment } from "../sites/types";
import { NormalizedLinkFragment } from "../links/types";
import { NormalizedOrgFragment } from "../organization/types";
import { TimelinePlayback } from "../timelines/types";
import { DeviceInfo } from "../../remoteApi.d";

// Action types
export const SET_SCREEN_ACTIVE_ITEM = "SET_SCREEN_ACTIVE_ITEM";
export const REQUEST_SCREEN = "REQUEST_SCREEN";
export const REQUEST_SCREEN_SUCCESS = "REQUEST_SCREEN_SUCCESS";
export const UPDATE_SELF_SCREEN_DEVICE_SUCCESS =
  "UPDATE_SELF_SCREEN_DEVICE_SUCCESS";
export const UPDATE_SCREEN_DEVICE_INFO_SUCCESS =
  "UPDATE_SCREEN_DEVICE_INFO_SUCCESS";
export const UPDATE_CURRENT_ACTIVE_ITEM_WITH_CHILD =
  "UPDATE_CURRENT_ACTIVE_ITEM_WITH_CHILD";
// Export all action types here.
export type ScreenAction =
  | RequestScreenAction
  | RequestScreenSuccessAction
  | SetScreenActiveItemAction
  | UpdateSelfScreenDeviceSuccessAction
  | UpdateScreenDeviceInfoSuccessAction
  | UpdateCurrentActiveItemWithChildAction;

export type ActiveItem = {
  contentId: string;
  contentType: LeafContentType;
  zoneId: string; // "if zonrId is `root` it's playlist leaf item"
};
export interface ScreenData {
  [key: string]: string;
}

export interface ScreenState {
  id: string;
  deviceId: string;
  name: string;
  activeContentItem?: ContentViewItem;
  screenData?: ScreenData;
  spaceId: string | null;
  operatingHours: Operating | undefined;
  isMuted: boolean | undefined;
  deviceInfo: DeviceInfo;
  status: ScreenStatus;
  isPreview: boolean;
  videoSource: string;
  highResolutionImage: boolean;
  playbackMode?: TimelinePlayback;
  playerHeight?: number | null;
  playerWidth?: number | null;
  playerTimezone?: string | null;
  playerRelease?: string | null;
  timezoneOverride?: string | null;
  deviceModel?: string | null;
  devicePlatform?: string | null;
}

export interface SetScreenActiveItemAction {
  type: typeof SET_SCREEN_ACTIVE_ITEM;
  payload: ContentViewItem;
}

export interface RequestScreenAction {
  type: typeof REQUEST_SCREEN;
}

export interface RequestScreenSuccessAction {
  type: typeof REQUEST_SCREEN_SUCCESS;
  payload: {
    screen: Partial<ScreenState>;
    organization: NormalizedOrgFragment | null | undefined;
    apps: {
      [id: string]: NormalizedAppInstanceFragment;
    };
    channels: {
      [id: string]: NormalizedChannelFragment;
    };
    files: {
      [id: string]: FileFragment;
    };
    layouts: {
      [id: string]: LayoutFragment;
    };
    links: {
      [id: string]: NormalizedLinkFragment;
    };
    playlists: {
      [id: string]: NormalizedPlaylistFragment;
    };
    themes: {
      [id: string]: ThemeFragment;
    };
    spaces: {
      [id: string]: SpaceFragment;
    };
    sites: {
      [id: string]: NormalizedSiteFragment;
    };
  };
}

export interface UpdateSelfScreenDeviceSuccessAction {
  type: typeof UPDATE_SELF_SCREEN_DEVICE_SUCCESS;
  payload: Partial<
    Pick<
      Screen,
      | "deviceHostname"
      | "deviceIpAddress"
      | "playerRelease"
      | "playerHeight"
      | "playerWidth"
    >
  >;
}

export interface UpdateScreenDeviceInfoSuccessAction {
  type: typeof UPDATE_SCREEN_DEVICE_INFO_SUCCESS;
  payload: Partial<Pick<Screen, "deviceInfo" | "deviceRaw">>;
}

export interface UpdateCurrentActiveItemWithChildAction {
  type: typeof UPDATE_CURRENT_ACTIVE_ITEM_WITH_CHILD;
  payload: {
    activeItems: ActiveItem;
  };
}
