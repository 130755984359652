import RemoteApi from "../remoteApi";
import { LogLevel } from "./logger";
import { Context } from "@datadog/browser-core";

export type PmiLogFunction = (
  level: LogLevel,
  message: string,
  context?: Context
) => void;

export class PMILogger {
  private remoteApi = new RemoteApi();

  public sendPmiLog: PmiLogFunction = (
    level: LogLevel,
    message: string,
    context?: Context
  ): void => {
    this.remoteApi?.remote.fire("STUDIO_PLAYER_LOGS", [
      level,
      message,
      context,
    ]);
  };

  public getLogger = (): PmiLogFunction => {
    return this.sendPmiLog;
  };
}
