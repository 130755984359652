import { NormalizationUtilities, TraversalResult } from "../storeTypes";
import { PlayerState } from "../rootReducer";
import { makeContentListIdForPlaylist } from "../contentLists/utils";
import { NormalizedAppInstanceFragment } from "../apps/types";
import { FileFragment, ThemeFragment } from "../../queries";
import { NormalizedLinkFragment } from "../links/types";
import { NormalizedPlaylistFragment } from "./types";
import { NormalizedSiteFragment } from "../sites/types";
import { NormalizedOrgFragment } from "../organization/types";
import { traverseContentList } from "../normalizationUtils";
import { playlistSchema } from "../normalizationSchemas";

export const playlistNormalization: NormalizationUtilities = {
  schema: playlistSchema,
  traverseFunction: (playlistId: string, state: PlayerState) => {
    const result: TraversalResult = traverseContentList(
      makeContentListIdForPlaylist(playlistId),
      state
    );

    result.playlists.add(playlistId);

    return result;
  },
};

export type NormalizedPlaylistEntities = {
  apps: { [key: string]: NormalizedAppInstanceFragment } | null;
  files: { [key: string]: FileFragment } | null;
  links: { [key: string]: NormalizedLinkFragment } | null;
  playlists: { [key: string]: NormalizedPlaylistFragment } | null;
  sites: { [key: string]: NormalizedSiteFragment } | null;
  orgs: { [key: string]: NormalizedOrgFragment } | null;
  themes: { [key: string]: ThemeFragment } | null;
};
