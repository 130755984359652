import React, { FunctionComponent, memo, useMemo } from "react";
import {
  GqlZoneItemTransition,
  TransitionType,
} from "../../../../../store/graphqlTypes";
import {
  getActiveSlotTransitionStatus,
  getInternalTransitionType,
  getPreloadSlotTransitionStatus,
  isCrossTransition,
  transitionDefinitions,
} from "./transitionUtils";
import "./animate.min.css";
import "./customAnimate.css";
import classnames from "classnames";
import { TransitionStatus } from "./types";

export interface TimelineSlotTransitionProps {
  transition: GqlZoneItemTransition | undefined;
  isPreload: boolean;
  isActiveItemTransitioningOut: boolean;
  forceBackground?: boolean;
}

/**
 * Purpose: provide seamless transition between 2 content items
 */
export const TimelineSlotTransition: FunctionComponent<TimelineSlotTransitionProps> = (
  props
) => {
  const {
    children,
    transition,
    isPreload,
    isActiveItemTransitioningOut,
    forceBackground,
  } = props;

  const transitionStatus = useTransitionStatus(
    transition,
    isPreload,
    isActiveItemTransitioningOut
  );

  return (
    <>
      <Background
        transition={transition}
        transitionStatus={transitionStatus}
        isPreload={isPreload}
        force={forceBackground}
      />
      <TransitionAnimation
        transition={transition}
        transitionStatus={transitionStatus}
      >
        {children}
      </TransitionAnimation>
    </>
  );
};

interface BackgroundProps {
  transition: GqlZoneItemTransition | undefined;
  transitionStatus: TransitionStatus | undefined;
  isPreload: boolean;
  force?: boolean;
}

export const Background: FunctionComponent<BackgroundProps> = (props) => {
  const { transition, transitionStatus, isPreload, force } = props;

  const targetTransition: GqlZoneItemTransition = transition
    ? transition
    : {
        type: TransitionType.None,
        duration: 0,
        color: "black",
      };

  const transitionDef =
    transitionDefinitions[
      getInternalTransitionType(
        targetTransition.type,
        targetTransition.direction
      )
    ];

  const transitionBackgroundClasses = classnames(
    "background-block",
    "animated",
    {
      fadeOut: transitionStatus === "in",
      fadeIn: transitionStatus === "out",
    }
  );

  if (
    !force &&
    (transitionDef.isCross || !transitionDef.isColor || isPreload)
  ) {
    return null;
  }

  const backgroundColor = force
    ? targetTransition.color ?? "black"
    : transitionDef.isColor
    ? targetTransition.color
    : "transparent";

  return (
    <div
      data-testid="transition-bg-block"
      className={transitionBackgroundClasses}
      style={{
        animationDuration: `${targetTransition.duration}ms`,
        backgroundColor,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
      }}
    />
  );
};

interface TransitionAnimationProps {
  transition: GqlZoneItemTransition | undefined;
  transitionStatus: TransitionStatus | undefined;
}

const TransitionAnimation: FunctionComponent<TransitionAnimationProps> = memo(
  (props) => {
    const { children, transition, transitionStatus } = props;

    const targetTransition = useMemo<GqlZoneItemTransition>(() => {
      if (transition === undefined) {
        return {
          type: TransitionType.None,
          duration: 0,
        };
      } else {
        return transition;
      }
    }, [transition]);

    const transitionDef =
      transitionDefinitions[
        getInternalTransitionType(
          targetTransition.type,
          targetTransition.direction
        )
      ];

    const animatedBackgroundClasses = classnames("animated", {
      [transitionDef.in]: transitionStatus === "in",
      [transitionDef.out]: transitionStatus === "out",
    });

    return (
      <div
        data-testid="animate-bg"
        className={animatedBackgroundClasses}
        style={{
          backgroundColor: "transparent",
          animationDuration: `${targetTransition.duration}ms`,
          height: "100%",
          width: "100%",
          zIndex: 2,
        }}
      >
        {children}
      </div>
    );
  }
);

function useTransitionStatus(
  transition: GqlZoneItemTransition | undefined,
  isPreload: boolean,
  isActiveItemTransitioningOut: boolean
): TransitionStatus | undefined {
  if (transition === undefined) {
    return undefined;
  }

  const crossTransition = isCrossTransition(
    transition.type,
    transition?.direction
  );

  return isPreload
    ? getPreloadSlotTransitionStatus(
        isActiveItemTransitioningOut,
        crossTransition
      )
    : getActiveSlotTransitionStatus(
        isActiveItemTransitioningOut,
        transition,
        crossTransition
      );
}
