import React, { FunctionComponent, memo, useEffect } from "react";

import { Logger } from "../../../../logger/logger";
import { generateDomId } from "../VideoViewer/utils";
import { ContentHrefItem } from "../../../../types/content";
import { useGetStudioVariables } from "../../../../utils/useGetStudioVariables";

const log = new Logger("HrefViewer");

export const HrefViewer: FunctionComponent<ContentHrefItem> = memo((props) => {
  const { resolveStudioVariablesInTargetURL } = useGetStudioVariables();
  const { href, meta, props: contentProps } = props;
  const finalURL = resolveStudioVariablesInTargetURL(href);

  const domNodeId = generateDomId();

  useEffect(() => {
    log.info({
      message: `Show href ${href}`,
      context: {
        contentType: "href",
      },
    });
  }, [href]);

  return (
    <>
      <style id={`href-viewer-styles-${domNodeId}`}>
        {`
      #${domNodeId} {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        overflow: hidden;
        border: "none";
      }

      #${domNodeId} > video,
      #${domNodeId} > img,
      #${domNodeId} > iframe
        {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: ${
            contentProps?.sizing_type === "fill" ? "cover" : "contain"
          };
        }
    `}
      </style>
      <div id={domNodeId} data-testid="href-viewer-container">
        {getRenderElement(finalURL, meta?.content_type, contentProps)}
      </div>
    </>
  );
});
HrefViewer.displayName = "HrefViewer";

const getRenderElement = (
  href: string,
  contentType?: string,
  contentProps?: ContentHrefItem["props"]
) => {
  try {
    const type = identifyRenderingType(href, contentType);

    switch (type) {
      case "video":
        return (
          <video
            data-testid="href-viewer"
            src={href}
            title={href}
            muted={contentProps?.muted ?? true}
            loop={contentProps?.loop ?? true}
            autoPlay={contentProps?.autoplay ?? true}
            controls={contentProps?.controls ?? false}
          />
        );
      case "image":
        return (
          <img data-testid="href-viewer" src={href} alt={href} title={href} />
        );
      case "iframe":
      default:
        return <iframe data-testid="href-viewer" src={href} title={href} />;
    }
  } catch (error) {
    return <div>{error instanceof Error ? error?.message : error}</div>;
  }
};

export const identifyRenderingType = (
  href: string,
  contentType?: string
): "image" | "video" | "iframe" => {
  const typeByContentType = identifyRenderingTypeByContentType(contentType);
  if (typeByContentType) {
    return typeByContentType;
  }

  const typeByHref = identifyRenderingTypeByHref(href);
  if (typeByHref) {
    return typeByHref;
  }

  return "iframe";
};

const supportedVideoTypes = ["mp4", "ogg", "webm"];

const identifyRenderingTypeByContentType = (
  contentType = ""
): "image" | "video" | undefined => {
  if (!contentType) {
    return undefined;
  }

  const [type, subType] = contentType.split("/");

  switch (type) {
    case "image":
      return "image";
    case "video":
      if (supportedVideoTypes.includes(subType.toLowerCase())) {
        return "video";
      }
      throw new Error(`contentType ${contentType} is not currently supported`);
  }

  return undefined;
};

const identifyRenderingTypeByHref = (
  href: string
): "video" | "image" | "iframe" | undefined => {
  const url = new URL(href);
  const extension = url.pathname.split(".").pop()?.toLowerCase() ?? "";

  if (!extension) {
    return undefined;
  }

  const imageExtensions = [
    // extensions
    "bmp",
    "cod",
    "gif",
    "ief",
    "jpe",
    "jpeg",
    "jpg",
    "jfif",
    "svg",
    "tif",
    "tiff",
    "ras",
    "cmx",
    "ico",
    "pnm",
    "pbm",
    "pgm",
    "ppm",
    "rgb",
    "xbm",
    "xpm",
    "xwd",
    "webp",
    "avif",
    "bmp",
  ];
  if (imageExtensions.includes(extension)) {
    return "image";
  }

  if (supportedVideoTypes.includes(extension)) {
    return "video";
  }

  return undefined;
};
