import { NormalizationUtilities } from "../storeTypes";
import { PlayerState } from "../rootReducer";
import { getEmptyTraversalObject } from "../normalizationUtils";
import { fileSchema } from "../normalizationSchemas";

export const fileNormalization: NormalizationUtilities = {
  schema: fileSchema,
  traverseFunction: (fileId: string, state: PlayerState) => {
    const result = getEmptyTraversalObject();
    result.files.add(fileId);
    return result;
  },
};
