import React, { memo, useCallback, useEffect, useMemo } from "react";
import { ContentSizeType } from "../../../../types/content";
import Hls from "hls.js";
import { generateDomId } from "./utils";
import { Logger } from "../../../../logger/logger";
const log = new Logger("HLSVideoViewer");

interface HLSVideoProps {
  id?: string;
  src: string;
  isPreload: boolean;
  hasNextItem?: boolean;
  muted: boolean;
  controls: boolean;
  loop: boolean;
  playbackPositionMs: number;
  sizeType?: ContentSizeType;
}

export const HLSVideoViewer = memo((props: HLSVideoProps) => {
  const {
    id,
    src,
    isPreload,
    muted,
    loop,
    controls,
    sizeType,
    playbackPositionMs,
  } = props;
  const domNodeId = useMemo(() => {
    return id ? id : generateDomId();
  }, [id]);

  const isPlaying = (video: HTMLMediaElement): boolean => {
    return !video.paused && video.readyState > 2;
  };

  const playVideo = useCallback(
    (video: HTMLMediaElement) => {
      if (isPlaying(video) || isPreload) {
        return;
      }

      video.currentTime = playbackPositionMs / 1000;
      video.play().catch(() => {
        video.muted = true;
        video.play().catch((error) => {
          // Handle the error, possibly with a more robust strategy
          log.warn({
            message: `Error trying to play the video:', ${error}`,
            proofOfPlayFlag: true,
          });
        });
      });
    },
    [isPreload, playbackPositionMs]
  );

  useEffect(() => {
    const videoId = `video-${domNodeId}`;
    const video = document.getElementById(videoId) as HTMLMediaElement | null;
    if (!video) return;

    let hls: Hls | null = null;

    const setupHls = () => {
      hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        playVideo(video);
      });

      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    };

    const setupNative = () => {
      video.src = src;
      const onLoadedMetadata = () => {
        playVideo(video);
      };
      video.addEventListener("loadedmetadata", onLoadedMetadata);
      return () => {
        video.removeEventListener("loadedmetadata", onLoadedMetadata);
      };
    };

    playVideo(video);

    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      /*
       * When the browser has built-in HLS support (check using `canPlayType`),
       * we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
       * This is using the built-in support of the plain video element, without using hls.js.
       */
      log.info({
        message: `HLS not supported`,
      });
      const cleanupNative = setupNative();
      return cleanupNative;
    }

    if (Hls.isSupported()) {
      log.info({
        message: `HLS is supported`,
      });
      const cleanupHls = setupHls();
      return cleanupHls;
    }
  }, [src, isPreload, playVideo, domNodeId]);

  return (
    <>
      <style id={`video-styles-${domNodeId}`}>
        {`
          #${domNodeId} {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            overflow: hidden;
          }

          #${domNodeId} > video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: ${sizeType === "fill" ? "cover" : "contain"};
          }
        `}
      </style>
      <div id={domNodeId}>
        <video
          id={`video-${domNodeId}`}
          muted={muted}
          loop={loop}
          controls={controls}
        ></video>
      </div>
    </>
  );
});
