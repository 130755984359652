import {
  TimeOptions,
  playbackNowTimestamp,
  getTimestampByTime,
} from "./timeManager";
import { Logger } from "../logger/logger";
import { DAY_DURATION_MS, HOUR_DURATION_MS } from "../constants";

const log = new Logger("singleContentLogs.tsx");

class IntervalAction {
  private timeoutID: NodeJS.Timeout | undefined;
  private interval = 1000;
  constructor(interval: number) {
    this.interval = interval;
  }

  public setup = (sendLog: () => void, callback: () => void) => {
    this.cancel();
    this.timeoutID = setTimeout(() => {
      sendLog();
      // Add 1s delay before refresh to ensure that log has been sent.
      setTimeout(() => callback(), 1000);
    }, this.interval);
    return this.timeoutID;
  };

  public cancel = () => {
    if (typeof this.timeoutID === "number") {
      clearTimeout(this.timeoutID);
    }
  };
}

/**
  Reloads at a random time between 2am and 4am every day.
*/
export function setDailyReload(timeOptions: TimeOptions): NodeJS.Timeout {
  const reloadPeriodStart =
    getTimestampByTime(timeOptions, "02:00") + DAY_DURATION_MS; // next 2am
  const reloadPeriodDurationMs = HOUR_DURATION_MS * 2;
  const randomOffset = Math.round(Math.random() * reloadPeriodDurationMs);
  const reloadTime = reloadPeriodStart + randomOffset;

  const timeoutValue = reloadTime - playbackNowTimestamp(timeOptions);
  const action = new IntervalAction(timeoutValue);

  log.info({
    message: `Set daily reload timeout.`,
    context: {
      reloadTimestamp: reloadTime,
      reloadTime: new Date(reloadTime).toLocaleString(),
    },
  });

  return action.setup(
    () =>
      log.info({
        message: `Daily reload triggered.`,
        context: {
          reloadTimestamp: reloadTime,
          reloadTime: new Date(reloadTime).toLocaleString(),
        },
      }),
    () => {
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    }
  );
}

export function addNoCacheParam(url: string): string {
  return `${url}${url.indexOf("?") > -1 ? "&" : "?"}nc=${new Date().getTime()}`;
}
