import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { canPlayUnmutedMedia } from "../../../../utils/autoplay";
import { PlayerState } from "../../../../store/rootReducer";
import styles from "./VideoViewer.module.css";

export function generateDomId(): string {
  return (
    "p-" +
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function isMediaPlaying(mediaElement: HTMLMediaElement): boolean {
  return !!(
    mediaElement &&
    mediaElement.currentTime > 0 &&
    !mediaElement.paused &&
    !mediaElement.ended &&
    mediaElement.readyState > 2
  );
}

export function getMutedStatus(isScreenMuted: boolean) {
  const muted = isScreenMuted || !canPlayUnmutedMedia();
  return muted;
}

export const useBlackCoverElementOnDevices = (isPreload: boolean) => {
  const [hideElement, setHideElement] = useState(false);
  const blackCoverTimeout = window.localStorage.getItem(
    "__sc_black_cover_timeout"
  );
  const blackCoverTimeoutTime = parseInt(blackCoverTimeout || "650");

  const devicesToAddBlackCover = ["AFTKRT", "AFTSS"];
  const isDeviceMatch = useSelector<PlayerState, boolean>(
    (state) =>
      !!state.screen.deviceModel &&
      devicesToAddBlackCover.includes(state.screen.deviceModel)
  );
  const shouldAddBlackCoverToDevices = isDeviceMatch || !!blackCoverTimeout;

  useEffect(() => {
    if (isPreload || !shouldAddBlackCoverToDevices) return;

    const timeout = setTimeout(() => {
      setHideElement(true);
    }, blackCoverTimeoutTime);

    return () => clearTimeout(timeout);
  }, [isPreload, shouldAddBlackCoverToDevices, blackCoverTimeoutTime]);

  return shouldAddBlackCoverToDevices ? (
    <div
      className={classNames(styles.blackCover, {
        [styles.hideCover]: hideElement,
      })}
    />
  ) : null;
};

export const isHlsVideo = (src: string): boolean => {
  return /\.m3u8(\?.*)?$/i.test(src);
};
