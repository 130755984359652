import React, { FunctionComponent } from "react";
import { useEmbedMenu } from "../../../../../providers/MenuProvider/MenuProvider";
import { PlaylistContent } from "../../../../../providers/MenuProvider/types";
import { Playlist, AllItemsPlaylist } from "../../components/Playlist";

const MainView: FunctionComponent = () => {
  const { playlistContent } = useEmbedMenu();

  return (
    <>
      {playlistContent?.map((playlist: PlaylistContent) => (
        <Playlist playlist={playlist} key={playlist.id}></Playlist>
      ))}
      <AllItemsPlaylist />
    </>
  );
};

export default MainView;
