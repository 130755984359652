import React, { FunctionComponent, useEffect, useMemo } from "react";
import { TimelineOperatorPropsBase } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { ContentList } from "../../../../store/contentLists/types";
import { PlayerState } from "../../../../store/rootReducer";
import { Timeline, TimelinePlayback } from "../../../../store/timelines/types";
import { TimelinePlaybackState } from "../../../../store/playback/types";
import { generateTimeline } from "../../../timeline/localGenerator";
import {
  getDateIsoShort,
  getEndOfDay,
  playbackNowTimestamp,
} from "../../../../utils/timeManager";
import {
  replaceTimelineAction,
  updateTimelineAction,
} from "../../../../store/timelines/actions";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { EntityType } from "@screencloud/signage-firestore-client";
import { useTimeOptions } from "../../../../utils/useTimeOptions";
import { DAY_DURATION_MS } from "../../../../constants";
import { ScreenState } from "../../../../store/screen/types";

const DEFAULT_IN_ADVANCE_TRIGGER_TIME = 3 * 60 * 1000;

type TimelineOperatorLocalProps = TimelineOperatorPropsBase & {
  testOnLiveUpdateChange?: (collection: LiveUpdateCollection) => unknown; // for test purposes only
  inAdvanceTriggerTimeMs?: number; // how long in advance should new timeline fragment be generated
};

export interface LiveUpdateCollection {
  fileIds: string[];
  appIds: string[];
  playlistIds: string[];
}

const TimelineOperatorLocal: FunctionComponent<TimelineOperatorLocalProps> = ({
  timelineId,
  testOnLiveUpdateChange,
  inAdvanceTriggerTimeMs,
}: TimelineOperatorLocalProps) => {
  const contentListId = timelineId;
  const contentList = useSelector<PlayerState, ContentList | undefined>(
    (state) => state.contentLists.byId[contentListId]
  );
  const timeline = useSelector<PlayerState, Timeline | undefined>(
    (state) => state.timelines.byId[timelineId]
  );
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const timelineItems = timeline?.items;

  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) => state.playback.timelines[timelineId]);
  const dispatch = useDispatch();

  const timeOptions = useTimeOptions();

  useEffect(() => {
    /**
     * Generate and replace timeline with updated content
     */
    if (contentList !== undefined) {
      const now = playbackNowTimestamp(timeOptions);
      const targetDay = getDateIsoShort(timeOptions, now);
      const timelineUpdate = generateTimeline(
        targetDay,
        contentList,
        timeOptions,
        {
          type: screen.playbackMode || TimelinePlayback.TIMELINE_PLAYBACK_SYNC,
        }
      );

      // next timeline fragment will be requested 3 minutes before the end of the day
      const nextFragmentRequestTime =
        getEndOfDay(timeOptions, targetDay) -
        (inAdvanceTriggerTimeMs ?? DEFAULT_IN_ADVANCE_TRIGGER_TIME);
      dispatch(
        replaceTimelineAction(
          timelineUpdate,
          timelineId,
          // the current time has to be recalculate here to prevent edage case for playback unsync first item start time may grater than the player current time (1 - 2ms)
          now,
          // playbackNowTimestamp(timeOptions),
          nextFragmentRequestTime
        )
      );
    } else {
      // todo: remove the else clause, when TimelineViewer component is fully covered with tests
      //  https://github.com/screencloud/studio-player/issues/659
      dispatch(
        replaceTimelineAction(
          [
            {
              breakpointId: 0,
              type: "void",
              startTimestampMs: -Infinity,
              fullDurationMs: Infinity,
              showDurationMs: Infinity,
              isInfinite: true,
            },
          ],
          timelineId,
          playbackNowTimestamp(timeOptions),
          0 // we don't update until content changes in this case
        )
      );
    }
  }, [
    contentList,
    dispatch,
    timelineId,
    timeOptions,
    inAdvanceTriggerTimeMs,
    screen,
  ]);

  useEffect(() => {
    /**
     * Produce next timeline fragment
     */
    const timeoutValue =
      typeof playbackState?.nextFragmentRequestTimestamp === "number"
        ? playbackState.nextFragmentRequestTimestamp -
          playbackNowTimestamp(timeOptions)
        : undefined;
    if (
      contentList &&
      timelineItems &&
      timeoutValue !== undefined &&
      timeoutValue > 0
    ) {
      const timeout = window.setTimeout(() => {
        const now = playbackNowTimestamp(timeOptions);
        const lastTimelineItemStartTimestamp =
          timelineItems.length > 0
            ? timelineItems[timelineItems.length - 1].startTimestampMs
            : undefined;

        // Player reloads daily, so we assume it's safe to not care about cleaning up the timeline history. It will
        //  be cleaned up on reload.
        const cleanupAmount = 0;

        const targetDay = getDateIsoShort(
          timeOptions,
          lastTimelineItemStartTimestamp
            ? lastTimelineItemStartTimestamp + DAY_DURATION_MS
            : now
        );

        const timelineUpdate = generateTimeline(
          targetDay,
          contentList,
          timeOptions
        );
        const nextUpdateTime =
          getEndOfDay(timeOptions, targetDay) -
          (inAdvanceTriggerTimeMs ?? DEFAULT_IN_ADVANCE_TRIGGER_TIME);

        dispatch(
          updateTimelineAction(
            timelineUpdate,
            timelineId,
            playbackNowTimestamp(timeOptions),
            cleanupAmount,
            nextUpdateTime
          )
        );
      }, timeoutValue);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [
    contentList,
    dispatch,
    playbackState?.nextFragmentRequestTimestamp,
    timeOptions,
    timelineItems,
    timelineId,
    inAdvanceTriggerTimeMs,
  ]);

  const contentListItems = contentList?.items;

  const liveUpdateCollection = useMemo<LiveUpdateCollection>(() => {
    const fileSet = new Set<string>();
    const appSet = new Set<string>();
    const playlistSet = new Set<string>();

    if (contentListItems) {
      contentListItems?.forEach((item) => {
        switch (item.type) {
          case "app":
            appSet.add(item.id);
            break;
          case "file":
            fileSet.add(item.id);
            break;
        }

        if (item.parent && item.parent.type === "playlist") {
          playlistSet.add(item.parent.id);
        }
      });
    }

    return {
      fileIds: [...fileSet],
      appIds: [...appSet],
      playlistIds: [...playlistSet],
    };
  }, [contentListItems]);

  useEffect(() => {
    // mostly to expose this in tests
    if (testOnLiveUpdateChange) {
      testOnLiveUpdateChange(liveUpdateCollection);
    }
  }, [liveUpdateCollection, testOnLiveUpdateChange]);

  return (
    <>
      {/**
       * Local timeline operator generates timeline based on the items, included into respective contentList.
       * Therefore all items that may affect the schedule filtering are connected to live updates below
       */}
      {liveUpdateCollection.fileIds.map((fileId) => (
        <LiveUpdateConnector
          key={fileId}
          entityId={fileId}
          entityType={EntityType.FILE}
        />
      ))}
      {liveUpdateCollection.appIds.map((appId) => (
        <LiveUpdateConnector
          key={appId}
          entityId={appId}
          entityType={EntityType.APP_INSTANCE}
        />
      ))}
      {liveUpdateCollection.playlistIds.map((playlistId) => (
        <LiveUpdateConnector
          key={playlistId}
          entityId={playlistId}
          entityType={EntityType.PLAYLIST}
        />
      ))}
    </>
  );
};

export default TimelineOperatorLocal;
