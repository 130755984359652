import React from "react";

import { RemoteApiInterface } from "./configurationManager";
import { FileProcessingClientInterface } from "./types/filesClient";

export interface PlaybackConfig {
  isTizenPlayer: boolean;
}
export interface ClientApiContextData {
  fileProcessingClient: FileProcessingClientInterface;
  remoteApi: RemoteApiInterface;
  playbackConfig: PlaybackConfig;
}

const ClientApiContext = React.createContext<ClientApiContextData | null>(null);

export const ClientApiProvider = ClientApiContext.Provider;
export default ClientApiContext;
