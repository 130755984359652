import { REQUEST_PLAYLIST_SUCCESS } from "../playlists/types";
import { REQUEST_APP_SUCCESS } from "../apps/types";
import { produce } from "immer";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import {
  FilesState,
  PlayerFile,
  ImageFile,
  REQUEST_FILE_SUCCESS,
  ValidatedFileFragment,
  ValidatedFileOutputFragment,
} from "./types";
import { fileMediaType } from "../../utils";
import isEqual from "lodash/isEqual";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import { FileprocessingClient } from "@screencloud/signage-files-client";
import { getVideoMimetypePriority } from "../../utils/videoTypePriorityManager";
import { FileFragment, FileOutputFragment } from "../../queries";
import { captureException } from "../../utils/bugTracker";

/**
 * Data about the available app instances.
 */
const initialState: FilesState = {
  byId: {},
};

export function filesReducer(
  state = initialState,
  action: PlayerAction
): FilesState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_APP_SUCCESS:
      case REQUEST_FILE_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS:
      case REQUEST_PLAYLIST_SUCCESS:
      case REQUEST_SCREEN_SUCCESS: {
        const files = action.payload.files;
        const fileIds = Object.keys(files);
        fileIds.forEach((id) => {
          if (files[id]) {
            const validatedFileEntity = getValidatedFileEntity(files[id]);
            if (validatedFileEntity) {
              const newFile = mapGqlFileToPlayerFile(validatedFileEntity);
              if (!isEqual(state.byId[id], newFile)) {
                draft.byId[id] = newFile;
              }
            } else {
              captureException(
                new Error(`File can not be validated. Missing props.`)
              );
            }
          }
        });
      }
    }

    return draft;
  });
}

/**
 * Some fields of a file are allowed to be empty on API side, but not allowed to be empty on player side.
 * This function returns a validated file entity, or undefined if validation is not passed.
 */
function getValidatedFileEntity(
  file: FileFragment
): ValidatedFileFragment | undefined {
  if (!isValidatedFile(file)) {
    return undefined;
  }

  return {
    ...file,
    fileOutputsByFileId: {
      nodes: file.fileOutputsByFileId.nodes.reduce<
        ValidatedFileOutputFragment[]
      >((sum, fileOutput) => {
        if (isValidatedFileOutput(fileOutput)) {
          return sum.concat([fileOutput]);
        } else {
          return sum;
        }
      }, []),
    },
  };
}

function isValidatedFile(file: FileFragment): file is ValidatedFileFragment {
  return !!file.mimetype;
}

function isValidatedFileOutput(
  fileOutput: FileOutputFragment
): fileOutput is ValidatedFileOutputFragment {
  return fileOutput.mimetype && fileOutput.content && fileOutput.url;
}

function findVideoData(
  nodes: FileOutputFragment[]
): FileOutputFragment | undefined {
  let resultVideoData: FileOutputFragment | undefined;
  const videoMimetypePriorityOrderList = getVideoMimetypePriority();

  for (let i = 0; i < videoMimetypePriorityOrderList.length; i++) {
    const mimetype = videoMimetypePriorityOrderList[i];
    resultVideoData = nodes.find((n) => n.mimetype === mimetype);

    if (resultVideoData) {
      break;
    }
  }

  return resultVideoData;
}

function findThumbnailData(
  nodes: FileOutputFragment[]
): FileOutputFragment | undefined {
  return nodes.find((node) => node.mimetype?.startsWith("image"));
}

const mapGqlFileToPlayerFile = (data: ValidatedFileFragment): PlayerFile => {
  const type = fileMediaType(data.mimetype);
  const base = {
    availableAt: data.availableAt,
    expireAt: data.expireAt,
    height: data.metadata.height,
    id: data.id,
    name: data.name,
    size: data.metadata.size,
    type,
    width: data.metadata.width,
    source: data.source,
    orgId: data.orgId,
    metadata: data.metadata,
    mimetype: data.mimetype,
  };

  switch (type) {
    case "video": {
      const videoData = findVideoData(data.fileOutputsByFileId.nodes);
      const thumbnailData = findThumbnailData(data.fileOutputsByFileId.nodes);
      const urlKey = videoData?.content.keys[0] || "";
      const source = data.source;
      return {
        ...base,
        type: "video",
        mimetype: videoData?.mimetype || data.mimetype,
        urlKey,
        source,
        thumbnail: thumbnailData ? thumbnailData.content.keys[0] : undefined,
      };
    }
    case "audio": {
      const urlKey = FileprocessingClient.getAudioUrlKey(data);
      const source = data.source;
      return {
        ...base,
        type: "audio",
        urlKey,
        source,
      };
    }
    case "image": {
      return {
        ...base,
        type: "image",
        urlKey: FileprocessingClient.getImageUrlKey(data),
      };
    }
    case "document": {
      return {
        ...base,
        type: "document",
        images: FileprocessingClient.getDocumentUrlKeys(data).map((key) => {
          const imageFile: ImageFile = {
            availableAt: data.availableAt,
            expireAt: data.expireAt,
            height: data.metadata.height,
            id: data.id,
            name: data.name,
            size: data.metadata.size,
            type: "image",
            width: data.metadata.width,
            urlKey: key,
            orgId: data.orgId,
            mimetype: data.mimetype,
            source: data.source,
          };
          return imageFile;
        }),
      };
    }
    default:
      break;
  }

  throw new Error(`Document type is not recognised. ${type}`);
};
