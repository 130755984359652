import { combineReducers, Reducer } from "redux";
import { persistReducer } from "redux-persist";
import { appsReducer } from "./apps/reducer";
import { AppsState } from "./apps/types";
import { chainReducers } from "./chainReducers";
import { channelsReducer } from "./channels/reducer";
import { ChannelsState } from "./channels/types";
import { configReducer } from "./config/reducer";
import { ConfigState } from "./config/types";
import { contentListsReducer } from "./contentLists/reducer";
import { ContentListsState } from "./contentLists/types";
import { filesReducer } from "./files/reducer";
import { FilesState } from "./files/types";
import { layoutsReducer } from "./layouts/reducer";
import { LayoutsState } from "./layouts/types";
import { linksReducer } from "./links/reducer";
import { LinksState } from "./links/types";
import { organizationReducer } from "./organization/reducer";
import { OrganizationState } from "./organization/types";
import { playbackReducer, emptyPlaybackReducer } from "./playback/reducer";
import { playListReducer } from "./playlists/reducer";
import { screenReducer } from "./screen/reducer";
import { ScreenState } from "./screen/types";
import { PlayerAction, unusedAction } from "./storeTypes";
import { themesReducer } from "./themes/reducer";
import { ThemesState } from "./themes/types";
import reduxPersistStorage from "redux-persist/lib/storage";
import { PlaybackState } from "./playback/types";
import { TimelinesState } from "./timelines/types";
import { emptyTimelinesReducer, timelinesReducer } from "./timelines/reducer";
import { SpacesState } from "./spaces/types";
import { spacesReducer } from "./spaces/reducer";
import { sitesReducer } from "./sites/reducer";
import { LiveUpdatesState } from "./liveUpdates/types";
import { liveUpdatesReducer } from "./liveUpdates/reducer";
import {
  PersistConfig,
  PersistedState,
  PersistMigrate,
} from "redux-persist/es/types";
import ValuesTransform from "./ValuesTransform";
import { ReduxPersistCounter } from "../utils/interactionCounter";
import { captureMessage } from "../utils/bugTracker";
import utilReducer from "./util/utilReducer";
import { SitesState } from "./sites/types";
import { PlaylistsState } from "./playlists/types";
import debounce from "lodash/debounce";
import { cleanupReducer } from "./cleanup/reducer";

function getStoreVersion(): number {
  return process.env.REACT_APP_REDUX_SCHEMA_VERSION !== undefined
    ? parseInt(process.env.REACT_APP_REDUX_SCHEMA_VERSION)
    : -1;
}

export const combinedReducer = combineReducers<PlayerState>({
  apps: appsReducer,
  channels: channelsReducer,
  playlists: playListReducer,
  config: configReducer,
  contentLists: contentListsReducer,
  files: filesReducer,
  layouts: layoutsReducer,
  links: linksReducer,
  organization: organizationReducer,
  screen: screenReducer,
  themes: themesReducer,
  timelines: emptyTimelinesReducer,
  playback: emptyPlaybackReducer,
  spaces: spacesReducer,
  liveUpdates: liveUpdatesReducer,
  sites: sitesReducer,
});

export const ROOT_STORAGE_KEY = "root";
export const PERSIST_KEY_PREFIX = "reduxpersist_";

const { setItem, getItem, removeItem } = reduxPersistStorage;
// Setting time window of 10 secs to count amount of persist logs
// persistLogCount will be reset after 10 secs
const reduxPersistCounter = new ReduxPersistCounter({
  baseTrackingTimePeriodMs: 10000,
  triggerNumber: 10,
  onTriggerWarning: (log) => {
    captureMessage(`Too many redux-persist setItem calls.`, {
      contexts: {
        callLog: log.reduce<string>((sum, item) => {
          return sum.concat(item.timestamp.toString());
        }, ""),
      },
    });
  },
});

const debouncePersist = debounce(
  async (key: string, value: string) => {
    reduxPersistCounter.add();
    return await setItem(key, value);
  },
  1000,
  {
    leading: false,
    trailing: true,
    maxWait: 5000,
  }
);

const blacklist: Array<keyof PlayerState> = [
  "playback",
  "timelines",
  "liveUpdates",
];

const dataReducer = chainReducers<PlayerState, PlayerAction>(
  combinedReducer,
  timelinesReducer,
  playbackReducer,
  cleanupReducer
);

export const rootReducer =
  process.env.NODE_ENV === "test"
    ? chainReducers<PlayerState, PlayerAction>(dataReducer, utilReducer)
    : dataReducer;

export const emptyInitialState: PlayerState & PersistedState = {
  ...rootReducer(undefined, unusedAction()),
  _persist: {
    version: 0,
    rehydrated: false,
  },
};

/**
 * Export the root reducer that gets persisted to permanent storage.
 */
export function getPersistedRootReducer(
  graphqlTokenScreenId: string | undefined
): Reducer<PlayerState & PersistedState> {
  const droppingMigration: PersistMigrate = (state) => {
    if (state?._persist.version !== getStoreVersion()) {
      return Promise.resolve(emptyInitialState);
    } else {
      return Promise.resolve(state);
    }
  };

  const persistConfigRoot: PersistConfig<PlayerState> = {
    key: ROOT_STORAGE_KEY,
    keyPrefix: PERSIST_KEY_PREFIX,
    migrate: droppingMigration,
    storage: {
      setItem: (key: string, value: string) => {
        return new Promise((resolve) => {
          debouncePersist(key, value);
          resolve(undefined);
        });
      },
      getItem,
      removeItem,
    },
    version: getStoreVersion(),
    blacklist,
    transforms: [ValuesTransform],
    stateReconciler: (inboundState, originalState, reducedState) => {
      if (
        reducedState.config.contentConfig.type !== "screen" ||
        reducedState.config.contentConfig.id !== graphqlTokenScreenId
      ) {
        // specific purpose of this clause: catch the situation when device pairing has changed and cancel rehydration
        //  by returning original (initial) state
        return originalState;
      } else {
        return reducedState;
      }
    },
  };

  return persistReducer<PlayerState>(persistConfigRoot, rootReducer);
}

export interface PlayerState {
  apps: AppsState;
  channels: ChannelsState;
  config: ConfigState;
  contentLists: ContentListsState;
  files: FilesState;
  layouts: LayoutsState;
  links: LinksState;
  organization: OrganizationState;
  playlists: PlaylistsState;
  screen: ScreenState;
  themes: ThemesState;
  timelines: TimelinesState;
  playback: PlaybackState;
  spaces: SpacesState;
  sites: SitesState;
  liveUpdates: LiveUpdatesState;
}
