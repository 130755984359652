import React, { FunctionComponent, useEffect } from "react";
import { useFetchSecureMediaPolicy } from "@screencloud/studio-media-client";
import { useDispatch, useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { setConfig } from "../../../../store/config/actions";
import { Logger } from "../../../../logger/logger";
import { ScreenState } from "../../../../store/screen/types";
import { isMediaCacheSupportedForScreen } from "../../../../utils/helpers";

const log = new Logger("SecureMediaPolicyContainer");

export interface SecureMediaPolicyContainerProps {
  mediaServiceEndpoint: string;
}
interface FetchSecureMediaPolicyProps {
  mediaServiceEndpoint: string;
  token: string;
  deviceTimeOffset: number;
  mediaCache: boolean;
  secureMediaPolicy: string | undefined;
}
const FetchSecureMediaPolicy: FunctionComponent<FetchSecureMediaPolicyProps> = ({
  mediaServiceEndpoint,
  token,
  deviceTimeOffset,
  mediaCache,
  secureMediaPolicy,
}) => {
  const options = {
    mediaServiceEndpoint: `${mediaServiceEndpoint}/auth`,
    studioAuthToken: token,
    maxRetryTimeMs: 3600000, // 1 hour retry if failed
    deviceTimeOffsetMs: deviceTimeOffset,
    cache: true,
    mediaCache: mediaCache ? "true" : undefined,
    retryOffsetBeforeExpireMs: 3600000, // 1 hour before policy expires
  };

  const dispatch = useDispatch();
  const { policy, error } = useFetchSecureMediaPolicy(options, {
    fetchApi: fetch,
  });
  useEffect(() => {
    if (typeof policy === "string" && policy !== secureMediaPolicy) {
      log.info(`Dispatching new secure media policy`);
      dispatch(setConfig({ secureMediaPolicy: policy }));
    }
  }, [policy, secureMediaPolicy, dispatch]);

  useEffect(() => {
    if (error !== undefined) {
      log.error("Can't fetch secure media policy. Error:" + error.message);
    }
  }, [error]);

  return null;
};

export const SecureMediaPolicyContainer: FunctionComponent<SecureMediaPolicyContainerProps> = (
  props
) => {
  const { mediaServiceEndpoint } = props;

  const token = useSelector<PlayerState, string>(
    (state) => state.config.graphqlToken
  );
  const deviceTimeOffset = useSelector<PlayerState, number>(
    (state) => state.config.timeOffset
  );

  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);

  const secureMediaPolicy = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaPolicy
  );
  // Assuming 'screen' is defined and has the necessary structure
  const isMediaCacheSupported = isMediaCacheSupportedForScreen(screen);

  return (
    <FetchSecureMediaPolicy
      mediaServiceEndpoint={mediaServiceEndpoint}
      token={token}
      deviceTimeOffset={deviceTimeOffset}
      mediaCache={isMediaCacheSupported}
      secureMediaPolicy={secureMediaPolicy}
    />
  );
};
