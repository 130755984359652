import { NormalizationUtilities } from "../storeTypes";
import { themeNormalization } from "../themes/normalization";
import { PlayerState } from "../rootReducer";
import {
  getEmptyTraversalObject,
  mergeTraversalResult,
} from "../normalizationUtils";
import { orgSchema } from "../normalizationSchemas";

export const orgNormalization: NormalizationUtilities = {
  schema: orgSchema,
  traverseFunction: (orgId: string, state: PlayerState) => {
    let result = getEmptyTraversalObject();

    if (state.organization.defaultThemeId) {
      result = mergeTraversalResult(
        result,
        themeNormalization.traverseFunction(
          state.organization.defaultThemeId,
          state
        )
      );
    }

    return result;
  },
};
