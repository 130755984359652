import React from "react";
import { AudioSettingsContext } from "../../../../providers/AudioSettingsProvider/AudioSettingsProvider";
import { shouldMuteMedia } from "./utils";

interface PrimaryAudioProps {
  enabled: boolean;
  shouldMute?: boolean;
}

interface Props {
  defaultMute?: boolean;
  primaryAudio: PrimaryAudioProps;
}

const AudioSettingsContainer: React.FunctionComponent<Props> = (props) => {
  return (
    <AudioSettingsContext.Provider
      value={{
        shouldMuteMedia:
          props.defaultMute ||
          shouldMuteMedia(
            props.primaryAudio.enabled,
            props.primaryAudio.shouldMute
          ),
      }}
    >
      {props.children}
    </AudioSettingsContext.Provider>
  );
};

export default AudioSettingsContainer;
