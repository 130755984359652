import { getStudioPlayerVersion } from "../helpers";
import { IHealthCheck, IHealthCheckInfo } from "./exposedInterface.d";

class HealthCheck {
  private sessionStartTime: number;

  // These following variables will be set from outside

  /** NOTE: this variable can be improved by intercept at [src/setup.ts:13](../../setup.ts)
   *
   * ref: https://www.npmjs.com/package/graphql-hooks#request-interceptors */
  latestLastApiSync: number | undefined;
  latestIsInOperatingHours: boolean | undefined;
  latestError: string;

  constructor() {
    this.sessionStartTime = Date.now();
    this.latestError = "";
  }

  async getLatestHealthCheck(): Promise<IHealthCheck> {
    const ok: boolean = this.latestError === "";
    const info = await this.getHealthCheckInfo();
    return {
      error: this.latestError,
      ok,
      info,
    };
  }

  private async getHealthCheckInfo(): Promise<IHealthCheckInfo> {
    const online = await checkUrlFetch(
      `https://studio-player.screencloud.com/online.txt?nocache=${new Date().getTime()}`
    );
    return {
      currentTime: Date.now(),
      sessionStartTime: this.sessionStartTime,
      navigatorOnline: navigator.onLine,
      playerVersion: getStudioPlayerVersion(),
      inOperatingHour: this.latestIsInOperatingHours,
      lastApiSync: this.latestLastApiSync,
      online,
    };
  }
}

async function checkUrlFetch(
  url: string,
  expectedStatus = 200,
  httpMethod = "GET"
): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    fetch(url, {
      method: httpMethod,
    })
      .then((response) => {
        if (response.status === expectedStatus) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        resolve(false);
      });
  });
}

const healthCheck = new HealthCheck();
export default healthCheck;
