import { PlayerState } from "../rootReducer";
import { PlayerAction } from "../storeTypes";
import {
  NEXT_ITEM,
  PlaybackState,
  SET_HAS_PRELOADING_STARTED,
  SET_IS_ACTIVE_ITEM_TRANSITIONING_OUT,
  SET_TIMELINE_CONTROL_OFFSET,
  SET_TIMELINE_PREVIEW_ITEM,
  TimelinePlaybackState,
} from "./types";
import { REPLACE_TIMELINE, UPDATE_TIMELINE } from "../timelines/types";
import isEqual from "lodash/isEqual";

const initialState: PlaybackState = {
  timelines: {},
  controls: {
    timelineOffset: 0, // used to provide playback skipping functionality
    previewItem: null,
  },
};

export function emptyPlaybackReducer(
  state: PlaybackState = initialState,
  action: PlayerAction
): PlaybackState {
  return state;
}

export function playbackReducer(
  state: PlayerState,
  action: PlayerAction
): PlayerState {
  switch (action.type) {
    case NEXT_ITEM:
      return setTimelinePlaybackState(
        state,
        action.payload.timelineId,
        action.payload.targetTimestamp,
        undefined
      );

    case REPLACE_TIMELINE:
    case UPDATE_TIMELINE:
      return setTimelinePlaybackState(
        state,
        action.payload.id,
        action.payload.targetTimestampMs,
        action.payload.nextFragmentRequestTimestamp
      );

    case SET_TIMELINE_CONTROL_OFFSET:
      return {
        ...state,
        playback: {
          ...state.playback,
          controls: {
            ...state.playback.controls,
            timelineOffset: action.payload.offsetValue,
          },
        },
      };
    case SET_TIMELINE_PREVIEW_ITEM:
      return {
        ...state,
        playback: {
          ...state.playback,
          controls: {
            ...state.playback.controls,
            previewItem: action.payload.previewItem,
          },
        },
      };

    case SET_IS_ACTIVE_ITEM_TRANSITIONING_OUT:
      return updateIsActiveItemTransitioningOut(
        state,
        action.timelineId,
        action.payload
      );

    case SET_HAS_PRELOADING_STARTED:
      return updateHasPreloadingStarted(
        state,
        action.timelineId,
        action.payload
      );

    default:
      return state;
  }
}

function setTimelinePlaybackState(
  state: PlayerState,
  timelineId: string,
  targetTimestamp: number,
  nextChunkRequestTimestamp: number | undefined
): PlayerState {
  const timeline = state.timelines.byId[timelineId];
  const items = timeline.items;
  const currentPlaybackState = state.playback.timelines[timelineId];
  const activeItemIndex = items.findIndex((item, index) => {
    const followingItem = items[index + 1];
    return (
      item.startTimestampMs <= targetTimestamp &&
      (!followingItem || followingItem.startTimestampMs > targetTimestamp)
    );
  });

  let nextItemIndex = items[activeItemIndex + 1]
    ? activeItemIndex + 1
    : undefined;

  if (activeItemIndex === -1) {
    // if activeItemIndex not found then look up to the first item that
    // has the closest start time in future from the targetTimestamp
    nextItemIndex = items.findIndex((item, index) => {
      return item.startTimestampMs > targetTimestamp;
    });
  }

  const activeScreenTime = items[activeItemIndex]?.showDurationMs ?? undefined; // allow 0

  const update: TimelinePlaybackState = {
    ...currentPlaybackState,
    activeIndex: activeItemIndex > -1 ? activeItemIndex : undefined,
    activeScreenTimeMs: activeScreenTime,
    nextIndex: nextItemIndex,
  };

  if (nextChunkRequestTimestamp !== undefined) {
    update.nextFragmentRequestTimestamp = nextChunkRequestTimestamp;
  }

  if (isEqual(update, currentPlaybackState)) {
    return state;
  } else {
    return updateTimelinePlayback(state, timelineId, update);
  }
}

function updateTimelinePlayback(
  state: PlayerState,
  timelineId: string,
  update: Partial<TimelinePlaybackState>
): PlayerState {
  const currentPlaybackState = state.playback.timelines[timelineId];

  const updatedPlaybackState = {
    ...currentPlaybackState,
    ...update,
  };

  if (!isEqual(currentPlaybackState, updatedPlaybackState)) {
    return {
      ...state,
      playback: {
        ...state.playback,
        timelines: {
          ...state.playback.timelines,
          [timelineId]: updatedPlaybackState,
        },
      },
    };
  }

  return state;
}

function updateIsActiveItemTransitioningOut(
  state: PlayerState,
  timelineId: string,
  value: boolean
): PlayerState {
  const targetTimelineState = state.playback.timelines[timelineId];

  if (
    targetTimelineState &&
    value !== targetTimelineState.isActiveItemTransitioningOut
  ) {
    return {
      ...state,
      playback: {
        ...state.playback,
        timelines: {
          ...state.playback.timelines,
          [timelineId]: {
            ...state.playback.timelines[timelineId],
            isActiveItemTransitioningOut: value,
          },
        },
      },
    };
  }

  return state;
}

function updateHasPreloadingStarted(
  state: PlayerState,
  timelineId: string,
  value: boolean
): PlayerState {
  return {
    ...state,
    playback: {
      ...state.playback,
      timelines: {
        ...state.playback.timelines,
        [timelineId]: {
          ...state.playback.timelines[timelineId],
          hasPreloadingStarted: value,
        },
      },
    },
  };
}
