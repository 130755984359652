export interface TokenRetryResponse {
  token: string | null;
  retryCount: number;
}

export interface TokenRetryError {
  source: "fetch" | "http" | "graphql" | "unknown";
  message: string;
  status?: number;
  retryCount: number;
  stackMessage?: string;
}

export const enum TokenType {
  Viewer = "Viewer",
  Management = "Management",
}

export interface Location {
  line: number;
  column: number;
}

export interface GraphQLError {
  locations: Location[];
  message: string;
  path: string[];
  stack: string;
}

export interface RetryOptions {
  maxAttempts?: number; // if undefined => retry will continue at maxRetryTImeoutMs rate until success
  retryTimeoutMs?: number; // starting point for retry backoff (in ms)
  maxRetryTimeoutMs?: number; // once the exponential backoff reaches this value the retries will be at this rate
}
