import { NormalizationUtilities } from "../storeTypes";
import { fileNormalization } from "../files/normalization";
import {
  getEmptyTraversalObject,
  mergeTraversalResult,
} from "../normalizationUtils";
import { PlayerState } from "../rootReducer";
import { NormalizedAppInstanceFragment } from "./types";
import { FileFragment, ThemeFragment } from "../../queries";
import { NormalizedOrgFragment } from "../organization/types";
import { appSchema } from "../normalizationSchemas";

export const appNormalization: NormalizationUtilities = {
  schema: appSchema,
  traverseFunction: (appInstanceId: string, state: PlayerState) => {
    let result = getEmptyTraversalObject();
    result.apps.add(appInstanceId);

    const targetApp = state.apps.byId[appInstanceId];

    if (!targetApp) {
      return result;
    }

    result = mergeTraversalResult(
      result,
      ...targetApp.fileIds.map((fileId) =>
        fileNormalization.traverseFunction(fileId, state)
      )
    );

    if (targetApp.fileByThumbnailFileId) {
      result = mergeTraversalResult(
        result,
        fileNormalization.traverseFunction(
          targetApp.fileByThumbnailFileId.id,
          state
        )
      );
    }

    return result;
  },
};

export type NormalizedAppInstanceEntities = {
  apps: { [key: string]: NormalizedAppInstanceFragment } | null;
  files: { [key: string]: FileFragment } | null;
  orgs: { [key: string]: NormalizedOrgFragment } | null;
  themes: { [key: string]: ThemeFragment } | null;
};
