interface BillingFeatureFlag {
  data: boolean;
  key: string;
}

export const getMergedFeatureFlags = (
  billingFeatureFlags: BillingFeatureFlag[],
  featureFlags: (string | null)[]
): string[] => {
  const filteredFeatureFlag = featureFlags.filter(
    (f) => f !== null
  ) as string[];
  return [
    ...filteredFeatureFlag,
    ...billingFeatureFlags
      .map((billingFeature) => billingFeature.key)
      .filter(
        (billingFeatureFlag) =>
          filteredFeatureFlag.indexOf(billingFeatureFlag) < 0
      ),
  ];
};
