import React, { ReactElement, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { TimelineViewerContainer } from "../TimelineViewer/TimelineViewer";
import { Loading } from "../../../core/components/Loading/Loading";
import { requestPlaylistSuccess } from "../../../../store/playlists/actions";
import { ContentList } from "../../../../store/contentLists/types";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { EntityType } from "@screencloud/signage-firestore-client";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import { PlaybackControlContainer } from "../../../core/containers/PlaybackControlContainer/PlaybackControlContainer";
import { ConfigurationManager } from "../../../../configurationManager";
import { usePlaylistById } from "../../../../queries";

interface PlaylistViewerContainerProps {
  id: string;
  isRoot?: boolean;
}

/**
 * Get the specified Playlist.
 */
export const PlaylistViewerContainer = (
  props: PlaylistViewerContainerProps
): ReactElement<PlaylistViewerContainerProps> => {
  const dispatch = useDispatch();
  const playlist = useSelector<PlayerState, ContentList | undefined>(
    (state) => state.contentLists.byId[props.id]
  );
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(!!playlist);

  const [fetchPlaylist, { data, loading }] = usePlaylistById({
    useCache: false,
    skipCache: true,
    variables: {
      id: props.id,
    },
  });

  useInitialFetch(!!props.isRoot, fetchPlaylist);

  useEffect(() => {
    if (data?.playlistById) {
      dispatch(requestPlaylistSuccess(data.playlistById));
      setIsDataLoaded(true);
    }
  }, [data?.playlistById, dispatch]);

  if (!playlist) {
    return (
      <p hidden={loading}>
        Sorry, we can&apos;t find the playlist you are looking for.
      </p>
    );
  }

  return (
    <>
      <PlaybackControlContainer
        type={"playlist"}
        id={props.id}
        sdkInterface={ConfigurationManager.getInstance().getRemoteApi().remote}
      />
      <LiveUpdateConnector
        entityId={props.id}
        entityType={EntityType.PLAYLIST}
      />
      {!isDataLoaded ? (
        <Loading />
      ) : (
        <TimelineViewerContainer id={props.id} key={props.id} />
      )}
    </>
  );
};
