import React, { FunctionComponent, useCallback } from "react";
import { GenericViewer } from "../../GenericViewer/GenericViewer";
import { TimelineItem } from "../../../../../store/timelines/types";
import { ContentFailureCallback, ContentFailureGenericCb } from "../types";

export interface SlotContentProps {
  item: TimelineItem;
  isPreload: boolean;
  onContentFailure: ContentFailureCallback;
  timelineItemIndex: number; // index of the item in the timeline items array
  hasNextItem?: boolean;
}

export const SlotContent: FunctionComponent<SlotContentProps> = (props) => {
  const {
    item,
    timelineItemIndex,
    onContentFailure,
    isPreload,
    hasNextItem,
  } = props;

  const onContentFailureCb = useCallback<ContentFailureGenericCb>(() => {
    return onContentFailure(timelineItemIndex);
  }, [onContentFailure, timelineItemIndex]);

  return (
    <GenericViewer
      contentItem={item}
      isPreload={isPreload}
      hasNextItem={hasNextItem}
      itemStartTimestamp={item.startTimestampMs}
      onContentFailure={onContentFailureCb}
    />
  );
};
