import { QRCode } from "@screencloud/qr-key-generator";
import QRCodeGenerator from "qrcode";
import { LocalStorage } from "../storage";
import { Logger } from "../logger/logger";
import { Scalars } from "../queries";
import { getDayInMs } from "./timeManager";
import { useSelector } from "react-redux";
import { PlayerState } from "../store/rootReducer";
import { useCreateAdditionalQrContext, useCreateQr } from "../queries/queries";
import { useEffect, useState } from "react";
import { UUID } from "@screencloud/uuid";

const log = new Logger("qrcode");
export const qrCache = "qr_cache";
export const QR_CLIENT_ID = "studio";

export interface QrCodeData {
  targetUrl: string;
  clientId: string;
  persistent: boolean;
  context: Scalars["JSON"];
}

export interface AdditionalKeyData {
  client_id?: string;
  context: Scalars["JSON"];
}

export enum QRcodePosition {
  BOTTOM_RIGHT = "bottom right",
  BOTTOM_LEFT = "bottom left",
  TOP_RIGHT = "top right",
  TOP_LEFT = "top left",
}

export const generateQR = async (text: string): Promise<string> => {
  try {
    const qrcode = await QRCodeGenerator.toDataURL(text);
    return qrcode;
  } catch (err) {
    log.error("failed to generate qr for [" + test + "]" + JSON.stringify(err));
    return "";
  }
};

export const getQrcodeStyleProperty = (
  qrcodePosition: QRcodePosition
): React.CSSProperties => {
  // `top left` ---> { top: 20px, left: 20px }
  return Object.fromEntries(
    qrcodePosition.split(" ").map((position) => [[position], "20px"])
  );
};

/**
 * call graphql to create the qr and get the url then store it in localStorage.
 */
export const useCreateQrCode = async (
  data: QrCodeData,
  isKeyExistInLocalStorage?: boolean,
  key?: string
) => {
  const { clientId, context, targetUrl, persistent } = data;
  const [
    fetchQRCodeUrl,
    { data: createdQRCodeData, error: createdQRCodeError },
  ] = useCreateQr({
    useCache: false,
    skipCache: true,
    variables: {
      input: {
        persistent,
        clientId,
        targetUrl,
        context,
      },
    },
  });

  useEffect(() => {
    if (!isKeyExistInLocalStorage) {
      fetchQRCodeUrl();
    }
  }, [fetchQRCodeUrl, isKeyExistInLocalStorage]);

  if (
    !createdQRCodeError &&
    createdQRCodeData?.createQr?.success &&
    createdQRCodeData?.createQr?.qr_url
  ) {
    const qrUrl = createdQRCodeData.createQr.qr_url;
    const key = qrUrl.split("/")[qrUrl.split("/").length - 1];
    const qrCacheData = LocalStorage.getInstance().getItem(qrCache);

    const newQrCacheData = {
      ...qrCacheData,
      [key]: new Date().getTime(),
    };
    LocalStorage.getInstance().setItem(qrCache, newQrCacheData);
    return qrUrl;
  } else if (isKeyExistInLocalStorage && key) {
    const qrScanUrl = process.env.REACT_APP_QR_CORE_SCAN_SERVICE_URL;
    return `${qrScanUrl}${key}`;
  } else {
    log.error("Failed to fetch QR code url");
    return;
  }
};

export const useCreateAdditionalKey = (
  data: AdditionalKeyData,
  isKeyExistInLocalStorage?: boolean,
  existingKey?: string
) => {
  const { context, client_id } = data;
  const [
    fetchAdditionalContextKey,
    { data: addtionalContextData, error: addtionalContextError },
  ] = useCreateAdditionalQrContext({
    useCache: false,
    skipCache: true,
    variables: {
      input: {
        clientId: client_id ?? QR_CLIENT_ID,
        context,
      },
    },
  });

  useEffect(() => {
    if (!isKeyExistInLocalStorage) {
      fetchAdditionalContextKey();
    }
  }, [isKeyExistInLocalStorage, fetchAdditionalContextKey]);

  if (isKeyExistInLocalStorage && existingKey) {
    return existingKey;
  } else if (
    !addtionalContextError &&
    addtionalContextData?.createAdditionalQrContext?.success &&
    addtionalContextData?.createAdditionalQrContext?.key
  ) {
    const additionalContextKey =
      addtionalContextData.createAdditionalQrContext.key;
    const qrCacheData = LocalStorage.getInstance().getItem(qrCache);

    const newQrCacheData = {
      ...qrCacheData,
      [additionalContextKey]: new Date().getTime(),
    };
    LocalStorage.getInstance().setItem(qrCache, newQrCacheData);
    return additionalContextKey;
  }
  return "";
};

/**
 * If qrcode key is already exist in localStorage then just use the key to get the link.
 * If the key is not exist then call qrcode service to get the link.
 */
export const useGetQrCodeUrl = (data: QrCodeData) => {
  const { clientId, targetUrl, context, persistent } = data;

  const [url, setUrl] = useState<string>("");
  // Important: the order of property: value also affected to the output key
  const key = new QRCode().getKey({
    persistent: persistent,
    client_id: clientId,
    target_url: targetUrl,
    context: context,
  });
  const qrCacheData = LocalStorage.getInstance().getItem(qrCache) ?? {};

  const isKeyExistInLocalStorage = Object.keys(qrCacheData).some((qrCacheKey) =>
    qrCacheKey.includes(key)
  );
  useCreateQrCode(data, !!isKeyExistInLocalStorage, key)
    .then((result) => {
      setUrl(result ?? "");
    })
    .catch((err) => {
      log.error("unable to create qrcode: " + JSON.stringify(err));
      setUrl("");
    });

  return url;
};

export const useGetAdditionContextKey = () => {
  const screenId = useSelector<PlayerState, UUID>((state) => state.screen.id);

  const value = {
    client_id: QR_CLIENT_ID,
    context: {
      screen_id: screenId,
    },
  };

  // Important: the order of property: value also affected to the output key
  const key = new QRCode().getKey(value, false);

  const contextPayload: AdditionalKeyData = value;

  const qrCacheData = LocalStorage.getInstance().getItem(qrCache) ?? {};
  const isKeyExistInLocalStorage = !!Object.keys(
    qrCacheData
  ).some((qrCacheKey) => qrCacheKey.includes(key));

  const newKey = useCreateAdditionalKey(
    contextPayload,
    isKeyExistInLocalStorage,
    key
  );

  return newKey ?? "";
};

/**
 * clean up the expired qr key in localStorage after 24 hours pass.
 */
export const cleanupQrCache = () => {
  const qrCacheData: {
    [key: string]: number;
  } = LocalStorage.getInstance().getItem(qrCache);
  if (qrCacheData) {
    const cleanedQrCacheData = Object.fromEntries(
      Object.entries(qrCacheData).filter(([_key, timestamp]) => {
        const now = new Date().getTime();
        const dayOffset = getDayInMs(1);
        if (now - timestamp > dayOffset) {
          // key life is over 24 hrs
          return false;
        }
        return true;
      })
    );
    LocalStorage.getInstance().setItem(qrCache, cleanedQrCacheData);
    log.info(`Removing localStorage key ${qrCache}`);
  }
};

export const useShouldShowQRCode = ({
  qrcodeEnabled,
  target_url,
}: {
  qrcodeEnabled: boolean;
  target_url: string;
}) => {
  return qrcodeEnabled && target_url;
};
