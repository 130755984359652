import { RenderSlot } from "../../TimelineViewer/types";

export function getActiveItemRenderSlotIndex(
  renderSlots: RenderSlot[]
): number {
  return renderSlots.findIndex(isRenderSlotActive);
}

export function getPreloadItemRenderSlotIndex(
  renderSlots: RenderSlot[]
): number {
  return renderSlots.findIndex(isRenderSlotPreload);
}

export function isRenderSlotActive(slot: RenderSlot): boolean {
  return !!slot.item && !slot.isPreload;
}

export function isRenderSlotPreload(slot: RenderSlot): boolean {
  return !!slot.item && slot.isPreload;
}
