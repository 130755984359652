import PMI from "@screencloud/postmessage-interface";
import {
  ConfigurationManager,
  RemoteApiInterface,
  StudioPlayerParentConfiguration,
} from "./configurationManager";
import {
  PlaybackItemReport,
  ContentListUpdateReport,
} from "./modules/viewers/containers/TimelineViewer/types";
import { getLogger } from "./utils/debugLog";
import { DeviceInfo } from "./remoteApi.d";
import { Maybe } from "./queries";
const log = getLogger("configuration-manager");

// This class is used to communicate with JS Player which exposes an api to fetch
// token and device info etc.
class RemoteApi implements RemoteApiInterface {
  // todo: remote interface must be private. expose functional methods only.
  public remote = new PMI.RemoteInterface({
    connectTimeout: 10000,
  });

  connect(): Promise<void> {
    log("Trying to connect to remote api!");
    return this.remote.connect(window.parent).then(() => {
      log("Connected to remote api!");
    });
  }
  private async getInitialiseDataFromJSPlayer(): Promise<
    StudioPlayerParentConfiguration
  > {
    const appData: StudioPlayerParentConfiguration = (await this.remote.call(
      "getAppData"
    )) as StudioPlayerParentConfiguration;
    const mediacacheBaseUrl: string = (await this.remote.call(
      "getMediaCacheURL"
    )) as string;
    const parentConf = { ...appData, mediacacheBaseUrl };
    log("Parent conf from JS Player", parentConf);
    return parentConf;
  }

  private getInitialiseDataFromPlayerSDK(): Promise<
    StudioPlayerParentConfiguration
  > {
    return this.remote.call("getSPInitialiseData") as Promise<
      StudioPlayerParentConfiguration
    >;
  }

  public reportPlaybackStateUpdate(
    playbackItemReport: PlaybackItemReport,
    zoneId?: string
  ): void {
    return ConfigurationManager.getInstance()
      .getRemoteApi()
      .remote.fire("SP_ACTIVE_ITEM_CHANGE", {
        payload: {
          activeItem: playbackItemReport,
          zoneId,
        },
      });
  }

  public reportContentListUpdate(
    contentListReport: ContentListUpdateReport
  ): void {
    return ConfigurationManager.getInstance()
      .getRemoteApi()
      .remote.fire("SP_CONTENT_LIST_REPORT_UPDATE", {
        payload: {
          report: contentListReport,
        },
      });
  }

  // resolve with correct data end point
  async getInitialiseData(): Promise<StudioPlayerParentConfiguration> {
    return this.getInitialiseDataFromPlayerSDK()
      .catch(() => this.getInitialiseDataFromJSPlayer())
      .catch((err) => {
        console.error("Could not connect to a Remote API.", err);
        throw err;
      });
  }

  async getDeviceInfoFromJSPlayer(): Promise<Maybe<DeviceInfo>> {
    try {
      const deviceInfo = (await ConfigurationManager.getInstance()
        .getRemoteApi()
        .remote.call("getDeviceInfo")) as DeviceInfo;
      return deviceInfo;
    } catch (err) {
      console.error(
        "Can't call getDeviceInfo from parent frame, this can causes from js-player of this native device just doesn't support the interface.",
        err
      );
      return {};
    }
  }
}

export default RemoteApi;
