import React, { FunctionComponent } from "react";
import { useEmbedMenu } from "../../../../../providers/MenuProvider/MenuProvider";
import { PlaylistContent } from "../../../../../providers/MenuProvider/types";
import { filterByPlaylistItems } from "../../../../../providers/MenuProvider/utils";
import GridIcon from "../../../../../img/grid-view.svg";
import styles from "./Playlist.module.css";
import { PlaylistSwiper } from "./PlaylistSwiper";

interface Props {
  playlist: PlaylistContent;
}

const Playlist: FunctionComponent<Props> = ({ playlist }: Props) => {
  const { items, navigateToItem, setPlaylist, currentIndex } = useEmbedMenu();

  const playlistItems = filterByPlaylistItems(items, playlist.id);

  return (
    <div key={playlist.id} className={styles.playlistRowContainer}>
      <div className={styles.playlistHeader}>
        <div
          className={styles.playlistName}
          onClick={() => {
            setPlaylist(playlist.id);
          }}
        >
          <img src={GridIcon} className={styles.gridIcon} alt="Images" />
          {playlist.name}
        </div>
      </div>
      <PlaylistSwiper
        allItems={items}
        playlistItems={playlistItems}
        navigateToItem={navigateToItem}
        currentIndex={currentIndex}
      />
    </div>
  );
};

export default Playlist;
