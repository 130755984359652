import { BreakpointWithItems } from "../store/playback/types";
import { generateItemsForBreakpoints } from "./scheduleFilter";
import { Logger } from "../logger/logger";

const log = new Logger("scheduleFilterCache");

export interface CacheEntry {
  timestamp: number;
  inputs: FilterFunctionInputs;
  output: BreakpointWithItems[];
}

export type FilterFunctionInputs = Parameters<
  typeof generateItemsForBreakpoints
>;

export type Cache = CacheEntry[];

export interface ScheduleFilterCacheOptions {
  maxMemory: number; // in bytes
}

/**
 * Returns approximate memory consumption in bytes
 */
function estimateObjectMemoryConsumption(array: BreakpointWithItems[]): number {
  let bytes = 0;

  const estimateValue = (value: unknown) => {
    const valueType = typeof value;

    switch (valueType) {
      case "number":
        bytes += 8; // number is always 8 byte integer
        break;
      case "string":
        bytes += (value as string).length * 2; // assume 1 latin symbol is 2 bytes
        break;
      case "object":
        if (Array.isArray(value)) {
          value.forEach(estimateValue);
        } else {
          bytes += 8; // assume an object reference is 8 bytes on a 64-bit system
        }
        break;
    }
  };

  array.forEach((object) => {
    Object.values(object).forEach((value) => {
      estimateValue(value);
    });
  });

  if (window.isNaN(bytes)) {
    log.warn("Object size estimation value is not a number.");
    return 0;
  }

  return bytes;
}

export class ScheduleFilterCache {
  private static instance: ScheduleFilterCache | undefined = undefined;

  public static getInstance(): ScheduleFilterCache | undefined {
    if (ScheduleFilterCache.instance) {
      return ScheduleFilterCache.instance;
    } else {
      return undefined;
    }
  }

  public static init(options: ScheduleFilterCacheOptions): ScheduleFilterCache {
    ScheduleFilterCache.instance = new ScheduleFilterCache(options);
    return ScheduleFilterCache.instance;
  }

  constructor(private options: ScheduleFilterCacheOptions) {}

  private cache: Cache = [];

  private memoryConsumption = 0; // in bytes

  public get size(): number {
    return this.cache.length;
  }

  public get memoryImpact(): number {
    return this.memoryConsumption;
  }

  public add(
    functionInputs: FilterFunctionInputs,
    functionOutput: BreakpointWithItems[]
  ): void {
    const existingEntryIndex = this.findExistingEntryIndex(functionInputs);

    const newEntry = {
      timestamp: new Date().getTime(),
      inputs: functionInputs,
      output: functionOutput,
    };
    if (existingEntryIndex < 0) {
      this.cache.push(newEntry);
    } else {
      this.memoryConsumption -= estimateObjectMemoryConsumption(
        this.cache[existingEntryIndex].output
      );
      this.cache[existingEntryIndex] = newEntry;
    }
    this.memoryConsumption += estimateObjectMemoryConsumption(newEntry.output);

    if (this.memoryConsumption > this.options.maxMemory) {
      this.reduceMemoryConsumption();
    }
  }

  public fetch(
    functionInputs: FilterFunctionInputs
  ): BreakpointWithItems[] | undefined {
    const cacheEntry: CacheEntry | undefined = this.cache[
      this.findExistingEntryIndex(functionInputs)
    ];

    if (cacheEntry) {
      return cacheEntry.output;
    } else {
      return undefined;
    }
  }

  private findExistingEntryIndex(functionInputs: FilterFunctionInputs): number {
    return this.cache.findIndex((entry) => {
      if (
        window.isNaN(entry.inputs.length) ||
        entry.inputs.length === Infinity
      ) {
        // Protection from infinite loops. This infinite loop protection doesn't make sense in real life, it's just
        //  a precaution measure for 100% protection from infinite loops.
        return false;
      }

      for (let i = 0; i < entry.inputs.length; i++) {
        if (functionInputs[i] !== entry.inputs[i]) {
          return false;
        }
      }

      return true;
    });
  }

  private reduceMemoryConsumption(): void {
    let reduceSize = this.memoryConsumption - this.options.maxMemory;

    if (window.isNaN(reduceSize) || reduceSize === Infinity) {
      // this is a part of additional infinite loop protection
      log.warn("Memory reduction size estimation is not a number");
      return;
    }

    if (this.cache.length === Infinity || window.isNaN(this.cache.length)) {
      log.warn("Cache length is not a number.");
      return;
    }

    while (reduceSize > 0 && this.cache.length > 0) {
      const removedElement = this.cache.shift();
      if (removedElement) {
        const memoryEstimation = estimateObjectMemoryConsumption(
          removedElement.output
        );
        reduceSize -= memoryEstimation;
        this.memoryConsumption -= memoryEstimation;
      }
    }
  }
}
