import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { cleanupStaleEntitiesAction } from "../../../../store/cleanup/actions";
import { HOUR_DURATION_MS } from "../../../../constants";

export const StoreCleanupContainer: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = window.setInterval(() => {
      dispatch(cleanupStaleEntitiesAction());
    }, HOUR_DURATION_MS * 4);

    return () => {
      window.clearInterval(interval);
    };
  }, [dispatch]);

  return <></>;
};
