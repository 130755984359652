import { FullscreenContent } from "./useFullscreenContent";
import { RenderSlot } from "../../../TimelineViewer/types";
import { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { TimelineItem } from "../../../../../../store/timelines/types";

export function findExistingSlotIndex(
  timelineItem: TimelineItem,
  renderSlots: RenderSlot[]
): number {
  return renderSlots.findIndex((slot) => slot.item === timelineItem);
}

/**
 * Converts the fullscreen content report into slots data structure to be used for rendering
 */
export function useFullscreenRenderSlots(
  fullscreenContent: FullscreenContent
): RenderSlot[] {
  const { active, preload } = fullscreenContent;

  const [fullscreenRenderSlots, setFullscreenRenderSlots] = useState<
    RenderSlot[]
  >([]);

  useEffect(() => {
    const updatedSlots: RenderSlot[] = [...fullscreenRenderSlots];

    const freeSlotIndexes = { "1": 1, "0": 0 };

    const existingActiveSlotIndex = active?.item
      ? findExistingSlotIndex(active.item, updatedSlots)
      : -1;
    const existingPreloadSlotIndex = preload?.item
      ? findExistingSlotIndex(preload.item, updatedSlots)
      : -1;

    if (existingActiveSlotIndex > -1) {
      delete freeSlotIndexes[
        existingActiveSlotIndex.toString(10) as keyof typeof freeSlotIndexes
      ];
    }
    if (existingPreloadSlotIndex > -1) {
      delete freeSlotIndexes[
        existingPreloadSlotIndex.toString(10) as keyof typeof freeSlotIndexes
      ];
    }

    const freeSlotIndexValues = Object.values(freeSlotIndexes);
    const activeSlotIndex =
      existingActiveSlotIndex > -1
        ? existingActiveSlotIndex
        : freeSlotIndexValues.pop();
    const preloadSlotIndex =
      existingPreloadSlotIndex > -1
        ? existingPreloadSlotIndex
        : freeSlotIndexValues.pop();

    if (
      typeof activeSlotIndex !== "number" ||
      typeof preloadSlotIndex !== "number"
    ) {
      throw new Error(
        `useFullscreenRenderSlots logic error: slot indexes must be numeric. Got: ${activeSlotIndex}, ${preloadSlotIndex}`
      );
    }

    updatedSlots[activeSlotIndex] = {
      timelineId: active?.timelineId,
      item: active?.item,
      indexInTimeline: active?.indexInTimeline,
      isPreload: false,
    };
    updatedSlots[preloadSlotIndex] = {
      timelineId: preload?.timelineId,
      item: preload?.item,
      indexInTimeline: preload?.indexInTimeline,
      isPreload: preload ? true : false,
    };

    if (!isEqual(fullscreenRenderSlots, updatedSlots)) {
      setFullscreenRenderSlots(updatedSlots);
    }
  }, [active, preload, fullscreenRenderSlots]);

  return fullscreenRenderSlots;
}
