import { SitesState } from "./../../store/sites/types";
import { MenuContentItem } from "./types";
import { ContentItemLeaf } from "./../../store/contentLists/types";
import { TimelineItem } from "./../../store/timelines/types";
import { LinksState } from "./../../store/links/types";
import { FilesState } from "./../../store/files/types";
import { AppsState } from "./../../store/apps/types";
import { MenuItemType } from "../../modules/menu/InteractiveMenu/types";
import { FileProcessingClientInterface } from "../../types/filesClient";
import DashboardsTileIcon from "../../img/dashboards-tile.svg";
import FontFaceObserver from "fontfaceobserver";

const thumbnailOptions = {
  size: { w: 204, h: 114 },
};

const getPlaylistId = (item: ContentItemLeaf): string => {
  return item.parent ? item.parent.id : "";
};

export const mapMenuContentItems = (
  fileProcessingClient: FileProcessingClientInterface | undefined,
  contentItems: ContentItemLeaf[],
  apps: AppsState,
  files: FilesState,
  links: LinksState,
  sites: SitesState,
  secureMediaPolicy: string | undefined
): MenuContentItem[] => {
  return contentItems.map((item) => {
    if (item.type === "app") {
      const app = apps.byId[item.id];
      if (app.slug === "canvas" && app.fileByThumbnailFileId) {
        return {
          id: app.id,
          name: app.name,
          thumbnail: app.fileByThumbnailFileId.source || app.iconUrl,
          type: "app",
          fullDurationMs: Infinity,
          listId: item.listId,
          playlistId: getPlaylistId(item),
          slug: app.slug || "",
        };
      }
      // treat video apps as a video type
      else if (
        app.slug === "youtube" ||
        app.slug === "youtube-live" ||
        app.slug === "vimeo" ||
        app.slug === "live-news"
      ) {
        return {
          id: app.id,
          name: app.name,
          thumbnail: app.iconUrl,
          type: "app",
          fullDurationMs: Infinity,
          sizeType: item.sizeType,
          listId: item.listId,
          playlistId: getPlaylistId(item),
          appType: "video",
          slug: app.slug || "",
        };
      } else {
        return {
          id: app.id,
          name: app.name,
          thumbnail: app.iconUrl,
          type: "app",
          fullDurationMs: Infinity,
          listId: item.listId,
          playlistId: getPlaylistId(item),
          slug: app.slug || "",
        };
      }
    } else if (item.type === "file") {
      const file = files.byId[item.id];
      if (file.type === "image") {
        return {
          id: file.id,
          name: file.name,
          thumbnail: fileProcessingClient?.getImgixUrl(
            file.urlKey,
            {
              ...thumbnailOptions,
              sizingType: "fill",
            },
            secureMediaPolicy
          ),
          type: "file",
          fileType: "image",
          fullDurationMs: Infinity,
          sizeType: item.sizeType,
          listId: item.listId,
          playlistId: getPlaylistId(item),
        };
      } else if (file.type === "video") {
        return {
          id: file.id,
          name: file.name,
          thumbnail: fileProcessingClient?.getImgixUrl(
            file.thumbnail || "",
            {
              ...thumbnailOptions,
              sizingType: "fill",
            },
            secureMediaPolicy
          ),
          type: "file",
          fileType: "video",
          fullDurationMs: Infinity,
          sizeType: item.sizeType,
          listId: item.listId,
          playlistId: getPlaylistId(item),
        };
      } else if (file.type === "document") {
        return {
          id: file.id,
          name: file.name,
          // name: "Document",
          thumbnail: fileProcessingClient?.getImgixUrl(
            file.images[0].urlKey || "",
            { ...thumbnailOptions, sizingType: "fill" },
            secureMediaPolicy
          ),
          type: "file",
          fileType: "document",
          fullDurationMs: Infinity,
          sizeType: item.sizeType,
          listId: item.listId,
          playlistId: getPlaylistId(item),
        };
      }
    } else if (item.type === "link") {
      const link = links.byId[item.id];
      const file = files.byId[link.fileId || ""];
      let thumbnail = undefined;
      if (file && file.type === "image") {
        thumbnail = fileProcessingClient?.getImgixUrl(
          file.urlKey,
          {
            ...thumbnailOptions,
            sizingType: "fill",
          },
          secureMediaPolicy
        );
      }
      return {
        id: link.id,
        type: "link",
        name: link.name,
        thumbnail,
        fullDurationMs: Infinity,
        listId: item.listId,
        playlistId: getPlaylistId(item),
      };
    } else if (item.type === "site") {
      const site = sites.byId[item.id];
      const file = files.byId[site.fileByThumbnailId || ""];
      let thumbnail = undefined;
      if (file?.type === "image") {
        thumbnail = fileProcessingClient?.getImgixUrl(
          file.urlKey,
          {
            ...thumbnailOptions,
            sizingType: "fill",
          },
          secureMediaPolicy
        );
      }
      if (thumbnail === undefined) {
        thumbnail = DashboardsTileIcon;
      }

      return {
        id: site.id,
        type: "site",
        name: site.name,
        thumbnail,
        fullDurationMs: Infinity,
        listId: item.listId,
        playlistId: getPlaylistId(item),
      };
    }
    return {
      type: "void",
    };
  });
};

export const getCurrentItemId = (
  timelineItems: TimelineItem[],
  currentActiveIndex?: number
): string | undefined => {
  if (currentActiveIndex !== undefined) {
    const item = timelineItems[currentActiveIndex];
    if (item && item.type !== "void") {
      return item.listId;
    }
  }
  return undefined;
};

const filterItemsBySearch = (
  search: string,
  items: MenuContentItem[]
): MenuContentItem[] => {
  return items.filter((item) => {
    return (
      item.type !== "void" &&
      (search.length < 1 ||
        item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    );
  });
};

const filterItemsByType = (
  menuType: MenuItemType,
  items: MenuContentItem[]
): MenuContentItem[] => {
  let itemsByActiveType: MenuContentItem[] = [];
  if (menuType === MenuItemType.All) {
    itemsByActiveType = items;
  } else {
    items.forEach((item: MenuContentItem) => {
      if (item.type === "file") {
        if (
          (menuType === MenuItemType.Video &&
            item.fileType === MenuItemType.Video) ||
          (menuType === MenuItemType.Image &&
            item.fileType === MenuItemType.Image) ||
          (menuType === MenuItemType.Document &&
            item.fileType === MenuItemType.Document)
        ) {
          itemsByActiveType.push(item);
        }
      }
      // if the app item contains appType = video, add to video content list
      if (
        item.type === "app" &&
        menuType === MenuItemType.Video &&
        item.appType === "video"
      ) {
        itemsByActiveType.push(item);
      } else if (item.type === "app" && menuType === MenuItemType.Apps) {
        itemsByActiveType.push(item);
      } else if (item.type === "site" && menuType === MenuItemType.Dashboards) {
        itemsByActiveType.push(item);
      } else if (item.type === "link" && menuType === MenuItemType.Links) {
        itemsByActiveType.push(item);
      }
    });
  }
  return itemsByActiveType;
};

export const searchItems = (
  items: MenuContentItem[],
  search: string
): MenuContentItem[] => {
  return filterItemsBySearch(search, items);
};

export const filterByPlaylistItems = (
  items: MenuContentItem[],
  playlistId: string
): MenuContentItem[] => {
  return items.filter(
    (item) => item.type !== "void" && item.playlistId === playlistId
  );
};

export const filterItems = (
  items: MenuContentItem[],
  type: MenuItemType,
  playlistId?: string
): MenuContentItem[] => {
  if (type === "playlist" && playlistId) {
    return filterByPlaylistItems(items, playlistId);
  }
  return filterItemsByType(type, items);
};

const getFontCrossOriginType = (fontUrl: string): string | null => {
  // CORS is not generally supported on localhost for security reasons.
  // This will increase the cache size when testing locally
  if (window.location.host === "localhost") {
    return null;
  }
  // Google Fonts & Adobe Typekit support CORS
  if (
    fontUrl.includes("fonts.googleapis.com") ||
    fontUrl.includes("typekit.net")
  ) {
    return "anonymous";
  }
  // FontLibrary does not support CORS
  return null;
};

export const loadFont = (
  fontFamily: string,
  fontUrl: string
): Promise<{ fontFamily: string; fontUrl: string }> => {
  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.href = fontUrl;
    link.rel = "stylesheet";
    link.crossOrigin = getFontCrossOriginType(fontUrl);

    document.head.append(link);

    const font = new FontFaceObserver(fontFamily, {
      weight: "normal",
      style: "normal",
    });

    font
      .load(undefined, 15000)
      .then(() => {
        resolve({ fontFamily, fontUrl });
      })
      .catch((e) => {
        reject({ fontFamily, fontUrl });
      });
  });
};
