import { useCallback, useRef, useState } from "react";
import { useIsMounted } from "./useIsMounted";

export type RetryCallback<T extends Array<unknown> = unknown[]> = (
  ...args: T
) => void;

export interface RetryCbOptions<
  RetryFunctionArguments extends Array<unknown> = unknown[]
> {
  maxAttempts?: number;
  retryTimeoutMs?: number;
  exponentialTimeout?: boolean;
  retryLogicCb: RetryCallback<RetryFunctionArguments>;
  onReachMaxAttempt?: () => void;
}

const DEFAULT_RETRY_TIMEOUT_MS = 5000;
const DEFAULT_MAX_ATTEMPTS = 3;

/**
 * Returns a retry callback that can be called on some event (typically an error) and execute a defined retry logic.
 * Provides a way to define types of timeouts and maximum attempts number.
 * Cancels timeouts in case the components was unmounted.
 */
export const useRetryCallback = <
  RetryFunctionArgs extends Array<unknown> = unknown[]
>(
  options: RetryCbOptions<RetryFunctionArgs>
): RetryCallback<RetryFunctionArgs> => {
  const { exponentialTimeout, retryLogicCb, onReachMaxAttempt } = options;
  const isMounted = useIsMounted();

  const maxAttempts = options.maxAttempts ?? DEFAULT_MAX_ATTEMPTS;
  const retryTimeoutMs = options.retryTimeoutMs ?? DEFAULT_RETRY_TIMEOUT_MS;

  const timeout = useRef<number | null>(null);

  const [attemptNumber, setAttemptNumber] = useState(0);

  const timeoutValue = exponentialTimeout
    ? Math.pow(2, attemptNumber) * 1000
    : retryTimeoutMs;

  const retryCb = useCallback<RetryCallback<RetryFunctionArgs>>(
    (...args) => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }

      if (attemptNumber > maxAttempts) {
        if (onReachMaxAttempt) {
          onReachMaxAttempt();
        }
        return;
      }

      if (timeoutValue && timeoutValue > 0) {
        timeout.current = window.setTimeout(() => {
          if (isMounted()) {
            retryLogicCb(...args);
            setAttemptNumber(attemptNumber + 1);
          }
        }, timeoutValue);
      } else {
        retryLogicCb(...args);
        setAttemptNumber(attemptNumber + 1);
      }
    },
    [
      retryLogicCb,
      timeoutValue,
      attemptNumber,
      maxAttempts,
      onReachMaxAttempt,
      isMounted,
    ]
  );

  return retryCb;
};
