import { useState, useEffect, useMemo } from "react";
import {
  PlaybackState,
  TimelinePlaybackState,
} from "../../../../store/playback/types";
import { TimelinesState } from "../../../../store/timelines/types";
import { getContentContext } from "./contentContext";
import { AppsState } from "../../../../store/apps/types";
import { FilesState } from "../../../../store/files/types";
import { LinksState } from "../../../../store/links/types";
import { ScreenState } from "../../../../store/screen/types";
import { ChannelsState } from "../../../../store/channels/types";
import { PlaylistsState } from "../../../../store/playlists/types";
import { LayoutsState } from "../../../../store/layouts/types";
import { OrganizationState } from "../../../../store/organization/types";
import { getStudioPlayerVersion } from "../../../../utils/helpers";
import {
  parseContentListId,
  parseLocalTimelineId,
} from "../../../../store/contentLists/utils";
import { getSourceDetail } from "./sourceDetail";
import { SitesState } from "../../../../store/sites/types";
import { Logger, LogLevel, LogObjects } from "../../../../logger/logger";

export const log = new Logger("timelinePlayBackLogs");

interface TimelinePlaybackLogsProps {
  apps: AppsState;
  files: FilesState;
  links: LinksState;
  sites: SitesState;
  playlists: PlaylistsState;
  screen: ScreenState;
  platform: string;
  playbackState: PlaybackState;
  timelines: TimelinesState;
  channels: ChannelsState;
  layouts: LayoutsState;
  organization: OrganizationState;
}

interface TimelinePlayback {
  [key: string]: TimelinePlaybackState;
}

interface Timeline {
  id: string;
  zone: string;
}

export const useTimelinePlaybackLogs = ({
  apps,
  files,
  links,
  sites,
  screen,
  playbackState,
  timelines,
  platform,
  channels,
  layouts,
  organization,
  playlists,
}: TimelinePlaybackLogsProps): void => {
  const [previousTimelinesPlayback, setPreviousTimelinesPlayback] = useState<
    TimelinePlayback
  >({});
  const activeContentItem = screen.activeContentItem;
  const isPreview = false; // force to false since no Mock of PlayerState
  const activeTimelinesWithZoneData = useMemo<Timeline[]>(() => {
    if (!activeContentItem) return [];

    if (activeContentItem.type === "channel") {
      const layoutId = channels.byId[activeContentItem.id].layoutId;
      const zones = layouts.byId[layoutId]?.zones || [];
      return zones.map((zone) => {
        return {
          zone: zone.id,
          id: zone.contentListId,
        };
      });
    } else if (activeContentItem?.type === "playlist") {
      return [
        {
          zone: "root",
          id: activeContentItem.id,
        },
      ];
    }
    return [];
  }, [activeContentItem, channels, layouts]);

  if (
    activeContentItem &&
    (activeContentItem.type === "channel" ||
      activeContentItem.type === "playlist")
  ) {
    activeTimelinesWithZoneData.forEach((activeTimeline) => {
      if (
        previousTimelinesPlayback[activeTimeline.id]?.activeIndex !==
        playbackState.timelines[activeTimeline.id]?.activeIndex
      ) {
        const activePlaybackTimeline =
          playbackState.timelines[activeTimeline.id];
        const activeTimelineItem =
          timelines.byId[activeTimeline.id]?.items?.[
            activePlaybackTimeline.activeIndex || 0
          ];
        const parsedContentListId = parseContentListId(
          parseLocalTimelineId(activeTimeline.id).sourceContentListId
        );

        const previousItemIndex =
          previousTimelinesPlayback[activeTimeline.id]?.activeIndex;
        const emptyItem = {
          zoneId: activeTimeline.zone,
        };
        const logContext = {
          ...emptyItem,
          isPreview: isPreview,
          playbackType: "timeline",
        };
        if (!activeTimelineItem) {
          log.info({
            message: "Screen is empty. No active item assigned.",
            context: logContext,
          });
          return;
        } else if (activeTimelineItem.type === "void") {
          log.info({
            message: "Screen is empty. No item assigned to screen.",
            context: logContext,
          });
          return;
        }

        // previous item is exists
        if (previousItemIndex !== undefined) {
          const previousTimelineItem =
            timelines.byId[activeTimeline.id]?.items?.[previousItemIndex];

          if (!previousTimelineItem || previousTimelineItem?.type === "void") {
            log.info({
              message: `Finish empty screen period.`,
              context: {
                previousTimelineItemType: previousTimelineItem?.type,
                isPreview: isPreview,
                playbackType: "timeline",
              },
            });
          } else {
            const finishPlaybackLog: LogObjects = {
              message: "Finish playback",
              context: {
                listId: previousTimelineItem.listId,
                platform,
                status: screen.status,
                screenId: screen.id,
                spaceId: screen.spaceId || "unavailable",
                deviceId: screen.deviceId,
                contentId: previousTimelineItem.id,
                contentType: previousTimelineItem.type,
                ...getSourceDetail(
                  "timeline",
                  activeContentItem,
                  parsedContentListId,
                  channels,
                  playlists
                ),
                playerVersion: getStudioPlayerVersion(),
                ...getContentContext(
                  previousTimelineItem.id,
                  previousTimelineItem.type,
                  apps,
                  files,
                  links,
                  sites
                ),
                screenName: screen.name,
                location: screen.screenData?.sc_address,
                orgId: organization.id,
                zoneId: activeTimeline.zone,
                isPreview: isPreview,
                playbackType: "timeline",
              },
              proofOfPlayFlag: true,
            };
            log.debug(finishPlaybackLog);
            log.proofOfPlayLog(LogLevel.Info, finishPlaybackLog);
          }
        }
        const startPlaybackLog: LogObjects = {
          message: "Start playback",
          context: {
            listId: activeTimelineItem.listId,
            platform,
            status: screen.status,
            screenId: screen.id,
            spaceId: screen.spaceId || "unavailable",
            deviceId: screen.deviceId,
            contentId: activeTimelineItem.id,
            contentType: activeTimelineItem.type,
            ...getSourceDetail(
              "timeline",
              activeContentItem,
              parsedContentListId,
              channels,
              playlists
            ),
            playerVersion: getStudioPlayerVersion(),
            ...getContentContext(
              activeTimelineItem.id,
              activeTimelineItem.type,
              apps,
              files,
              links,
              sites
            ),
            screenName: screen.name,
            location: screen.screenData?.sc_address,
            orgId: organization.id,
            zoneId: activeTimeline.zone,
            isPreview: isPreview,
            playbackType: "timeline",
          },
          proofOfPlayFlag: true,
        };
        log.debug(startPlaybackLog);
        log.proofOfPlayLog(LogLevel.Info, startPlaybackLog);
      }
    });
  } else {
    log.info({
      message: "No active item assigned.",
      context: {
        isPreview: isPreview,
        playbackType: "timeline",
      },
    });
  }

  useEffect(() => {
    // reset previous timeline playback state when content item is changed to a single content
    if (
      activeContentItem &&
      activeContentItem.type !== "channel" &&
      activeContentItem.type !== "playlist"
    ) {
      setPreviousTimelinesPlayback({});
    } else {
      if (Object.keys(playbackState.timelines).length === 0) return;
      setPreviousTimelinesPlayback(playbackState.timelines);
    }
  }, [activeContentItem, playbackState.timelines]);
};
