import { TraversalResult } from "./storeTypes";
import { ContentGenericType } from "../types/content";
import { PlayerState } from "./rootReducer";
import { playlistNormalization } from "./playlists/normalization";
import { appNormalization } from "./apps/normalization";
import { fileNormalization } from "./files/normalization";
import { linkNormalization } from "./links/normalization";
import { siteNormalization } from "./sites/normalization";
import { orgNormalization } from "./organization/normalization";
import { screenNormalization } from "./screen/normalization";
import { channelNormalization } from "./channels/normalization";

export function mergeTraversalResult(
  ...args: TraversalResult[]
): TraversalResult {
  return {
    channels: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.channels], [])
    ),
    playlists: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.playlists], [])
    ),
    sites: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.sites], [])
    ),
    links: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.links], [])
    ),
    layouts: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.layouts], [])
    ),
    apps: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.apps], [])
    ),
    files: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.files], [])
    ),
    spaces: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.spaces], [])
    ),
    themes: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.themes], [])
    ),
    contentLists: new Set(
      args.reduce<string[]>((sum, item) => [...sum, ...item.contentLists], [])
    ),
  };
}

export function getEmptyTraversalObject(): TraversalResult {
  return {
    channels: new Set(),
    playlists: new Set(),
    sites: new Set(),
    links: new Set(),
    layouts: new Set(),
    apps: new Set(),
    files: new Set(),
    spaces: new Set(),
    themes: new Set(),
    contentLists: new Set(),
  };
}

export function traverseEntity(
  contentType: ContentGenericType,
  contentId: string,
  state: PlayerState
): TraversalResult {
  let result: TraversalResult;
  switch (contentType) {
    case "screen":
      result = screenNormalization.traverseFunction(contentId, state);
      break;

    case "channel":
      return channelNormalization.traverseFunction(contentId, state);

    case "playlist":
      result = playlistNormalization.traverseFunction(contentId, state);
      break;

    case "app":
      result = appNormalization.traverseFunction(contentId, state);
      break;

    case "file":
      result = fileNormalization.traverseFunction(contentId, state);
      break;

    case "link":
      result = linkNormalization.traverseFunction(contentId, state);
      break;

    case "site":
      result = siteNormalization.traverseFunction(contentId, state);
      break;

    default:
      throw new Error(`Unknown content type ${contentType}`);
  }

  if (state.organization) {
    result = mergeTraversalResult(
      result,
      orgNormalization.traverseFunction(state.organization.id, state)
    );
  }

  return result;
}

export function traverseContentList(
  contentListId: string,
  state: PlayerState
): TraversalResult {
  let result = getEmptyTraversalObject();
  result.contentLists.add(contentListId);

  const targetContentList = state.contentLists.byId[contentListId];

  if (!targetContentList) {
    return result;
  }

  result = mergeTraversalResult(
    result,
    ...targetContentList.items.map((item) =>
      traverseEntity(item.type, item.id, state)
    )
  );

  if (targetContentList.nestedPlaylistIds) {
    result = mergeTraversalResult(
      result,
      ...targetContentList.nestedPlaylistIds.map((playlistId) =>
        playlistNormalization.traverseFunction(playlistId, state)
      )
    );
  }

  return result;
}
