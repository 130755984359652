import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { TimelinePlaybackState } from "../../../../store/playback/types";
import {
  parseContentListId,
  parseLocalTimelineId,
} from "../../../../store/contentLists/utils";
import { Timeline, TimelineItem } from "../../../../store/timelines/types";
import { PlaybackItemReport } from "./types";
import ClientApiContext from "../../../../ClientApiContext";
import {
  ContentItemLeaf,
  ContentList,
} from "../../../../store/contentLists/types";

interface TimelinePlaybackReportContainerProps {
  timelineId: string;
}

export const TimelinePlaybackReportContainer: FunctionComponent<TimelinePlaybackReportContainerProps> = (
  props
) => {
  const clientApiContext = useContext(ClientApiContext);

  const { timelineId } = props;

  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) => {
    return state.playback.timelines[timelineId];
  });

  // preview item set selected by studio preview
  const previewItem = useSelector<PlayerState, TimelineItem | null>((state) => {
    return state.playback.controls.previewItem;
  });

  const targetTimeline = useSelector<PlayerState, Timeline | undefined>(
    (state) => {
      return state.timelines.byId[timelineId];
    }
  );

  const targetContentList = useSelector<PlayerState, ContentList | undefined>(
    (state) => {
      return state.contentLists.byId[
        parseLocalTimelineId(timelineId).sourceContentListId
      ];
    }
  );

  const targetTimelineItems = targetTimeline?.items;

  const activeTimelineItem = useMemo<TimelineItem | undefined>(() => {
    if (targetTimelineItems && playbackState?.activeIndex !== undefined) {
      return targetTimelineItems[playbackState.activeIndex];
    } else {
      return undefined;
    }
  }, [playbackState, targetTimelineItems]);

  const activeContentListItem = useMemo<ContentItemLeaf | undefined>(() => {
    if (targetContentList && activeTimelineItem?.type !== "void") {
      return targetContentList.items.find(
        (item) =>
          activeTimelineItem &&
          // @ts-ignore
          activeTimelineItem?.type !== "void" &&
          item.listId === activeTimelineItem.listId
      );
    }
  }, [targetContentList, activeTimelineItem]);

  const activePreviewListItem = useMemo<ContentItemLeaf | undefined>(() => {
    if (targetContentList && previewItem && previewItem?.type !== "void") {
      return targetContentList.items.find(
        (item) =>
          activeTimelineItem &&
          activeTimelineItem?.type !== "void" &&
          item.listId === previewItem.listId
      );
    }
  }, [targetContentList, previewItem, activeTimelineItem]);

  const activeZoneId = useMemo<string | undefined>(() => {
    const { sourceContentListId } = parseLocalTimelineId(timelineId);

    const parsedContentListId = parseContentListId(sourceContentListId);
    if (parsedContentListId.type !== "channel-zone") {
      return undefined;
    }
    const { zoneId } = parsedContentListId;

    return zoneId;
  }, [timelineId]);

  const activeItemReport = useMemo<PlaybackItemReport | undefined>(() => {
    if (
      !activeTimelineItem ||
      !playbackState ||
      playbackState.activeIndex === undefined ||
      targetTimeline === undefined
    ) {
      return undefined;
    }
    const result: PlaybackItemReport = {
      type: activeTimelineItem.type,
      startTimestamp:
        targetTimeline.items[playbackState.activeIndex].startTimestampMs,
    };

    // report preview item if playing
    if (
      previewItem &&
      previewItem?.type !== "void" &&
      activeTimelineItem.type !== "void"
    ) {
      result.id = previewItem.id;
      result.listId = previewItem.listId;
      result.parentEntityListId = activePreviewListItem?.parent?.listId;
    }

    // report activetimelineitem if previewitem is null
    if (activeTimelineItem.type !== "void" && !previewItem) {
      result.id = activeTimelineItem.id;
      result.listId = activeTimelineItem.listId;
      result.parentEntityListId = activeContentListItem?.parent?.listId;
    }
    return result;
  }, [
    activeTimelineItem,
    playbackState,
    targetTimeline,
    previewItem,
    activePreviewListItem?.parent?.listId,
    activeContentListItem?.parent?.listId,
  ]);

  useEffect(() => {
    if (activeItemReport && clientApiContext?.remoteApi) {
      clientApiContext.remoteApi.reportPlaybackStateUpdate(
        activeItemReport,
        activeZoneId
      );
    }
  }, [
    activeItemReport,
    activeZoneId,
    clientApiContext?.remoteApi,
    previewItem,
  ]);

  return <></>;
};
