import { RenderSlot } from "../../../TimelineViewer/types";
import { FullscreenTransitionDefinitions } from "../types";
import { useEffect, useMemo, useState } from "react";
import {
  GqlZoneItemTransition,
  TransitionType,
} from "../../../../../../store/graphqlTypes";
import isEqual from "lodash/isEqual";

/**
 * Returns transition props to apply to channel layout zone and to fullscreen zone when entering or exiting fullscreen mode
 */
export function useTransitionDefinitions(
  activeSlot: RenderSlot | undefined,
  preloadSlot: RenderSlot | undefined
): FullscreenTransitionDefinitions {
  const isFullscreenInTransitionActive =
    activeSlot?.item === undefined && preloadSlot?.item !== undefined;
  const isFullscreenOutTransitionActive =
    activeSlot?.item !== undefined && preloadSlot?.item === undefined;

  const preloadItemTransition = preloadSlot?.item?.transition;
  const activeItemTransition = activeSlot?.item?.transition;

  const [mainZoneTransition, setMainZoneTransition] = useState<
    GqlZoneItemTransition | undefined
  >(undefined);
  const [fullscreenZoneTransition, setFullscreenZoneTransition] = useState<
    GqlZoneItemTransition | undefined
  >(undefined);

  useEffect(() => {
    if (isFullscreenInTransitionActive && preloadItemTransition) {
      const newMainZoneTransition = produceOverridenTransitionObject(
        preloadItemTransition
      );

      if (!isEqual(newMainZoneTransition, mainZoneTransition)) {
        setMainZoneTransition(newMainZoneTransition);
      }
    }
  }, [
    isFullscreenInTransitionActive,
    mainZoneTransition,
    preloadItemTransition,
  ]);

  useEffect(() => {
    if (isFullscreenInTransitionActive && preloadItemTransition) {
      const newFullscreenZoneTransition = produceOverridenTransitionObject(
        preloadItemTransition
      );

      if (!isEqual(newFullscreenZoneTransition, fullscreenZoneTransition)) {
        setFullscreenZoneTransition(newFullscreenZoneTransition);
      }
    }
  }, [
    isFullscreenInTransitionActive,
    preloadItemTransition,
    fullscreenZoneTransition,
  ]);

  useEffect(() => {
    if (isFullscreenOutTransitionActive && activeItemTransition) {
      const newMainZoneTransition = produceOverridenTransitionObject(
        activeItemTransition
      );

      if (!isEqual(newMainZoneTransition, mainZoneTransition)) {
        setMainZoneTransition(newMainZoneTransition);
      }
    }
  }, [
    isFullscreenOutTransitionActive,
    mainZoneTransition,
    activeItemTransition,
  ]);

  useEffect(() => {
    if (isFullscreenOutTransitionActive && activeItemTransition) {
      const newFullscreenZoneTransition = produceOverridenTransitionObject(
        activeItemTransition
      );

      if (!isEqual(newFullscreenZoneTransition, fullscreenZoneTransition)) {
        setFullscreenZoneTransition(newFullscreenZoneTransition);
      }
    }
  }, [
    isFullscreenOutTransitionActive,
    fullscreenZoneTransition,
    activeItemTransition,
  ]);

  return useMemo(
    () => ({
      channelLayoutZone: mainZoneTransition,
      fullscreenZone: fullscreenZoneTransition,
    }),
    [mainZoneTransition, fullscreenZoneTransition]
  );
}

function produceOverridenTransitionObject(
  overridableTransitionInput: GqlZoneItemTransition | undefined
): GqlZoneItemTransition | undefined {
  const shouldOverrideTransition =
    overridableTransitionInput &&
    overridableTransitionInput.type !== TransitionType.None &&
    overridableTransitionInput.duration > 0;

  return overridableTransitionInput && shouldOverrideTransition
    ? {
        ...overridableTransitionInput,

        // Allow only one type of transitions for transitioning between fullscreen and channel layout
        type: TransitionType.Dissolve,
      }
    : undefined;
}
