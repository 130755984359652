import {
  BreakpointWithItems,
  ListPlaybackControl,
} from "../../store/playback/types";
import {
  GenerateTimelineItemsInputs,
  TimelineItemsWithPlaytimeTracking,
} from "./getBreakpointTimeline";
import { Logger } from "../../logger/logger";
import { ContentItemLeaf } from "../../store/contentLists/types";
import { shuffleES6 } from "../../utils/devUtils";
import { contentListToTimelineItems, getBreakpointStartItem } from "./utils";
import {
  TimelineContinuousStartItem,
  TimelineItem,
  TimelineItemContent,
} from "../../store/timelines/types";
import { isTimelineItemVoid } from "../../utils/contentItemTypeGuards";

const log = new Logger("TimelineWithPlaybackControlGenerator");

export const generateTimelineItemsWithPlaybackControls = (
  inputs: GenerateTimelineItemsInputs
): TimelineItemsWithPlaytimeTracking => {
  const bpWithItems = inputs.bpWithItems;
  const lastItemFromPreviousBp = inputs.lastItemFromPreviousBp;
  const bpFullDuration = inputs.bpFullDuration;
  const plControls = inputs.playbackListControls;
  let playTimeTrackingMs = inputs.playTimeTrackingMs;
  let totalItems = bpWithItems.items.length;
  let sumDurationMs = 0;

  if (!plControls) {
    log.error(
      "Function: generateTimelineItemsWithPlaybackControls - Expected inputs for playbackListControls not found."
    );
    return {
      items: [],
      playTimeTrackingMs: playTimeTrackingMs,
    };
  }
  // clean up items in existing listControls
  const plControlKeys = Object.keys(plControls);
  const totalPlKeys = plControlKeys.length;
  for (let i = 0; i < totalPlKeys; i++) {
    const plKey = plControlKeys[i];
    const plControl = plControls[plKey];
    if (plControl) {
      plControl.items = [];
    }
  }

  let foundParentAtItemsIdx = -1;
  let lastFoundLControls: ListPlaybackControl | null = null;

  // 1. Prepare control list items
  // even it is the same playlist but at each point of time
  // the valid items inside may be different from availability / expire
  // therefore we have to copy those items to each listControl to be upto date

  // updateListControlItems is helper function to update items with listControl
  const updateListControlItems = () => {
    if (!lastFoundLControls)
      throw new Error("Expect lastFoundLControls not exist!!");

    if (lastFoundLControls.playback.mode === "random") {
      let contentItems: ContentItemLeaf[] = lastFoundLControls.items;
      contentItems = shuffleES6(contentItems) as ContentItemLeaf[];
      lastFoundLControls.items = contentItems;
    }

    updateBreakpointItemsToListControl(
      lastFoundLControls,
      bpWithItems,
      foundParentAtItemsIdx
    );

    // generate timelineItems items frome the new the contentItems
    plControls[
      lastFoundLControls.listId
    ].timelineItems = contentListToTimelineItems(
      plControls[lastFoundLControls.listId].items,
      bpWithItems.id,
      false // disable the autoMergeDuplication, enough for this specific case for pick content to play
    );

    foundParentAtItemsIdx = -1;
    lastFoundLControls = null;
  };

  // Find item with playlist parent and add them to plControl.item
  for (let idx = 0; idx < totalItems; idx++) {
    const item = bpWithItems.items[idx];
    const itemParentId = item.parent?.listId;

    if (itemParentId && plControls[itemParentId]) {
      // item with parent
      const currentLpControls = plControls[itemParentId];
      // check does listControls still now different from the last found
      if (
        lastFoundLControls !== null &&
        lastFoundLControls.listId !== itemParentId
      ) {
        updateListControlItems();
      }

      lastFoundLControls = currentLpControls;
      // fill in the items that belong to the list
      if (foundParentAtItemsIdx === -1) foundParentAtItemsIdx = idx;
      currentLpControls.items = currentLpControls.items || [];
      currentLpControls.items.push({ ...item });
    } else {
      // current item has no parent or not found parent controls
      if (lastFoundLControls) {
        updateListControlItems();
      }
    }

    // final check for the last item
    if (lastFoundLControls && idx === totalItems - 1) {
      updateListControlItems();
    }
  }

  removeItemsWithZeroPickToPlay(plControls, bpWithItems);
  const breakpointTimelineItems: TimelineItem[] = contentListToTimelineItems(
    bpWithItems.items,
    bpWithItems.id
  );

  // 2. Find continuous item playback
  // default to start at first item with 0 offset

  let startBpItem: TimelineContinuousStartItem = {
    startItemIdx: 0,
    startTimeOffsetMs: 0,
  };

  // [continuous playback], find the last play item from previous break point to continue
  // apply continuous playback only non void content
  if (lastItemFromPreviousBp && !isTimelineItemVoid(lastItemFromPreviousBp)) {
    startBpItem = getBreakpointStartItem(bpWithItems, lastItemFromPreviousBp);
  }
  totalItems = bpWithItems.items.length;

  const resultTimelineItems: TimelineItem[] = [];
  const timelineLoopItems = breakpointTimelineItems;
  let itemIdxCount = startBpItem.startItemIdx;

  // internal helper function for add the valid timelineItem to the list
  const addTimelineItem = (timelineItem: TimelineItemContent) => {
    timelineItem.startTimestampMs = playTimeTrackingMs;
    // calculate the actual show duration againt the limit by bpFullDuration
    const sumDurationsWithPlayFullDurationMs =
      sumDurationMs + timelineItem.fullDurationMs;
    if (sumDurationsWithPlayFullDurationMs <= bpFullDuration) {
      timelineItem.showDurationMs = timelineItem.fullDurationMs;
    } else {
      timelineItem.showDurationMs =
        timelineItem.fullDurationMs -
        (sumDurationsWithPlayFullDurationMs - bpFullDuration);
    }
    playTimeTrackingMs += timelineItem.showDurationMs;
    resultTimelineItems.push(timelineItem);
    sumDurationMs += timelineItem.showDurationMs;
  };

  // 3. start looping create the list till fill in the breakpoint's full duration
  while (sumDurationMs < bpFullDuration) {
    const idx = itemIdxCount % totalItems;
    const contentItem = bpWithItems.items[idx];
    const listControlByItem = contentItem.parent?.listId
      ? plControls[contentItem.parent?.listId]
      : undefined;
    const continuousItem =
      resultTimelineItems.length === 0
        ? bpWithItems.items[startBpItem.startItemIdx]
        : undefined;
    const pickedItemsByListControl = pickTimelineItemsByListControl(
      listControlByItem,
      continuousItem
    );
    // found picked items from list control playback
    if (pickedItemsByListControl && pickedItemsByListControl.length > 0) {
      const pickedItems = pickedItemsByListControl.length;
      for (let pickIdx = 0; pickIdx < pickedItems; pickIdx++) {
        const timelineItem: TimelineItemContent = Object.assign(
          {},
          pickedItemsByListControl[pickIdx]
        ) as TimelineItemContent;
        addTimelineItem(timelineItem);
        if (sumDurationMs >= bpFullDuration) {
          break;
        }
      }

      itemIdxCount++;

      // move itemIdxCount to skip through the nested items in the playlist
      let nextItemIdx = itemIdxCount % totalItems;
      let nextContentItem: ContentItemLeaf | undefined =
        bpWithItems.items[nextItemIdx];
      let isInSameList = true;
      do {
        if (
          !nextContentItem ||
          listControlByItem?.listId !== nextContentItem.parent?.listId
        ) {
          isInSameList = false;
        } else {
          itemIdxCount++;
          // the check of the previous nextItemIdx is the last one in the list, if so the exit
          if (nextItemIdx === totalItems - 1) {
            break;
          }
          nextItemIdx = itemIdxCount % totalItems;
          nextContentItem = bpWithItems.items[nextItemIdx];
        }
      } while (isInSameList);
    } else {
      // normal timelineItem without any control
      const timelineItem: TimelineItemContent = Object.assign(
        {},
        timelineLoopItems[idx]
      ) as TimelineItemContent;
      addTimelineItem(timelineItem);
      itemIdxCount += 1;
    }
  }

  if (sumDurationMs > bpFullDuration) {
    throw new Error(
      "Duraton of sum timeline items can not go over breakpoint's full duration"
    );
  }

  return {
    items: resultTimelineItems,
    playTimeTrackingMs: playTimeTrackingMs,
    plControls,
  };
};

/* **********************************************************
 * Pick function will pick item(s) from the start index
 * by work out from given continue item to play
 * or default to last state in lpControl.currentPickIdx
 * to the number of specific in pickToPlay and give the result
 ********************************************************** */
export const pickTimelineItemsByListControl = (
  lpControl: ListPlaybackControl | undefined,
  continueContentItem: ContentItemLeaf | undefined
): TimelineItem[] | undefined => {
  const isValidInputs =
    lpControl &&
    (!continueContentItem ||
      (continueContentItem && continueContentItem.parent?.listId));
  let timelineItemsResult: TimelineItem[] = [];

  if (!isValidInputs) return undefined;

  if (isValidInputs && lpControl && lpControl.timelineItems) {
    let startIdx = lpControl.currentPickIdx;
    const totalItems = lpControl.items.length;

    if (continueContentItem) {
      for (let i = 0; i < totalItems; i++) {
        const item = lpControl.items[i];
        if (item.id === continueContentItem.id) {
          startIdx = i;
          break;
        }
      }
    }

    // the play all item, valid for the random list mode
    startIdx = startIdx === -1 ? 0 : startIdx;
    const pickToPlay =
      lpControl.playback.pickToPlay === undefined ||
      lpControl.playback.pickToPlay < 0
        ? lpControl.items.length
        : lpControl.playback.pickToPlay;
    let pickEndIdx =
      startIdx + pickToPlay > totalItems ? totalItems : startIdx + pickToPlay;
    // end index for slice is not include in the result, check slice() document for more info

    timelineItemsResult = lpControl.timelineItems.slice(startIdx, pickEndIdx);

    lpControl.currentPickIdx = pickEndIdx === totalItems ? 0 : pickEndIdx;

    if (timelineItemsResult.length < pickToPlay) {
      startIdx = lpControl.currentPickIdx;
      pickEndIdx = startIdx + (pickToPlay - timelineItemsResult.length);
      const additionTimlineItemsResult = lpControl.timelineItems.slice(
        startIdx,
        pickEndIdx
      );
      timelineItemsResult = [
        ...timelineItemsResult,
        ...additionTimlineItemsResult,
      ];
    }
    return timelineItemsResult;
  }

  return undefined;
};

/* ***********************************************
 * ListPlayback control contain up to date of content Item
 * therefore update the one in breakpoint with items for
 * later use in the generator
 *************************************************/
export const updateBreakpointItemsToListControl = (
  lpControl: ListPlaybackControl,
  bpWithItems: BreakpointWithItems,
  startIdx: number
) => {
  const contentItems = lpControl.items;
  const firstItemToReplace = bpWithItems.items[startIdx];
  const lastItemToReplace =
    bpWithItems.items[startIdx + contentItems.length - 1];
  const itemAfterLastItemToReplace =
    bpWithItems.items[startIdx + contentItems.length];

  // sanitise check before replace
  if (
    lpControl.listId === firstItemToReplace.parent?.listId &&
    lpControl.listId === lastItemToReplace.parent?.listId &&
    (!itemAfterLastItemToReplace ||
      lpControl.listId !== itemAfterLastItemToReplace.parent?.listId)
  ) {
    bpWithItems.items.splice(startIdx, contentItems.length, ...contentItems);
  } else {
    log.error(
      "[updateBreakpointItemsToListControl]: Target to replace breakpoint items mismatch."
    );
  }
};

// items that got 0 pickToPlay are removed
export const removeItemsWithZeroPickToPlay = (
  plControls: {
    [list_id: string]: ListPlaybackControl;
  },
  bpWithItems: BreakpointWithItems
) => {
  for (const plListId in plControls) {
    const plControl = plControls[plListId];
    if (plControl.playback.pickToPlay === 0) {
      // remove items that belong to this playlist
      bpWithItems.items = bpWithItems.items.filter(
        (item) => item.parent?.listId !== plListId
      );
    }
  }
};
