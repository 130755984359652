import { MenuItemType } from "../../modules/menu/InteractiveMenu/types";
import { Theme } from "../../../src/store/themes/types";
import { ContentItemLeaf } from "../../store/contentLists/types";
import {
  ContentViewFileItem,
  ContentViewAppItem,
  ContentViewSiteItem,
  ContentViewLinkItem,
  ContentViewVoidItem,
  ContentViewPlaylistItem,
} from "./../../types/content";

export type BaseMenuContentItem = {
  id: string;
  name: string;
  thumbnail?: string;
  listId?: string;
  playlistId?: string;
};

export interface AppContentItem
  extends BaseMenuContentItem,
    ContentViewAppItem {
  type: "app";
  appType?: string;
  slug: string;
}
export interface FileContentItem
  extends BaseMenuContentItem,
    ContentViewFileItem {
  type: "file";
  fileType: "image" | "video" | "document";
}
export interface SiteContentItem
  extends BaseMenuContentItem,
    ContentViewSiteItem {
  type: "site";
}
export interface LinkContentItem
  extends BaseMenuContentItem,
    ContentViewLinkItem {
  type: "link";
}

export interface PlaylistContentItem
  extends BaseMenuContentItem,
    ContentViewPlaylistItem {
  type: "playlist";
}
export type MenuContentItem =
  | AppContentItem
  | FileContentItem
  | SiteContentItem
  | LinkContentItem
  | ContentViewVoidItem
  | PlaylistContentItem;

export interface MenuContext {
  // Channel content
  filteredItems: MenuContentItem[];
  items: MenuContentItem[];
  searchedItems: MenuContentItem[];
  currentIndex: number;
  channelName?: string;
  theme?: Theme;

  // Selected content
  playlistContent?: PlaylistContent[];
  selectedPlaylist?: PlaylistContent;
  playlistId?: string;
  setPlaylist: (id: string) => void;
  type?: MenuItemType;
  setType: (type: MenuItemType) => void;

  // Display
  show: () => void;
  liveMode: boolean;
  toggleFullscreen: () => void;
  returnToLiveMode: () => void;
  onClose: () => void;
  isFullscreen: boolean;
  view: MenuViews;
  setView: (view: MenuViews) => void;

  // Play controls
  navigateToItem: (itemIndex: number) => void;
  navigateToNextItem: () => void;
  navigateToPreviousItem: () => void;
}

export interface PlaylistContent {
  id: string;
  items: ContentItemLeaf[];
  publishedAt: string;
  nestedPlaylistIds?: string[];
  name: string;
}

export enum MenuViews {
  MAIN = "main",
  GRID = "grid",
}
