import React, { FunctionComponent, useEffect, useState } from "react";
import View from "./views";
import styles from "./InteractiveMenu.module.css";
import { MenuItemType } from "./types";
import { MenuContentItem } from "../../../providers/MenuProvider/types";
import Navbar from "./components/NavBar";
import GripIcon from "../../../img/grip-icon.svg";
import { DEFAULT_SLIDER_MENU_PADDING, GRIP_BAR_HEIGHT } from "../constants";
import RightArrow from "../../../img/right-arrow.svg";
import LeftArrow from "../../../img/left-arrow.svg";

interface Props {
  // search filter props
  search: string;
  setSearch: (search: string) => void;
  navigateToNextItem: () => void;
  navigateToPreviousItem: () => void;
}

export const count = (
  itemType: MenuItemType,
  searchedItems: MenuContentItem[]
): number => {
  // Adding video apps e.g youtube, to total video count
  if (itemType === MenuItemType.Video) {
    const { appVideos, videos } = searchedItems.reduce<{
      appVideos: MenuContentItem[];
      videos: MenuContentItem[];
    }>(
      (accum, item) => {
        if (
          item.type === MenuItemType.Apps &&
          item.appType === MenuItemType.Video
        ) {
          accum.appVideos.push(item);
        } else if (
          item.type === MenuItemType.File &&
          item.fileType === MenuItemType.Video
        ) {
          accum.videos.push(item);
        }
        return accum;
      },
      {
        appVideos: [],
        videos: [],
      }
    );
    return appVideos.length + videos.length;
  } else if (
    itemType === MenuItemType.Apps ||
    itemType === MenuItemType.Dashboards ||
    itemType === MenuItemType.Links
  ) {
    return searchedItems.filter((item) => item.type === itemType).length;
  } else {
    return searchedItems.filter(
      (item) => item.type === MenuItemType.File && item.fileType === itemType
    ).length;
  }
};

const handleGripClick = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  const isMenuShowing =
    document.documentElement.scrollTop >=
    window.innerHeight - DEFAULT_SLIDER_MENU_PADDING;
  if (isMenuShowing) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    event.preventDefault();
  } else {
    window.scrollTo({
      top: window.innerHeight - GRIP_BAR_HEIGHT,
      behavior: "smooth",
    });
    event.preventDefault();
  }
};

const InteractiveMenu: FunctionComponent<Props> = ({
  search,
  setSearch,
  navigateToNextItem,
  navigateToPreviousItem,
}: Props) => {
  const [isMenuShowing, setIsMenuShowing] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (
        document.documentElement.scrollTop >=
        window.innerHeight - DEFAULT_SLIDER_MENU_PADDING
      ) {
        setIsMenuShowing(true);
        event.preventDefault();
      } else {
        setIsMenuShowing(false);
        event.preventDefault();
      }
    });
  }, []);

  return (
    <>
      <div className={styles.empty}></div>
      <div
        className={`${styles.container} ${
          !isMenuShowing ? styles.menuHidden : ""
        }`}
        data-testid="main-menu"
      >
        <div className={styles.stickySection}>
          <div className={styles.grip} onClick={handleGripClick}>
            <img src={GripIcon} className={styles.gripIcon} alt="Grip Icon" />
          </div>
          <div hidden={isMenuShowing}>
            <button className={styles.nextscreen} onClick={navigateToNextItem}>
              <img src={RightArrow} className={styles.iconNext} alt="Next" />
            </button>
          </div>
          <div hidden={isMenuShowing}>
            <button
              className={styles.previousscreen}
              onClick={navigateToPreviousItem}
              hidden={isMenuShowing}
            >
              <img src={LeftArrow} className={styles.iconPrevious} alt="Back" />
            </button>
          </div>
          <Navbar search={search} setSearch={setSearch} />
        </div>
        <div className={styles.menuScrollWrapper}>
          <div className={`${styles.viewContainer} `}>
            <View search={search} />
          </div>
        </div>
      </div>
    </>
  );
};

export default InteractiveMenu;
