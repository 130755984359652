import { NormalizationUtilities } from "../storeTypes";
import { layoutNormalization } from "../layouts/normalization";
import { themeNormalization } from "../themes/normalization";
import { PlayerState } from "../rootReducer";
import {
  getEmptyTraversalObject,
  mergeTraversalResult,
  traverseContentList,
} from "../normalizationUtils";
import { getHiddenZoneTimelineId } from "../../utils/channelContent";
import { parseLocalTimelineId } from "../contentLists/utils";
import { NormalizedChannelFragment } from "./types";
import { NormalizedAppInstanceFragment } from "../apps/types";
import { FileFragment, LayoutFragment, ThemeFragment } from "../../queries";
import { NormalizedLinkFragment } from "../links/types";
import { NormalizedPlaylistFragment } from "../playlists/types";
import { NormalizedSiteFragment } from "../sites/types";
import { NormalizedOrgFragment } from "../organization/types";
import { channelSchema } from "../normalizationSchemas";

export const channelNormalization: NormalizationUtilities = {
  schema: channelSchema,
  traverseFunction: (channelId: string, state: PlayerState) => {
    let result = getEmptyTraversalObject();

    result.channels.add(channelId);

    const targetChannel = state.channels.byId[channelId];
    if (!targetChannel) {
      // can't traverse further
      return result;
    }

    const targetLayout = state.layouts.byId[targetChannel.layoutId];
    if (!targetLayout) {
      return result;
    }

    result = mergeTraversalResult(
      result,
      ...targetLayout.zones.map((zone) =>
        traverseContentList(zone.contentListId, state)
      )
    );

    const hiddenZoneTimelineId = getHiddenZoneTimelineId(
      targetChannel,
      targetLayout
    );
    if (hiddenZoneTimelineId) {
      result = mergeTraversalResult(
        result,
        traverseContentList(
          parseLocalTimelineId(hiddenZoneTimelineId).sourceContentListId,
          state
        )
      );
    }

    result = mergeTraversalResult(
      result,
      layoutNormalization.traverseFunction(targetChannel.layoutId, state)
    );

    if (targetChannel.themeId) {
      result = mergeTraversalResult(
        result,
        themeNormalization.traverseFunction(targetChannel.themeId, state)
      );
    }

    return result;
  },
};

export type NormalizedChannelEntities = {
  channels: { [key: string]: NormalizedChannelFragment } | null;
  apps: { [key: string]: NormalizedAppInstanceFragment } | null;
  files: { [key: string]: FileFragment } | null;
  layouts: { [key: string]: LayoutFragment } | null;
  links: { [key: string]: NormalizedLinkFragment } | null;
  playlists: { [key: string]: NormalizedPlaylistFragment } | null;
  themes: { [key: string]: ThemeFragment } | null;
  sites: { [key: string]: NormalizedSiteFragment } | null;
  orgs: { [key: string]: NormalizedOrgFragment } | null;
};
