import { Layout, Zone } from "../store/layouts/types";
import {
  makeContentListIdForZone,
  makeTimelineIdForZone,
} from "../store/contentLists/utils";
import { Channel, ChannelContentLayout } from "../store/channels/types";
import { HIDDEN_ZONE_ID } from "../constants";

// todo: this is to be removed in next versions. Details in gh issue:
//  https://github.com/screencloud/studio-player/issues/613
export function deprecateGetSplashChannelZones(
  channelContentLayout: ChannelContentLayout | undefined
): Zone[] {
  if (!channelContentLayout) {
    return [];
  }
  const result: Zone[] = [];
  const zoneNumber = channelContentLayout.zones || 2;
  let i: number;
  for (i = 1; i <= zoneNumber; i++) {
    result.push({
      id: `zone${i}`,
      contentListId: makeContentListIdForZone(null, `zone${i}`),
      name: `zone${i}`,
    });
  }
  return result;
}

export function getVisibleChannelZones(
  channel: Channel,
  layout: Layout | undefined
): Zone[] {
  return deprecateIsSplashChannel(layout)
    ? deprecateGetSplashChannelZones(channel.deprecateContentLayout)
    : layout?.zones || [];
}

/**
 * Gets hidden zone id or undefined if hidden zone is disabled
 */
export function getHiddenZoneTimelineId(
  channel: Channel,
  layout: Layout | undefined
): string | undefined {
  return layout && channel.isHiddenZoneActive
    ? makeTimelineIdForZone(layout.id, HIDDEN_ZONE_ID)
    : undefined;
}

export function deprecateIsSplashChannel(layout: Layout | undefined): boolean {
  return !layout;
}

/**
 * Returns a list of timeline ids for each active zone of a channel
 */
export function getAllActiveChannelZoneTimelineIds(
  channel: Channel,
  layout: Layout
): string[] {
  const visibleZones = getVisibleChannelZones(channel, layout);
  const hiddenZoneId = getHiddenZoneTimelineId(channel, layout);

  // todo: use a getter for timeline id for better maintenance in future≈
  const result = visibleZones.map((zone) => zone.contentListId);

  if (hiddenZoneId !== undefined) {
    result.push(hiddenZoneId);
  }

  return result;
}
