import { FullscreenContent } from "./useFullscreenContent";
import { FullscreenZoneTransitioningItemInfo } from "../types";
import { useEffect, useMemo, useState } from "react";

/**
 * Returns the data of a content item to be used for transition from fullscreen zone to channel layout zone.
 */
export function useFullscreenZoneTransitioningItemInfo(
  fullscreenContent: FullscreenContent
): FullscreenZoneTransitioningItemInfo {
  const { active, preload } = fullscreenContent;
  const [targetTimelineId, setTargetTimelineId] = useState<string | undefined>(
    undefined
  );
  const [targetIndexInTimeline, setTargetIndexInTimeline] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (active && !preload) {
      setTargetTimelineId(active.timelineId);
      setTargetIndexInTimeline(active.indexInTimeline);
    } else if (!active && preload) {
      setTargetTimelineId(preload.timelineId);
      setTargetIndexInTimeline(preload.indexInTimeline);
    } else {
      setTargetTimelineId(undefined);
      setTargetIndexInTimeline(undefined);
    }
  }, [active, preload]);

  return useMemo(
    () => ({
      targetTimelineId,
      targetIndexInTimeline,
    }),
    [targetTimelineId, targetIndexInTimeline]
  );
}
