/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'Logger';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```

 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */

/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */

import { Maybe } from "../queries";
import { actualUtcNow, TimeOptions } from "../utils/timeManager";
import datadog from "./datadog";
import proofOfPlay from "./proofOfPlay";
import remoteEndPointLogger from "./remoteEndPointLogger";
import { PMILogger } from "./pmiLogger";
import { Context } from "@datadog/browser-core";
import omit from "lodash/omit";
import isNil from "lodash/isNil";
import { FEATURE_FLAGS_ENUM } from "../featureFlags";

const pmiLogger = new PMILogger();

export interface RemoteLoggerConfig {
  clientToken: string;
  version: string;
  env: string;
  proxyHost?: string;
  platform: string;
  deviceId: string;
  screenId: string;
  screenName: string;
  orgId: string;
  timeOptions: TimeOptions;
  graphqlToken: string;
  proofOfPlayEndpoint: string;
  spaceName?: string;
  remoteLogEndPoint?: string;
}

export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}

export type LogMessage = string;

export type ProofOfPlayFlag = boolean; // flag that indicates that a log line must be sent to Loki transport

export type LogObjects = {
  message: LogMessage;
  context?: Context;
  proofOfPlayFlag?: ProofOfPlayFlag;
};

/**
 * Log output handler function.
 */
export type LogOutput = (
  level: LogLevel,
  message: string,
  context: Context,
  sendToProofOfPlay?: boolean
) => void;

export class Logger {
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static currentLevel: LogLevel = LogLevel.Error;

  /**
   * Additional log outputs.
   */
  static outputs: LogOutput[] = []; // data dog , loki , custom functions to send logs

  /**
   * Logger initialised status
   */
  static isInitialized = false;

  /**
   * Default context that is added to every log context
   */
  static defaultContext: Context = {};

  static timeOptions: TimeOptions = {
    timeOffset: 0,
    timelineControlOffset: 0,
  };

  static enableConsoleLogs = false;

  // for directly log to specific logger
  static pfpLogger: LogOutput;
  static ddLogger: LogOutput;
  static pmLogger: LogOutput;

  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  static enableProductionMode(): void {
    // TODO set log level by environment variables or flags in future
    Logger.currentLevel = LogLevel.Warning;
  }

  /**  initialise the parent logger */
  static init = (
    config: RemoteLoggerConfig,
    featureFlags: Maybe<string>[]
  ): void => {
    if (!Logger.isInitialized) {
      // set default log level from env
      if (process.env.REACT_APP_LOG_LEVEL) {
        Logger.currentLevel = Number(process.env.REACT_APP_LOG_LEVEL);
      }

      // set timeoptions
      Logger.timeOptions = config.timeOptions;

      // init datadog
      datadog.init(config, featureFlags);

      // init proof of play log
      if (!!config.proofOfPlayEndpoint) {
        proofOfPlay.init(config, featureFlags);
      }

      // init remote endpoint logger
      remoteEndPointLogger.init(config);

      Logger.defaultContext = {
        ...Logger.defaultContext,
        screenId: config.screenId,
        screenName: config.screenName,
        spaceName: config.spaceName,
        platform: config.platform,
        orgId: config.orgId,
        studioPlayerVersion: config.version,
        deviceId: config.deviceId,
        env: config.env,
        deviceTimeStamp: actualUtcNow(Logger.timeOptions), // log actual utc timestamp
      };

      // TODO: update this TEMP FIX UNTILL ORG & SCREEN LOG lEVEL IS THERE
      if (
        Logger.isDataDogEnabled(featureFlags) ||
        Logger.isLokiEnabled(featureFlags)
      ) {
        Logger.currentLevel = LogLevel.Info;
      }
      Logger.isInitialized = true;
    }
  };

  /**
   * getters for feature flags
   *
   */
  static isDataDogEnabled = (featureFlags: Maybe<string>[]): boolean => {
    return featureFlags.includes(FEATURE_FLAGS_ENUM.PLAYER_LOG_ENABLE);
  };

  static isLokiEnabled = (featureFlags: Maybe<string>[]): boolean => {
    return featureFlags.includes(FEATURE_FLAGS_ENUM.PLAYBACK_LOGS);
  };

  static setTimeOptions = (timeoptions: TimeOptions): void => {
    Logger.timeOptions = timeoptions;
  };

  constructor(private origin: string) {
    // TODO: refactoring below again to server the purpose of
    // Log should be able to queue when it still not connected to the remote service
    // And later when flush the queue it will look up to list of available msg in the Queue
    // the shape of log class and logic above should be shared and apply by polymorphism concept
    // but for now to save amout of work and continue on other bit, I leave it as what it was before
    // but added access to allow to call to log on each service directly from outside.
    Logger.ddLogger = datadog.getLogger();
    Logger.pfpLogger = proofOfPlay.getLogger();
    Logger.pmLogger = pmiLogger.getLogger();
    // add output sources adding sources to constructor to make sure events before initialisation are captured
    if (Logger.outputs.length === 0) {
      Logger.outputs.push(Logger.ddLogger);
      Logger.outputs.push(Logger.pfpLogger);
      Logger.outputs.push(Logger.pmLogger);
    }
  }

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug(logObj: LogMessage | LogObjects): void {
    this.log(LogLevel.Debug, logObj);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info(logObj: LogMessage | LogObjects): void {
    this.log(LogLevel.Info, logObj);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn(logObj: LogMessage | LogObjects): void {
    this.log(LogLevel.Warning, logObj);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error(logObj: LogMessage | LogObjects): void {
    this.log(LogLevel.Error, logObj);
  }

  log(level: LogLevel, data: LogMessage | LogObjects): void {
    const proofOfPlayFlag =
      typeof data == "string" ? undefined : data.proofOfPlayFlag;
    let logContext: Context = this.createLogContext(data);
    let message: string = typeof data == "string" ? data : data.message;
    // console.log("logContext ==== ", logContext);
    /** filters log output on
     * 1 - when in preload change info messages to debug
     * 2 - when in preview donot output logs except error
     */
    if (logContext?.isPreview && level > 1) {
      return;
    }
    if (logContext?.isPreload && level > 1) {
      // downgrade logs to debug when in preload state
      if (level === LogLevel.Info) {
        level = LogLevel.Debug;
      }
      message = "[preload]" + message;
    }

    // show output on console
    this.logToConsole(level, message, logContext);

    // sanitise logcontext before sending to ouputs
    const filterKeys = ["isPreview", "status", "isPreload"];
    logContext = omit(logContext, filterKeys);

    // Messages being sent to regitsered outputs
    if (level <= Logger.currentLevel) {
      Logger.outputs.forEach((output) => {
        return output.apply(output, [
          level,
          message,
          logContext,
          proofOfPlayFlag,
        ]);
      });
    }
  }

  // to allow direct log to ProofOfPlay
  proofOfPlayLog(level: LogLevel, data: LogMessage | LogObjects): void {
    const proofOfPlayFlag = true;
    let logContext: Context = this.createLogContext(data);
    const message: string = typeof data == "string" ? data : data.message;

    // show output on console
    this.logToConsole(level, message, logContext);

    // sanitise logcontext before sending to ouputs
    const filterKeys = ["isPreview", "status", "isPreload"];
    logContext = omit(logContext, filterKeys);

    // Messages being sent to regitsered outputs
    if (level <= Logger.currentLevel) {
      Logger.pfpLogger.apply(Logger.pfpLogger, [
        level,
        message,
        logContext,
        proofOfPlayFlag,
      ]);
    }
  }

  private logToConsole(level: LogLevel, message: string, logContext: Context) {
    // show output on console
    const isLogToConsole =
      process.env.REACT_APP_SC_ENV === "development" ||
      process.env.REACT_APP_ENABLE_CONSOLE_LOGS ||
      localStorage.getItem("enable_console_logs") ||
      level === LogLevel.Error; // send log to console
    if (isLogToConsole) {
      let func: (...args: unknown[]) => void = console.log; // default
      switch (level) {
        case LogLevel.Debug:
          func = console.log;
          break;
        case LogLevel.Info:
          func = console.info;
          break;
        case LogLevel.Warning:
          func = console.warn;
          break;
        case LogLevel.Error:
          func = console.error;
          break;
      }

      func.apply(console, [
        "[" + this.origin + "]",
        "[" + LogLevel[level] + "]",
        message,
        logContext,
      ]);
    }
  }

  private createLogContext(data: LogMessage | LogObjects): Context {
    // redundant type check to satify TYPE verification
    const defaultContext = Logger.defaultContext;
    const additionalContext =
      typeof data == "string" ? undefined : data.context;
    const logContext: Context = {
      ...defaultContext,
      ...additionalContext,
      ...{ component: this.origin }, // component of origin
    };
    for (const propName in logContext) {
      if (isNil(logContext[propName]) || logContext[propName] === "N/A") {
        delete logContext[propName];
      }
    }
    return logContext;
  }
}
