import React, { FunctionComponent } from "react";
import { useEmbedMenu } from "../../../../../providers/MenuProvider/MenuProvider";
import { BaseMenuContentItem } from "../../../../../providers/MenuProvider/types";
import { filterByPlaylistItems } from "../../../../../providers/MenuProvider/utils";
import { ItemTileList } from "../../components/ItemTile/ItemTileList";
import styles from "./GridView.module.css";

interface Props {
  search?: string;
}

const GridView: FunctionComponent<Props> = ({ search }: Props) => {
  const {
    items,
    filteredItems,
    navigateToItem,
    currentIndex,
    playlistId,
  } = useEmbedMenu();
  const itemsToDisplay = filteredItems.length
    ? filteredItems
    : search
    ? []
    : filterByPlaylistItems(items, playlistId || "");

  return (
    <div className={styles.grid} data-testid="filtered-items-list">
      <ItemTileList
        items={itemsToDisplay}
        onClick={(item: BaseMenuContentItem) => {
          navigateToItem(
            items.findIndex((element) => {
              if (element.type !== "void") {
                return element.listId === item.listId;
              }
              return false;
            })
          );
        }}
        currentIndex={currentIndex}
        styles={styles}
      />
    </div>
  );
};

export default GridView;
