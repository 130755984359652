import fs from "fs";

export const isLocalDev = (): boolean => {
  return process.env.REACT_APP_SC_ENV === "development";
};

export const isProduction = (): boolean => {
  return process.env.REACT_APP_SC_ENV === "production";
};

export const isStorePersistDisabledFlag = (): boolean => {
  const urlParams = new window.URLSearchParams(window.location.search);
  return urlParams.has("disable_store_persist");
};

export const isUrlAppsDomain = (url: string): boolean => {
  const urlToCheck = new URL(url);
  const domain = urlToCheck.hostname.toString();
  return (
    domain.endsWith(".apps.next.sc") ||
    domain.endsWith(".apps.screencloud.com") ||
    domain.endsWith(".screencloudapps.com")
  );
};

export const writeToFile = (data: unknown, fname?: string) => {
  const jsonContent = JSON.stringify(data);
  fs.writeFile(fname ? fname : "output.json", jsonContent, "utf8", function (
    err: unknown
  ) {
    if (err) {
      console.log("An error occured while writing JSON Object to File.");
      return console.log(err);
    }
  });
};

export const countMatchStringInJson = (
  jsonData: any,
  stringValue: string
): number => {
  let count = 0;

  for (const key in jsonData) {
    const value = jsonData[key];
    if (typeof value === "string" && value.indexOf(stringValue) !== -1) {
      count++;
    } else if (typeof value === "object") {
      count += countMatchStringInJson(value, stringValue);
    } else if (Array.isArray(value)) {
      for (const item of value) {
        if (typeof item === "string" && value.indexOf(stringValue) !== -1) {
          count++;
        }
      }
    }
  }

  return count;
};
// Fisher-Yates Shuffle Algorithm
// ref: https://www.webmound.com/shuffle-javascript-array/

// TODO: add deterministic shuffle with seed
export const shuffleES6 = (array: unknown[]) => {
  const newArray = [...array];

  newArray.reverse().forEach((item, index) => {
    const j = Math.floor(Math.random() * (index + 1));
    [newArray[index], newArray[j]] = [newArray[j], newArray[index]];
  });

  return newArray;
};
