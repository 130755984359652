import { ExposedInterface } from "@screencloud/postmessage-interface";

import healthCheck from "./healthCheck";
import { updateDeviceInfo } from "./updateDeviceInfo";

class StudioPlayerExposedInterface {
  private isExposed: boolean;

  constructor() {
    this.isExposed = false;
  }

  expose(): void {
    if (this.isExposed) {
      return; // should expose once
    }

    const interfaces = {
      healthcheck: healthCheck.getLatestHealthCheck.bind(healthCheck),
      updateDeviceInfo: updateDeviceInfo.bind(updateDeviceInfo),
      clearServiceWorkerCached: window.__sc_clearSWCache,
      getServiceWorkerStorage: window.__sc_getSWStorage,
      unregisterServiceWorker: window.__sc_unregisterSW,
    };
    new ExposedInterface(interfaces);

    this.isExposed = true;
  }
}

const studioPlayerExposedInterface = new StudioPlayerExposedInterface();
export default studioPlayerExposedInterface;
