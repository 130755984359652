import { produce } from "immer";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import {
  DefaultDurations,
  GqlContentListItem,
  GqlContentProps,
  GqlZoneItemSizeType,
  GqlZoneItemTransition,
} from "../graphqlTypes";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import {
  ChannelZoneContentItem,
  ContentItemLeaf,
  ContentItemPlaylist,
  ContentListsState,
} from "./types";
import {
  doesContentListBelongToChannel,
  flattenNestedContentItems,
  getContentItemInfo,
  getDefaultDurationsForItem,
  getItemAvailability,
  getItemSizeType,
  getPreloadDurationFromPreviousItemDuration,
  getPreviousItemFromGqlContentList,
  makeContentListIdForZone,
} from "./utils";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import {
  NormalizedPlaylistFragment,
  REQUEST_PLAYLIST_SUCCESS,
} from "../playlists/types";
import { notUndefinedOrNull } from "../../utils/helpers";
import {
  NormalizedAppInstanceFragment,
  REMOVE_APP_INSTANCE,
  RemoveAppInstanceAction,
} from "../apps/types";
import { FileFragment } from "../../queries";
import { NormalizedLinkFragment } from "../links/types";

/**
 * Data about the available app instances.
 */
const initialState: ContentListsState = {
  byId: {},
};

interface AddContentListEntityInput {
  listId: string;
  listItems: ContentItemLeaf[];
  publishedAt: string;
  nestedPlaylistIds?: string[];
  nestedPlaylistPropsByListId?: { [key: string]: GqlContentProps };
}

// the props that set inside each playlist not the one in Channel's item,
// currently not take this into consideration of playback yet, require clean up before apply next
export interface PlaylistsWithContentItemsAndProps {
  [id: string]: {
    items: ContentItemLeaf[];
    props?: GqlContentProps;
  };
}

export interface PlaylistsMapListIdAndProps {
  [listId: string]: {
    id: string;
    props?: GqlContentProps; // the props that set inside playlist itself
    // meta?: // TODO: possible add in here in future
  };
}

export function contentListsReducer(
  state = initialState,
  action: PlayerAction
): ContentListsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_PLAYLIST_SUCCESS:
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS: {
        const addContentListEntity = (
          input: AddContentListEntityInput
        ): void => {
          const listId = input.listId;
          const listItems = input.listItems;
          const publishedAt = input.publishedAt;
          const nestedPlaylistIds = input.nestedPlaylistIds;
          const nestedPlaylistPropsByListId = input.nestedPlaylistPropsByListId;
          const currentList = draft.byId[listId];
          const currentListItems = currentList?.items;
          const isItemsEqual = isEqual(listItems, currentListItems);
          const isPublishedAtEqual = publishedAt === currentList?.publishedAt;

          // New items will trigger elements to re-render, so only update if content has changed.
          if (!isItemsEqual || !isPublishedAtEqual) {
            draft.byId[listId] = {
              ...currentList,
              id: listId,
              items: isItemsEqual ? currentListItems : listItems,
              publishedAt,
              nestedPlaylistIds,
              nestedPlaylistPropsByListIds: nestedPlaylistPropsByListId,
            };
          }
        };

        const fileEntities = action.payload.files;
        const appEntities = action.payload.apps;
        /**
         * Playlists cannot embed other playlists right now.
         */
        const playlists = Object.values(action.payload.playlists);
        const playlistItemsById: PlaylistsWithContentItemsAndProps = {};

        const filterUnavailableItemsInput: FilterUnavailableItemsInput = {
          gqlContentList: [],
          apps: action.payload.apps,
          files: action.payload.files,
          links: action.payload.links,
          playlists: action.payload.playlists,
        };
        const makeContentListInput: MakeContentListItemInput = {
          gqlContentList: [],
          nestedPlaylistWithItemsAndProps: {},
          files: fileEntities,
          apps: appEntities,
          defaultDurations: undefined,
        };

        playlists.forEach((playlist) => {
          const listId = playlist.id;
          filterUnavailableItemsInput.gqlContentList = playlist.content.list;
          const filteredPlaylistList = filterUnavailableItems(
            filterUnavailableItemsInput
          );

          makeContentListInput.gqlContentList = filteredPlaylistList;
          makeContentListInput.defaultDurations =
            playlist.content.props?.default_durations;
          const items = makeContentItemsList(makeContentListInput);
          playlistItemsById[listId] = { items, props: playlist.content.props };

          const addContentListEntityInput: AddContentListEntityInput = {
            listId,
            listItems: items,
            publishedAt: playlist.publishedAt,
          };
          addContentListEntity(addContentListEntityInput);
        });

        /**
         * When used in a Channel (or another playlist), replace the Playlist reference with its contents.
         * i.e. removing list nesting upfront, so runtime is easier.
         *
         * TODO - This could be done server-side, so playlists as a concept are just a Studio UI helper?
         */
        if (action.type !== REQUEST_PLAYLIST_SUCCESS) {
          const channels = Object.values(action.payload.channels);

          channels.forEach((channel) => {
            const zones = channel.content?.zones;
            const activeZoneIds = zones ? Object.keys(zones) : [];
            const defaultDurations = channel.content?.props?.default_durations;
            activeZoneIds.forEach((zoneId) => {
              const zone = zones[zoneId];
              const zoneList = zone?.list;

              if (!zone || !zoneList) {
                return;
              }

              const listId = makeContentListIdForZone(
                channel.layoutByChannel,
                zoneId
              );

              const filterUnavailableItemsInput: FilterUnavailableItemsInput = {
                gqlContentList: zoneList,
                apps: action.payload.apps,
                files: action.payload.files,
                links: action.payload.links,
                playlists: action.payload.playlists,
              };
              const filteredZoneList = filterUnavailableItems(
                filterUnavailableItemsInput
              );

              const makeContentListInput: MakeContentListItemInput = {
                gqlContentList: filteredZoneList,
                nestedPlaylistWithItemsAndProps: playlistItemsById,
                files: action.payload.files,
                apps: action.payload.apps,
                defaultDurations: defaultDurations,
                sizeTypes: zone.props?.sizing_type,
                transition: zone.props?.transition,
              };
              const items = makeContentItemsList(makeContentListInput);

              const nestedPlaylistIds = compact([
                ...new Set(
                  items
                    .filter((item) => item.parent?.type === "playlist")
                    .map((playlist) => playlist.parent?.id)
                ),
              ]);

              const playlistWithPropsByListId = getPlaylistWithPropsByListId(
                filteredZoneList
              );

              const addContentListEntityInput: AddContentListEntityInput = {
                listId,
                listItems: items,
                publishedAt: channel.publishedAt,
                nestedPlaylistIds: nestedPlaylistIds.length
                  ? nestedPlaylistIds
                  : undefined,
                nestedPlaylistPropsByListId: playlistWithPropsByListId,
              };
              addContentListEntity(addContentListEntityInput);
            });

            const activeContentListIds = activeZoneIds.map((zoneId) =>
              makeContentListIdForZone(channel.layoutByChannel, zoneId)
            );
            const unusedChannelContentLists = Object.keys(draft.byId).filter(
              (contentListId) =>
                doesContentListBelongToChannel(channel, contentListId) &&
                !activeContentListIds.includes(contentListId)
            );
            unusedChannelContentLists.forEach((contentListId) => {
              delete draft.byId[contentListId];
            });
          });
        }

        break;
      }
      case REMOVE_APP_INSTANCE: {
        Object.keys(draft.byId).forEach((contentListId) => {
          const targetContentList = draft.byId[contentListId];
          function getTargetIndex(
            targetAction: RemoveAppInstanceAction
          ): number {
            return targetContentList.items.findIndex((item) => {
              return item.type === "app" && item.id === targetAction.payload.id;
            });
          }
          let targetIndex = getTargetIndex(action);

          // extra protection from infinite loops
          const maxIterations = targetContentList.items.length;
          let iterationCounter = 0;

          while (targetIndex > -1 && iterationCounter <= maxIterations) {
            targetContentList.items.splice(targetIndex, 1);
            targetIndex = getTargetIndex(action);

            iterationCounter++;
          }
        });
      }
    }

    return draft;
  });
}

/**
 * Filters out content items form inputList that are not accessible anymore. Returns filtered list.
 *
 * We assume here: the item in the content list json is available, then actual entity is going to be sent in the payload
 * of the same action. If whenever graphql request is not going to include all the referenced entities (eg someone
 * decides do it in several separate smaller queries) - this filter will not work correctly.
 */
interface FilterUnavailableItemsInput {
  gqlContentList: GqlContentListItem[];
  apps: { [key: string]: NormalizedAppInstanceFragment };
  files: { [key: string]: FileFragment };
  links: { [key: string]: NormalizedLinkFragment };
  playlists: { [key: string]: NormalizedPlaylistFragment };
}
const filterUnavailableItems = (
  input: FilterUnavailableItemsInput
): GqlContentListItem[] => {
  const inputList = input.gqlContentList;
  const apps = input.apps;
  const files = input.files;
  const links = input.links;
  const playlists = input.playlists;
  const isItemAvailable = (item: GqlContentListItem): boolean => {
    if (item.content._ref.type === "file") {
      return !!files[item.content._ref.id];
    }

    if (item.content._ref.type === "app") {
      return !!apps[item.content._ref.id];
    }

    if (item.content._ref.type === "link") {
      return !!links[item.content._ref.id];
    }

    if (item.content._ref.type === "playlist") {
      return !!playlists[item.content._ref.id];
    }

    return true;
  };

  return inputList.filter(isItemAvailable);
};

interface MakeContentListItemInput {
  gqlContentList: GqlContentListItem[];
  nestedPlaylistWithItemsAndProps: PlaylistsWithContentItemsAndProps;
  files: { [id: string]: FileFragment };
  apps: { [id: string]: NormalizedAppInstanceFragment };
  defaultDurations: DefaultDurations | undefined; // these must come from the parent channel duration settings
  sizeTypes?: GqlZoneItemSizeType; // these must come from the parent channel size type settings
  transition?: GqlZoneItemTransition;
}

const makeContentItemsList = (
  inputs: MakeContentListItemInput
): ContentItemLeaf[] => {
  const nestedPlaylistContentLists = inputs.nestedPlaylistWithItemsAndProps;
  const files = inputs.files;
  const apps = inputs.apps;
  const defaultDurations = inputs.defaultDurations;
  const sizeTypes = inputs.sizeTypes;
  const transition = inputs.transition;
  const list = inputs.gqlContentList
    .map<ChannelZoneContentItem | undefined>((item, idx) => {
      if (item.content._ref.type === "playlist") {
        const contentItem: ContentItemPlaylist = {
          id: item.content._ref.id,
          type: item.content._ref.type,
          rules: item.rules,
          props: item.content.props,
          ...getItemAvailability(item, files),
          listId: item.list_id,
          defaultDurations: getDefaultDurationsForItem(
            item.content,
            defaultDurations
          ),
          defaultSizeTypes: sizeTypes,
          transition,
        };
        return contentItem;
      } else if (
        item.content._ref.type === "app" ||
        item.content._ref.type === "file" ||
        item.content._ref.type === "link" ||
        item.content._ref.type === "site"
      ) {
        const currentItem = getContentItemInfo(
          item,
          files,
          apps,
          defaultDurations
        );

        const previousItem = getPreviousItemFromGqlContentList(
          inputs.gqlContentList,
          idx
        );
        const previousItemForPreload = getContentItemInfo(
          previousItem,
          files,
          apps,
          defaultDurations
        );

        if (!currentItem) {
          return undefined;
        }

        const contentItem: ContentItemLeaf = {
          id: item.content._ref.id,
          type: item.content._ref.type,
          durationMs: currentItem.itemDurationMs,
          sizeType: getItemSizeType(
            item.content._ref.type,
            sizeTypes,
            currentItem.mimetype
          ),
          transition,
          preloadDurationMs: getPreloadDurationFromPreviousItemDuration(
            item,
            previousItemForPreload?.itemDurationMs ?? currentItem.itemDurationMs
          ),
          listId: item.list_id,
          rules: item.rules,
          availableAt: currentItem.availableAt,
          expireAt: currentItem.expireAt,
        };

        return contentItem;
      }
      return undefined;
    })
    .filter(notUndefinedOrNull);

  return flattenNestedContentItems(list, nestedPlaylistContentLists, files);
};

export const getPlaylistWithPropsByListId = (
  gqlContentList: GqlContentListItem[]
): { [key: string]: GqlContentProps } | undefined => {
  const list: { [key: string]: GqlContentProps } = {};
  gqlContentList.forEach((item) => {
    if (
      item &&
      item.content._ref.type === "playlist" &&
      item.content.props &&
      Object.keys(item.content.props).length > 0
    ) {
      const listId = item.list_id;
      list[listId] = item.content.props;
    }
  });
  return Object.keys(list).length > 0 ? list : undefined;
};
