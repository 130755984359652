import { generateDomId, isMediaPlaying } from "./utils";
import { Logger, LogMessage, LogObjects } from "../../../../logger/logger";
import { IVideoPool, VideoPool } from "./VideoPool";

const IOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  !window.MSStream;
const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i;
const DASH_EXTENSIONS = /\.(mpd)($|\?)/i;

export interface PlayerOptions {
  debug: boolean;
  autoplay: boolean;
  controls: boolean;
  muted: boolean;
  loop: boolean;
  preload: boolean;
  videoPool: IVideoPool;
  container?: string; // parent dom node selector
  onError?: (error: Error | MediaError) => void;
}

export class PlayerClass {
  protected player: HTMLVideoElement | null = null;
  private readonly id: string;
  private readonly opts: PlayerOptions;
  private isDestroyed = false;
  private logger: Logger | undefined;
  private activePlayPromise: Promise<void> | undefined;

  private isPlayAttemptActive = false; // shows if the video.play() promise is currently active (not resolved)

  constructor(options: Partial<PlayerOptions>, logger?: Logger) {
    this.id = generateDomId();
    this.logger = logger;

    this.opts = {
      debug: false,
      autoplay: false,
      controls: false,
      muted: false,
      loop: false,
      preload: true,
      videoPool: new VideoPool(),
      ...options,
    };

    this.create();
  }

  private logDebug(logObj: LogMessage | LogObjects) {
    if (this.opts.debug && this.logger) {
      this.logger.info(logObj);
    }
  }

  private onError = (event: unknown) => {
    if (this.isPlayAttemptActive) {
      // there is dedicated logic to handle errors during play() attempts, no need to emit them.
      return;
    }

    const errorEvent = event as { target: { error?: MediaError } } | undefined;

    const error = errorEvent?.target?.error;

    if (this.opts.onError) {
      if (error) {
        this.opts.onError(error);
      } else {
        this.opts.onError(new Error(`Unknown error happened in PlayerClass.`));
      }
    }
  };

  public destroy = async (): Promise<void> => {
    if (!this.player) {
      this.logger?.warn("PlayerClass instance is already destroyed.");
      return;
    }

    if (this.activePlayPromise) {
      await this.activePlayPromise;
    }

    this.logDebug("Destroying PlayerClass instance.");
    await this.pause();

    this.player.removeEventListener("error", this.onError);

    this.player.src = "";
    this.player.load();
    this.player.removeAttribute("src");

    this.logDebug(
      "Video pool length before returning the video element:" +
        this.opts.videoPool.getLength()
    );
    this.opts.videoPool.returnInstance(this.player);
    this.unmount();

    this.player = null;
    this.isDestroyed = true;
  };

  private create = (): void => {
    // add all supported events listener
    try {
      this.logDebug(
        "Video pool length before new element request:" +
          this.opts.videoPool.getLength()
      );
      this.player = this.opts.videoPool.requestInstance();
      this.player.setAttribute("id", this.id);

      this.player.addEventListener("error", this.onError);

      // apply attributes
      if (this.opts.autoplay) this.player.setAttribute("autoplay", "");
      if (this.opts.controls) this.player.setAttribute("controls", "");
      if (this.opts.loop) this.player.setAttribute("loop", "");
      if (this.opts.muted) {
        this.player.setAttribute("muted", "");
        this.player.muted = true;
      }

      if (this.opts.preload) this.player.setAttribute("preload", "auto"); // auto | metadata | none

      if (IOS) {
        // https://webkit.org/blog/6784/new-video-policies-for-ios/
        this.player.setAttribute("playsinline", "");
        this.player.setAttribute("webkit-playsinline", "");
      }

      this.render();
    } catch (err) {
      this.logger?.error(
        `Error in PlayerClass create() method. ${(err as Error).message}.`
      );
      if (this.opts.onError) {
        this.opts.onError(err as Error);
      }
    }
  };

  private render(): void {
    if (!this.player) {
      return;
    }

    try {
      if (this.opts.container) {
        const container = document.querySelectorAll(this.opts.container)[0];
        if (container) {
          container.appendChild(this.player);
          this.logDebug(
            "Append element to this.opts.container: " + this.opts.container
          );
        }
      } else {
        document.body.appendChild(this.player);
        this.logDebug("Append element to document root");
      }
    } catch (err) {
      this.logger?.error("Render error: " + (err as Error).message);
      if (this.opts.onError) {
        this.opts.onError(err as Error);
      }
    }
  }

  private unmount = (): void => {
    const elem = document.getElementById(this.id);
    if (elem?.parentNode) {
      elem.parentNode.removeChild(elem);
    }
  };

  public load = async (url: string): Promise<void> => {
    if (this.isDestroyed) {
      this.logger?.warn(`Attempt to call load() when instance is destroyed.`);
      return;
    }

    if (!this.player) {
      this.logger?.warn("Video component instance does not exist.");
      return;
    }

    if (DASH_EXTENSIONS.test(url)) {
      this.logger?.error("Dash extension not supported");
      return;
    } else if (HLS_EXTENSIONS.test(url) && !IOS) {
      this.logger?.error("Hls extension not supported");
      return;
    } else {
      this.player.src = url;
      // ensure load for fix in iOS, the current one not seem to do autoload
      if (this.opts.preload) {
        if (this.activePlayPromise) {
          await this.activePlayPromise;
        }
        this.player.load();
      }
    }
  };

  public play = async (): Promise<void> => {
    // TODO: fix autoplay not work on all platform
    // https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
    // https://webkit.org/blog/6784/new-video-policies-for-ios/
    // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    if (this.activePlayPromise) {
      // don't start new play attempt if there is already one in progress
      return;
    }

    this.activePlayPromise = new Promise(async (resolve, reject) => {
      const callResolve = () => {
        this.activePlayPromise = undefined;
        resolve();
      };

      const callReject = (error: Error) => {
        this.activePlayPromise = undefined;
        reject(error);
      };

      if (this.player && !isMediaPlaying(this.player) && !this.isDestroyed) {
        this.isPlayAttemptActive = true; // this ensures errors that are handled within this method don't get emitted
        const promise = this.player.play();
        if (promise) {
          promise
            .then(() => {
              callResolve();
            })
            .catch((err) => {
              if (err instanceof DOMException) {
                // handle error from autoplaying video with sound without user interaction
                if (
                  this.player &&
                  !isMediaPlaying(this.player) &&
                  !this.isDestroyed
                ) {
                  this.logDebug("Attempting to autoplay video without sound");
                  this.mute();
                  const playMutedPromise = this.player.play();
                  return playMutedPromise
                    .then(() => {
                      callResolve();
                    })
                    .catch((mutedError) => {
                      this.isPlayAttemptActive = false;
                      console.error(mutedError);
                      this.onError(mutedError);
                      callReject(mutedError);
                    });
                } else {
                  callResolve();
                }
              } else {
                // handle other cases
                let retryAttemptCount = 0;

                const attemptPlay = async (): Promise<void> => {
                  if (
                    this.player &&
                    !isMediaPlaying(this.player) &&
                    !this.isDestroyed
                  ) {
                    this.logDebug(
                      `Attempting to play video: ${retryAttemptCount + 1}`
                    );
                    const playAttemptPromise = this.player.play();
                    return playAttemptPromise
                      .then(() => {
                        callResolve();
                      })
                      .catch((playAttemptError) => {
                        if (retryAttemptCount < 5) {
                          setTimeout(() => {
                            attemptPlay();
                          }, 1000);
                        } else {
                          this.isPlayAttemptActive = false;
                          this.onError(playAttemptError);
                          callReject(playAttemptError);
                        }
                      });
                  } else {
                    callResolve();
                  }

                  retryAttemptCount += 1;
                };
                attemptPlay();
              }
            });
        } else {
          callResolve();
        }
      } else {
        callResolve();
      }
    });

    return this.activePlayPromise;
  };

  public pause = async (): Promise<void> => {
    if (this.activePlayPromise) {
      await this.activePlayPromise;
    }
    if (this.player && !this.isDestroyed && isMediaPlaying(this.player)) {
      this.player.pause();
    }
  };

  public seekTo = (seconds: number): void => {
    if (this.player) {
      this.player.currentTime = seconds;
    }
  };

  private mute = (): void => {
    if (this.player) {
      this.player.muted = true;
    }
  };

  public getDuration = (): number | undefined => this.player?.duration;

  public getCurrentTime = (): number | undefined => this.player?.currentTime;

  public getSecondsLoaded = (): number | undefined => {
    if (this.player) {
      const { buffered } = this.player;
      if (buffered.length === 0) {
        return 0;
      }
      const end = buffered.end(buffered.length - 1);
      const duration = this.getDuration();
      if (typeof duration === "number" && end > duration) {
        return duration;
      } else {
        return end;
      }
    }
  };
}
