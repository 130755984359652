import { FullscreenContent } from "./useFullscreenContent";
import { FullscreenZoneTransitioningItemInfo } from "../types";
import { useEffect, useMemo, useState } from "react";

/**
 * Returns the data of a content item to be used for transition of the channel layout zone (when switching to/from the
 * fullscreen zone).
 */
export function useChannelLayoutZoneTransitioningItemInfo(
  fullscreenContent: FullscreenContent
): FullscreenZoneTransitioningItemInfo {
  const { active, preload } = fullscreenContent;
  const [targetTimelineId, setTargetTimelineId] = useState<string | undefined>(
    undefined
  );
  const [targetIndexInTimeline, setTargetIndexInTimeline] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (preload && !active) {
      setTargetTimelineId(preload.timelineId);
      setTargetIndexInTimeline(preload.indexInTimeline - 1);
    } else if (active && !preload) {
      setTargetTimelineId(active.timelineId);
      setTargetIndexInTimeline(active.indexInTimeline + 1);
    } else {
      setTargetTimelineId(undefined);
      setTargetIndexInTimeline(undefined);
    }
  }, [active, preload]);

  return useMemo(
    () => ({
      targetTimelineId,
      targetIndexInTimeline,
    }),
    [targetTimelineId, targetIndexInTimeline]
  );
}
