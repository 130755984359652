import React, { FunctionComponent, memo, useContext, useEffect } from "react";
import { PlayerFile } from "../../../../store/files/types";
import { ImageViewer } from "../ImageViewer/ImageViewer";
import { VideoViewer } from "../VideoViewer/VideoViewer";
import { AudioViewer } from "../AudioViewer/AudioViewer";
import { DocumentContainer } from "../DocumentViewer/DocumentViewer";
import { ContentSizeType } from "../../../../types/content";
import {
  ElementSize,
  ignoreToCacheTheseParamsOnOurNativePlayers,
} from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import { requestFileSuccess } from "../../../../store/files/actions";
import { useInitialPlaybackPosition } from "../../../../utils/useInitialPlaybackPosition";
import { useMediaSource } from "../../../../utils/useMediaSource";
import { ConfigState } from "../../../../store/config/types";
import { Maybe, useFileById } from "../../../../queries";
import { FEATURE_FLAGS_ENUM } from "../../../../featureFlags";
import { ContentFailureGenericCb } from "../TimelineViewer/types";
import ClientApiContext from "../../../../ClientApiContext";

export interface FileViewerContainerProps {
  id: string;
  sizeType: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  hasNextItem?: boolean;
  fullDurationMs: number;
  itemStartTimestamp?: number;
  isRoot?: boolean;
  onContentFailure: ContentFailureGenericCb;
}

export const FileViewerContainer: FunctionComponent<FileViewerContainerProps> = (
  props: FileViewerContainerProps
) => {
  const { id, isRoot, itemStartTimestamp, sizeType, containerSize } = props;
  const dispatch = useDispatch();

  const initialPlaybackPositionMs = useInitialPlaybackPosition(
    itemStartTimestamp,
    props.fullDurationMs
  );

  const videoSource = useSelector<PlayerState, string>(
    (state) => state.screen.videoSource
  );

  const highResolutionImage = useSelector<PlayerState, boolean>(
    (state) => state.screen.highResolutionImage
  );

  const file = useSelector<PlayerState, PlayerFile | undefined>((state) => {
    return state.files.byId[id];
  });

  const config = useSelector<PlayerState, ConfigState>((state) => {
    return state.config;
  });

  const featureFlags = useSelector<PlayerState, Maybe<string>[]>(
    (state) => state.organization?.featureFlags || []
  );

  const secureMediaPolicy = useSelector<PlayerState, string | undefined>(
    (state) => state.config.secureMediaPolicy
  );

  const [fetchFile, { data }] = useFileById({
    useCache: false,
    skipCache: true,
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (data?.fileById) {
      dispatch(requestFileSuccess(data.fileById));
    }
  }, [data?.fileById, dispatch]);

  useInitialFetch(!!isRoot, fetchFile);

  const src = useMediaSource(
    file,
    config,
    sizeType,
    containerSize,
    featureFlags,
    ignoreToCacheTheseParamsOnOurNativePlayers(secureMediaPolicy),
    videoSource,
    highResolutionImage
  );

  if (!file) {
    return (
      <p data-testid="can-not-find-file">
        Sorry, we can&apos;t find the file you are looking for.
      </p>
    );
  }

  return (
    <FileViewer
      src={src}
      file={file}
      initialPlaybackPositionMs={initialPlaybackPositionMs}
      featureFlags={featureFlags}
      userInteractionEnabled={
        (config.contextConfig?.userInteractionEnabled || false) as boolean
      }
      {...props}
    />
  );
};

interface FileViewerProps {
  src: string | string[];
  file: PlayerFile;
  sizeType: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  hasNextItem?: boolean;
  fullDurationMs: number;
  initialPlaybackPositionMs: number;
  itemStartTimestamp?: number;
  userInteractionEnabled: boolean;
  onContentFailure: ContentFailureGenericCb;
  featureFlags: Maybe<string>[];
}

export const FileViewer: FunctionComponent<FileViewerProps> = memo(
  ({
    src,
    file,
    sizeType,
    containerSize,
    isPreload,
    hasNextItem,
    fullDurationMs,
    itemStartTimestamp,
    initialPlaybackPositionMs,
    userInteractionEnabled,
    onContentFailure,
    featureFlags,
  }: FileViewerProps) => {
    const clientApiContext = useContext(ClientApiContext);
    const isTizenPlayer = clientApiContext?.playbackConfig?.isTizenPlayer;
    const { id } = file;

    switch (file.type) {
      case "image":
        if (typeof src !== "string") {
          return null;
        }
        return (
          <ImageViewer
            src={src}
            name={file.name}
            urlKey={file.urlKey}
            mimetype={file.mimetype}
            key={id}
            sizeType={sizeType}
            containerSize={containerSize}
            isPreload={isPreload}
            onContentFailure={onContentFailure}
          />
        );
      case "video":
        if (typeof src !== "string") {
          return null;
        }

        // this is specific to disabled preload slot only on Tizen and fill mode only
        // when we set fill mode with preload video content on tizen the video will be squeezed but not happen in fit mode.
        // so we can still enable preload video for fit mode on tizen
        // adding new feature flag to disable preload video
        const shouldSetSrcForPreload =
          isPreload && isTizenPlayer && sizeType === "fill";
        const isDisablePreloadVideoByFeatureFlag =
          isPreload &&
          featureFlags.includes(FEATURE_FLAGS_ENUM.DISABLE_PRELOAD_VIDEO);
        return (
          <VideoViewer
            src={
              !shouldSetSrcForPreload && !isDisablePreloadVideoByFeatureFlag
                ? src
                : ""
            }
            file={file}
            key={id}
            isPreload={isPreload}
            hasNextItem={hasNextItem}
            userInteractionEnabled={userInteractionEnabled}
            sizeType={sizeType}
            initialPlaybackPositionMs={initialPlaybackPositionMs}
            shouldUsePersistentVideo={featureFlags.includes(
              FEATURE_FLAGS_ENUM.PERSISTENT_VIDEO
            )}
          />
        );
      case "audio":
        if (typeof src !== "string") {
          return null;
        }
        return (
          <AudioViewer
            src={src}
            key={id}
            isPreload={isPreload}
            initialPlaybackPositionMs={initialPlaybackPositionMs}
            fileName={file.name}
            mimetype={file.mimetype}
            onContentFailure={onContentFailure}
          />
        );
      case "document":
        if (!Array.isArray(src)) {
          return null;
        }
        return (
          <DocumentContainer
            src={src}
            file={file}
            key={id}
            sizeType={sizeType}
            containerSize={containerSize}
            durationMs={fullDurationMs}
            itemStartTimestamp={itemStartTimestamp || 0}
            isPreload={isPreload}
            onContentFailure={onContentFailure}
          />
        );
      default:
        return (
          <div data-testid="file-not-support">
            File type support coming soon.
          </div>
        );
    }
  }
);
FileViewer.displayName = "FileViewer";
