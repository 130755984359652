import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { useCreateAppManagementToken } from "../../../../queries";
import { captureException } from "../../../../utils/bugTracker";
import { Logger } from "../../../../logger/logger";
import { useGetAppTokenWithRetry } from "../../../../utils/useGetAppTokenWithRetry";
import {
  TokenRetryError,
  TokenRetryResponse,
} from "../../../../types/appToken";
import { Loading } from "../../../core/components/Loading/Loading";
import { SECOND_DURATION_MS } from "../../../../constants";
import { ContextConfig } from "../../../../store/config/types";
import { ScreenData } from "../../../../store/screen/types";
import { InitializeMessagePayload } from "../AppViewer/types";
import { ConfigurationManager } from "../../../../configurationManager";
import { AppEditorViewer } from "../AppEditorViewer/AppEditorViewer";
import { checkForGraphQLTokenIssues } from "../../../../utils/index";

const log = new Logger("AppEditorViewerContainer");
export const AppEditorViewerContainer: FunctionComponent = () => {
  const contentPathId = useSelector<PlayerState, string | undefined>(
    (state) => state.config.contentConfig.id
  );

  const contextConfig = useSelector<PlayerState, ContextConfig>(
    (state) => state.config.contextConfig || {}
  );

  const screenData = useSelector<PlayerState, ScreenData | undefined>(
    (state) => state.screen.screenData
  );

  const overrideAppInitialize = useSelector<
    PlayerState,
    Partial<InitializeMessagePayload> | undefined
  >((state) => {
    const overrideAppInitialize = state.config.overrideAppInitialize;
    // Ensure content path matches overrideAppInitialize being sent
    if (overrideAppInitialize?.appInstanceId === contentPathId) {
      return overrideAppInitialize;
    }
  });

  const spaceId = useSelector<PlayerState, string | undefined>(
    (state) => state.screen?.spaceId || state.config.spaceId
  );

  const orgId = useSelector<PlayerState, string | undefined>(
    (state) => state.organization?.id
  );

  const graphQLToken = useSelector<PlayerState, string>((state) => {
    return state.config.graphqlToken;
  });

  // Using a key to trigger the app re-mount
  const [reMountKey, setReMountKey] = useState<number | undefined>(undefined);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    // update the key prop to cause AppEditorViewer re-mount on app data change
    setReMountKey(new Date().getTime());
  }, [overrideAppInitialize]);

  const onSuccessCallback = useCallback((data: TokenRetryResponse) => {
    log.info({
      message: "Request app management token [SUCCESS]",
      context: {
        retryCount: data.retryCount,
      },
    });
    setToken(data.token);
  }, []);

  const onFailureCallback = useCallback(
    (error: TokenRetryError) => {
      const tokenIssues = checkForGraphQLTokenIssues("Error", graphQLToken);

      log.error({
        message: `Request app management token [FAIL] - ${error.source}`,
        context: {
          errorMessage: error.message,
          retryCount: error.retryCount,
          errorCode: error.status,
          errorSource: error.source,
          tokenIssues,
        },
      });

      captureException(
        new Error(
          `Request app management token [FAIL] - Attempt ${error.retryCount}`
        )
      );
    },
    [graphQLToken]
  );

  const onRetryExhaustionCallback = useCallback((error: TokenRetryError) => {
    log.error({
      message: `Request app management token MAX RETRIES REACHED [FAIL] - ${error.source} (${error.retryCount})`,
      context: {
        errorMessage: error.message,
        retryCount: error.retryCount,
        errorCode: error.status,
        errorSource: error.source,
      },
    });
    captureException(
      new Error(
        `Request app management token MAX RETRIES REACHED [FAIL] (${error.retryCount})`
      )
    );
  }, []);

  useGetAppTokenWithRetry(
    "management",
    spaceId,
    "",
    useCreateAppManagementToken,
    onSuccessCallback,
    onFailureCallback,
    onRetryExhaustionCallback
  );

  useEffect(() => {
    if (!token) {
      const displayErrorTimeout = setTimeout(() => {
        setDisplayError(true);
        captureException(
          new Error(`Editor app unable to display - no appMangementToken`)
        );
      }, SECOND_DURATION_MS * 20); // 20 Seconds

      const displayLoadingTimeout = setTimeout(() => {
        setDisplayLoading(true);
      }, SECOND_DURATION_MS * 2); // 2 Seconds

      return () => {
        clearTimeout(displayErrorTimeout);
        clearTimeout(displayLoadingTimeout);
      };
    }
  }, [token]);

  // Only render the editor viewer when we have an appMangementToken
  if (token) {
    return (
      <AppEditorViewer
        key={reMountKey}
        overrideAppInitialize={overrideAppInitialize}
        orgId={orgId}
        filesByAppInstanceId={
          overrideAppInitialize?.filesByAppInstanceId || { nodes: [] }
        }
        contextConfig={contextConfig}
        screenData={screenData}
        sdkInterface={ConfigurationManager.getInstance().getRemoteApi().remote}
        appManagementToken={token}
      />
    );
  }

  // After 20 seconds of no appMangementToken render an error state
  if (displayError) {
    return (
      <div
        style={{
          background: "#f50606",
          padding: "0.5em",
          color: "#f3ebeb",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            marginLeft: "80px",
            fontSize: "16px",
            marginBottom: 0,
          }}
        >
          Sorry, there has been an issue setting up the app configuration,
          please refresh and try again.
        </p>
      </div>
    );
  }
  // Until we have an appManagementToken or error show the loading screen
  if (displayLoading) {
    return <Loading />;
  }

  return <></>;
};
