import { ContentList } from "../../store/contentLists/types";
import {
  TimelineItem,
  TimelinePlaybackMode,
} from "../../store/timelines/types";
import { TimeOptions } from "../../utils/timeManager";
import {
  generateItemsForBreakpoints,
  ScheduleFilterReport,
} from "../../utils/scheduleFilter";
import { BreakpointWithItems } from "../../store/playback/types";
import { getBreakpointTimeline } from "./getBreakpointTimeline";

export interface TimelineFragmentGeneratorOptions {
  targetStartTimestamp: number; // timeline fragment start
  maxItemsAhead: number; // max items to generate ahead of fragment start
  maxTimeAheadMs: number; // max time to generate ahead of fragment start
  maxItemsBehind: number; // max items to generate behind the fragment start
  maxTimeBehindMs: number; // max time to generate behind the fragment start
}

/**** TIMELINE GENERATOR V2 *******************************************
/** Note the input should allow to specific period of timeline generator
/** V2 is ignore maxTimeAheadMs and maxTimeBehindMs, always generate per day
/** The time accuracy is 1 day, so if want to get timeline > 1 day
/** then make multiple call to this function with differnt targetStartTimestamp
 * Reference Document > https://www.notion.so/screencloud/Timeline-Generator-V-2-d8b820a5c87d4fb58fbad186d82da619
/**********************************************************************/

/** Generate the timeline items for a specific date
 ** ALWAYS GENERATE FOR ONLY ONE DAY SCOPE */
export function generateTimeline(
  targetDateISO: string,
  contentList: ContentList,
  timeOptions: TimeOptions,
  playbackMode?: TimelinePlaybackMode
): TimelineItem[] {
  // 1. Fill the available items for each schedule breakpoint
  const breakpointsWithItems: BreakpointWithItems[] = generateItemsForBreakpoints(
    contentList,
    timeOptions,
    targetDateISO,
    playbackMode
  );

  // note: last breakpoints is always end-of-day which is extra breakpoint for reference as the closing the breakpoint
  //       there is not necessary to generate timelineItem for end-of-day breakpoint.
  //       that why the for loop check is " i < totalBreakpoints - 1 "
  //       and therefore minimum number of totalBreakpoints is 2 (start-of-day and end-of-day)

  return getBreakpointTimeline(breakpointsWithItems);
}

/** filter output to only concern limit of maxItemsAhead / maxItemsBehind
/** the check start from the start play timeline idx  */
export function filterTimelineOutput(
  generatorOptions: TimelineFragmentGeneratorOptions,
  startTimestampMs: number,
  timelineItems: TimelineItem[] // result apply directly to the source inputs here
): TimelineItem[] {
  let targetStartTimelineIdx = getStartPlayIdxForTimeline(
    timelineItems,
    startTimestampMs
  );

  // chop the item ahead to the limitation
  if (generatorOptions.maxItemsAhead && generatorOptions.maxItemsAhead > 0) {
    timelineItems.splice(
      targetStartTimelineIdx + generatorOptions.maxItemsAhead + 1
    );
  }

  // chop the item behind to the limitation
  if (generatorOptions.maxItemsBehind >= 0) {
    const tartgetTrimFromStart =
      targetStartTimelineIdx - generatorOptions.maxItemsBehind;
    timelineItems.splice(0, tartgetTrimFromStart);
    // update target idx to the new order after trim
    targetStartTimelineIdx -= tartgetTrimFromStart;
  }
  return timelineItems;
}

/** Return the index of timeline item that match to startTimestampMs */
export function getStartPlayIdxForTimeline(
  timelineItems: TimelineItem[],
  startTimestampMs: number
): number {
  let targetStartTimelineIdx = 0;
  const totalTimelineItems = timelineItems.length;
  for (let idx = 0; idx < totalTimelineItems; idx++) {
    const item = timelineItems[idx];
    if (
      startTimestampMs >= item.startTimestampMs &&
      startTimestampMs < item.startTimestampMs + item.showDurationMs
    ) {
      targetStartTimelineIdx = idx;
      break;
    }
  }
  return targetStartTimelineIdx;
}

export type ScheduleBreakpointsGetter = (dateIsoShort: string) => number[];

export type FilterFunction = (targetTimestamp: number) => ScheduleFilterReport;
