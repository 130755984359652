import React, {
  CSSProperties,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestChannelSuccess } from "../../../../store/channels/actions";
import { Channel } from "../../../../store/channels/types";
import { Layout, Zone } from "../../../../store/layouts/types";
import { PlayerState } from "../../../../store/rootReducer";
import { Loading } from "../../../core/components/Loading/Loading";
import { TimelineViewerContainer } from "../TimelineViewer/TimelineViewer";
import styles from "./ChannelViewer.module.css";
import { EntityType } from "@screencloud/signage-firestore-client";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {
  deprecateIsSplashChannel,
  getHiddenZoneTimelineId,
  getVisibleChannelZones,
} from "../../../../utils/channelContent";
import { PlaybackControlContainer } from "../../../core/containers/PlaybackControlContainer/PlaybackControlContainer";
import { ConfigurationManager } from "../../../../configurationManager";
import { useChannelById } from "../../../../queries";
import { FullscreenContentViewerContainer } from "./FullscreenContentViewer";
import AudioSettingsContainer from "../../../core/containers/AudioSettingsContainer/AudioSettingsContainer";
import { useAudioSettings } from "../../../../utils/useAudioSettings";

interface ChannelViewerContainerProps {
  id: string;
  isRoot?: boolean;
}

/**
 * Get the specified Channel.
 */
export const ChannelViewerContainer = (
  props: ChannelViewerContainerProps
): ReactElement<ChannelViewerContainerProps> => {
  const dispatch = useDispatch();

  const channel = useSelector<PlayerState, Channel | undefined>(
    (state) => state.channels.byId[props.id]
  );

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(!!channel);

  const [fetchChannel, { data, loading }] = useChannelById({
    useCache: false,
    skipCache: true,
    variables: {
      id: props.id,
    },
  });

  useInitialFetch(!!props.isRoot, fetchChannel);

  useEffect(() => {
    if (data?.channelById) {
      dispatch(requestChannelSuccess(data.channelById));
      setIsDataLoaded(true);
    }
  }, [data?.channelById, dispatch]);

  if (!channel) {
    return (
      <p hidden={loading}>
        Sorry, we can&apos;t find the channel you are looking for.
      </p>
    );
  }

  return !isDataLoaded ? (
    <Loading />
  ) : (
    <>
      <PlaybackControlContainer
        type={"channel"}
        id={props.id}
        sdkInterface={ConfigurationManager.getInstance().getRemoteApi().remote}
      />
      <LiveUpdateConnector
        entityId={props.id}
        entityType={EntityType.CHANNEL}
      />
      {channel?.layoutId ? (
        <LiveUpdateConnector
          entityId={channel.layoutId}
          entityType={EntityType.LAYOUT}
        />
      ) : null}
      <ChannelLayoutViewer channel={channel} />
    </>
  );
};

interface ChannelLayoutViewerProps {
  channel: Channel;
}

function removeStyleTags(css: string): string {
  // Chop the built-in <style></style> out of the string.
  return css.replace(/<(\/)?style>/g, "");
}

/**
 * Get the layout corresponding to a given Channel.
 */
const ChannelLayoutViewer = memo(
  (props: ChannelLayoutViewerProps): ReactElement<ChannelLayoutViewerProps> => {
    const { channel } = props;
    const layout = useSelector<PlayerState, Layout | undefined>(
      (state) => state.layouts.byId[channel.layoutId]
    );

    // todo: this is to be removed in next versions. Details in gh issue:
    //  https://github.com/screencloud/studio-player/issues/613
    const deprecateIsSplashChannelValue = deprecateIsSplashChannel(layout);

    const getOuterBlockStyles = useCallback((): CSSProperties => {
      const result: CSSProperties = {
        overflow: "hidden",
        position: "absolute",
      };

      if (layout !== undefined && !layout.isFlexible && layout.isScalable) {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const scaleW: number = screenWidth / layout.width;
        const scaleH: number = screenHeight / layout.height;
        const finalAspectRatio = Math.min(scaleW, scaleH);

        const finalWidth = Math.ceil(finalAspectRatio * layout.width);
        const finalHeight = Math.ceil(finalAspectRatio * layout.height);
        const positionLeft = (screenWidth - finalWidth) / 2;
        const positionTop = (screenHeight - finalHeight) / 2;

        result.width = `${finalWidth}px`;
        result.height = `${finalHeight}px`;
        result.left = positionLeft;
        result.top = positionTop;
      } else if (
        layout !== undefined &&
        !layout.isFlexible &&
        !layout.isScalable
      ) {
        result.width = `${Math.ceil(layout.width)}px`;
        result.height = `${Math.ceil(layout.height)}px`;
        result.left = 0;
        result.top = 0;
      } else {
        result.height = "100%";
        result.width = "100%";
      }

      return result;
    }, [layout]);

    const [outerBlockStyles, setOuterBlockStyles] = useState<CSSProperties>(
      getOuterBlockStyles()
    );

    const css = channel.deprecateContentLayout?.config.css || layout?.css || "";
    const cssStyle = removeStyleTags(css);

    const hiddenZoneId = getHiddenZoneTimelineId(channel, layout);

    useEffect(() => {
      const onResize = debounce(() => {
        const newStyles = getOuterBlockStyles();
        if (!isEqual(newStyles, outerBlockStyles)) {
          setOuterBlockStyles(newStyles);
        }
      }, 500);

      try {
        window.addEventListener("resize", onResize);
      } catch (error) {
        console.log(error);
      }

      onResize();

      return (): void => {
        window.removeEventListener("resize", onResize);
      };
    }, [getOuterBlockStyles, outerBlockStyles]);

    const zones: Zone[] = useMemo(() => {
      return getVisibleChannelZones(channel, layout);
    }, [channel, layout]);

    // todo: this identifier is likely to be not working right now, so can be removed when
    //  https://github.com/screencloud/studio-player/issues/613 is solved
    const deprecateE2eTestIdentifier = deprecateIsSplashChannelValue
      ? "splash-channel"
      : "";

    const { primaryAudioContentId } = useAudioSettings(
      zones.map((zone) => zone.contentListId)
    );

    return (
      <div
        id={deprecateE2eTestIdentifier}
        title={deprecateE2eTestIdentifier}
        aria-label={deprecateE2eTestIdentifier}
        data-testid="outer-block"
        style={outerBlockStyles}
      >
        <FullscreenContentViewerContainer>
          <style data-testid="cssStyle">{cssStyle}</style>
          <div className={styles.wrapper}>
            {hiddenZoneId && (
              <div data-testid="hidden-zone" className={styles.hidden}>
                <AudioSettingsContainer
                  defaultMute={false}
                  primaryAudio={{
                    enabled: !!primaryAudioContentId,
                    shouldMute: primaryAudioContentId !== hiddenZoneId,
                  }}
                >
                  <TimelineViewerContainer id={hiddenZoneId} />
                </AudioSettingsContainer>
              </div>
            )}
            {zones.map((zone) => {
              return (
                <div data-testid={zone.id} id={zone.id} key={zone.id}>
                  <AudioSettingsContainer
                    defaultMute={false}
                    primaryAudio={{
                      enabled: !!primaryAudioContentId,
                      shouldMute: primaryAudioContentId !== zone.contentListId,
                    }}
                  >
                    <TimelineViewerContainer id={zone.contentListId} />
                  </AudioSettingsContainer>
                </div>
              );
            })}
          </div>
        </FullscreenContentViewerContainer>
      </div>
    );
  }
);

ChannelLayoutViewer.displayName = "ChannelLayoutViewer";
