import cx from "classnames";
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Mimetype } from "../../../../store/files/types";
import { ContentSizeType } from "../../../../types/content";
import { ElementSize } from "../../../../utils/helpers";
import styles from "./ImageViewer.module.css";
import { Logger } from "../../../../logger/logger";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { ScreenState } from "../../../../store/screen/types";

import { ContentFailureGenericCb } from "../TimelineViewer/types";
const log = new Logger("imageViewer");

export interface ImageProps {
  src: string;
  sizeType?: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  name: string;
  mimetype?: Mimetype;
  onContentFailure: ContentFailureGenericCb;
  id?: string;
}

interface ImageViewerProps {
  src: string;
  name: string;
  mimetype?: Mimetype;
  urlKey?: string;
  sizeType?: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  onContentFailure: ContentFailureGenericCb;
  id?: string;
}

// Helper function to extract the fallback URL from the initial 'src'
function extractFallbackUrl(src: string): string | null {
  try {
    const parsedUrl = new URL(src);

    // Handle URLs in the format: http://127.0.0.1:8700/proxyBase/ENCODED_URL
    const pathnameParts = parsedUrl.pathname.split("/proxyBase/");
    if (pathnameParts.length > 1) {
      const encodedUrl = pathnameParts[1];
      // Decode the encoded URL directly
      const fallbackUrl = decodeURIComponent(encodedUrl);
      return fallbackUrl;
    }

    if (parsedUrl.searchParams.has("url")) {
      // Handle URLs in the format: http://localhost:8700/?url=ENCODED_URL
      const encodedUrl = parsedUrl.searchParams.get("url") || "";
      return decodeURIComponent(encodedUrl);
    }

    // Fallback URL could not be extracted
    return null;
  } catch (error) {
    // If parsing fails, return null
    return null;
  }
}

export const Image = (props: ImageProps): ReactElement<ImageProps> | null => {
  const {
    sizeType,
    src,
    name,
    mimetype,
    isPreload,
    onContentFailure,
    id,
  } = props;
  const retryLimit = 3;
  const [isPreloaded, setIsPreloaded] = useState<boolean>(false);
  const [reMountKey, setReMountKey] = useState<number | undefined>(
    new Date().getTime()
  );
  const attemptCount = useRef<number>(1);
  const retryTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [currentSrc, setCurrentSrc] = useState<string>(src);

  useEffect(() => {
    setCurrentSrc(src);
  }, [src]);

  useEffect(() => {
    let preloadingElement: HTMLImageElement | null = null;

    if (!isPreloaded && currentSrc !== undefined && currentSrc !== "") {
      preloadingElement = new window.Image();
      preloadingElement.onload = () => {
        setIsPreloaded(true);
      };
      preloadingElement.onerror = () => {
        // ignore the error for now
        setIsPreloaded(true);
      };
      preloadingElement.src = currentSrc;
    }

    return (): void => {
      if (preloadingElement) {
        preloadingElement.src = "";
        preloadingElement.onload = null;
        preloadingElement.onerror = null;
      }
      preloadingElement = null;
    };
  }, [currentSrc, isPreloaded]);

  const sizeStyle = sizeType === "fill" ? styles.imageFill : styles.imageFit;
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);

  useEffect(() => {
    return () => {
      if (retryTimeout.current) {
        clearTimeout(retryTimeout.current);
        retryTimeout.current = null;
      }
    };
  }, []);

  const onError = useCallback(() => {
    // do not retry on preload
    if (isPreload) {
      return;
    }

    const timeoutMs = attemptCount.current * 3 * 1000;
    if (retryTimeout.current !== null) {
      clearTimeout(retryTimeout.current);
      retryTimeout.current = null;
    }
    // TODO: replace the second params with look up function for content object from normaliza data (redux)
    log.error({
      message: `Cannot load image ${currentSrc}`,
      context: {
        screenId: screen.id,
        name,
        isPreload: isPreload,
      },
    });
    if (attemptCount.current < retryLimit) {
      retryTimeout.current = setTimeout(() => {
        setReMountKey(new Date().getTime());
        log.info({
          message: `Retry reload image attemptCount[${attemptCount}]${currentSrc}`,
          context: {
            screenId: screen.id,
            name,
            isPreload: isPreload,
          },
        });
        attemptCount.current += 1;
      }, timeoutMs);
    } else if (attemptCount.current === retryLimit) {
      // add a fallback network on last attempt

      try {
        const fallbackSrc = extractFallbackUrl(currentSrc);
        if (fallbackSrc) {
          setCurrentSrc(fallbackSrc);
          setReMountKey(new Date().getTime());
          attemptCount.current += 1;

          log.info({
            message: `Retry fallback image to ${fallbackSrc}`,
            context: {
              screenId: screen.id,
              name,
              isPreload: isPreload,
            },
          });
        } else {
          // If no 'url' parameter is found, handle content failure
          onContentFailure();
        }
      } catch (error) {
        // If parsing fails, handle content failure
        onContentFailure();
      }
    } else {
      log.warn({
        message: `Reach limit! retry reload image ${currentSrc}`,
        context: {
          screenId: screen.id,
          name,
        },
        proofOfPlayFlag: true,
      });
      onContentFailure();
    }
  }, [attemptCount, name, screen.id, onContentFailure, isPreload, currentSrc]);

  useEffect(() => {
    log.info({
      message: `Show Image ${name}`,
      context: {
        id: id,
        screenId: screen.id,
        name,
        contentType: mimetype,
        isPreload,
        src: currentSrc,
      },
    });
  }, [currentSrc, name, mimetype, isPreload, screen.id, id]);

  return isPreloaded ? (
    <img
      className={cx(styles.image, sizeStyle)}
      alt={name}
      src={currentSrc}
      onError={onError}
      data-testid="image-loaded"
      key={reMountKey}
    />
  ) : null;
};

export const ImageViewer = memo(
  (props: ImageViewerProps): ReactElement<ImageViewerProps> => {
    return (
      <div
        className={styles.imageContainer}
        data-testid={`image-${props.urlKey}`}
      >
        <Image
          src={props.src}
          name={props.name}
          mimetype={props.mimetype}
          sizeType={props.sizeType}
          containerSize={props.containerSize}
          isPreload={props.isPreload}
          onContentFailure={props.onContentFailure}
          id={props.id}
        />
      </div>
    );
  }
);
ImageViewer.displayName = "ImageViewer";
