import {
  FullscreenContent,
  FullscreenItemReport,
} from "./useFullscreenContent";
import { TimelinesPlaybackState } from "../../../../../../store/playback/types";
import { useMemo } from "react";
import { useIsItemTransitioningOut } from "../../../TimelineViewer/hooks/useIsItemTransitioningOut";

/**
 * Determines if entire fullscreen zone is transitioning out
 * (right before regular channel layout is back after fullscreen content period)
 */
export function useIsActiveFullscreenSlotTransitioningOut(
  fullscreenContent: FullscreenContent,
  timelinesPlaybackState: TimelinesPlaybackState
): boolean {
  const isEndOfMainZonePeriod =
    !fullscreenContent.active?.item && fullscreenContent.preload?.item;
  const isEndOfFullscreenZonePeriod =
    fullscreenContent.active?.item && !fullscreenContent.preload?.item;

  const targetFullscreenReport:
    | FullscreenItemReport
    | undefined = useMemo(() => {
    if (isEndOfMainZonePeriod) {
      return fullscreenContent.preload;
    } else if (isEndOfFullscreenZonePeriod) {
      return fullscreenContent.active;
    } else {
      return undefined;
    }
  }, [
    isEndOfFullscreenZonePeriod,
    isEndOfMainZonePeriod,
    fullscreenContent.preload,
    fullscreenContent.active,
  ]);

  const targetTimelinePlaybackState = targetFullscreenReport?.timelineId
    ? timelinesPlaybackState[targetFullscreenReport.timelineId]
    : undefined;

  return useIsItemTransitioningOut(targetTimelinePlaybackState);
}
