import React, { FunctionComponent } from "react";
import { useEmbedMenu } from "../../../../providers/MenuProvider/MenuProvider";
import { MenuViews } from "../../../../providers/MenuProvider/types";
import GridView from "./GridView/GridView";
import MainView from "./MainView/MainView";

interface Props {
  search?: string;
}

const View: FunctionComponent<Props> = ({ search }: Props) => {
  const { view } = useEmbedMenu();

  switch (view) {
    case MenuViews.MAIN:
      return <MainView />;
    case MenuViews.GRID:
      return <GridView search={search} />;
    default:
      return <MainView />;
  }
};

export default View;
