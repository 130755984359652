import { LogLevel, RemoteLoggerConfig } from "./logger";
import { Context } from "@datadog/browser-core";

class RemoteEndPointLogger {
  private isEnabled = false;

  init(config: RemoteLoggerConfig) {
    this.isEnabled = !!config.remoteLogEndPoint;
  }

  log(level: LogLevel, message: string, context?: Context): void {
    fetch(
      `${
        process.env.REACT_APP_ENABLE_LOGS_ENDPOINT ||
        localStorage.getItem("enable_logs_endpoint")
      }`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          level: LogLevel[level],
          message: message,
          context: context,
        }),
      }
    )
      .then((res) => {
        // DONOT use this.log here will end up in infinite loop
        if (res.status === 200) {
          console.log("logs send to remote endpoint success ");
        } else {
          console.log("logs send to remote endpoint failed ", res);
        }
      })
      .catch((e) => {
        console.error("Sending logs to remote endpoint failed", {
          endpoint: process.env.REMOTE_LOG_ENDPOINT,
        });
      });
  }

  getlogger() {
    // check at runtime if local storage config is set
    if (this.isEnabled && localStorage.getItem("enable_logs_endpoint")) {
      return this.log;
    }
  }
}

const remoteEndPointLogger = new RemoteEndPointLogger();
export default remoteEndPointLogger;
