import React, {
  ReactElement,
  useRef,
  useLayoutEffect,
  useState,
  FunctionComponent,
  memo,
  useCallback,
} from "react";
import { AppViewerContainer } from "../AppViewerContainer/AppViewerContainer";
import { ChannelViewerContainer } from "../ChannelViewer/ChannelViewer";
import { detectElementSize, ElementSize } from "../../../../utils/helpers";
import styles from "./GenericViewer.module.css";
import { LinkViewerContainer } from "../LinkViewer/LinkViewer";
import { ScreenViewerContainer } from "../ScreenViewer/ScreenViewer";
import { PlaylistViewerContainer } from "../PlaylistViewer/PlaylistViewer";
import { ContentViewItem } from "../../../../types/content";
import { FileViewerContainer } from "../FileViewer/FileViewer";
import { SiteViewerContainer } from "../SiteViewer/SiteViewer";
import { AppEditorViewerContainer } from "../AppEditorViewerContainer/AppEditorViewerContainer";
import { genericViewerTestId } from "../../../../test-utils/testIds";
import isEqual from "lodash/isEqual";
import { ContentFailureGenericCb } from "../TimelineViewer/types";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { HrefViewer } from "../HrefViewer/HrefViewer";

interface Props {
  contentItem: ContentViewItem;
  itemStartTimestamp?: number;
  isPreload?: boolean;
  hasNextItem?: boolean;
  isRoot?: boolean;
  onContentFailure?: ContentFailureGenericCb;
}

interface TypedViewerElementProps {
  contentItem: ContentViewItem; // todo: any way to avoid union types and maintain manual compatibility between involved interfaces???
  isPreload: boolean;
  hasNextItem?: boolean;
  containerSize: ElementSize;
  isRoot?: boolean;
  itemStartTimestamp?: number;
  onContentFailure: ContentFailureGenericCb;
}

export const GenericViewer: FunctionComponent<Props> = memo(
  (props: Props): ReactElement<Props> => {
    const { isPreload, isRoot, onContentFailure, hasNextItem } = props;
    const elRef = useRef<HTMLImageElement>(null);
    const [size, setSize] = useState<ElementSize>({ width: 0, height: 0 });

    const onContentFailureCallback = useCallback<
      ContentFailureGenericCb
    >(() => {
      if (onContentFailure) {
        return onContentFailure();
      } else {
        return;
      }
    }, [onContentFailure]);

    useLayoutEffect(() => {
      if (!elRef.current) return;
      const newSize = detectElementSize(elRef.current);

      if (!isEqual(size, newSize)) {
        setSize(newSize);
      }
    }, [size]);

    const enableEmbedMenu = useSelector<PlayerState, boolean>(
      (state) =>
        String(state.screen.screenData?.sc_menuEnabled) === "true" ||
        !!state.config.features?.menuEnabled
    );

    if (enableEmbedMenu) {
      const htmlTags = document.getElementsByTagName("html");
      const bodyTags = document.getElementsByTagName("body");
      const rootElement = document.getElementById("root");

      if (rootElement) {
        rootElement.style.overflow = "visible";
      }

      for (const html of htmlTags) {
        html.classList.add("menuEnabled");
        html.style.overflow = "visible";
      }
      for (const body of bodyTags) {
        body.style.overflow = "visible";
      }
    }

    return (
      <div
        ref={elRef}
        className={`${styles.base} ${enableEmbedMenu ? styles.menu : ""}`}
        data-testid={genericViewerTestId(
          props.contentItem,
          props.itemStartTimestamp
        )}
      >
        <TypedViewerElement
          contentItem={props.contentItem}
          isPreload={!!isPreload}
          hasNextItem={hasNextItem}
          containerSize={size}
          isRoot={isRoot}
          itemStartTimestamp={props.itemStartTimestamp}
          onContentFailure={onContentFailureCallback}
        />
      </div>
    );
  }
);
GenericViewer.displayName = "GenericViewer";

export const TypedViewerElement: FunctionComponent<TypedViewerElementProps> = memo(
  (props: TypedViewerElementProps) => {
    if (props.contentItem.type === "void") {
      return null;
    }
    if (props.contentItem.type === "href") {
      return <HrefViewer {...props.contentItem} />;
    }

    const { id } = props.contentItem;

    // Important - Use the "key" prop to create a new component instance each time the element changes.
    // e.g. app 1 -> app 2 => 2 separate instances of the AppViewer
    switch (props.contentItem.type) {
      case "screen":
        return <ScreenViewerContainer key={id} isRoot={props.isRoot} />;
      case "channel":
        return (
          <ChannelViewerContainer id={id} key={id} isRoot={props.isRoot} />
        );
      case "playlist":
        return (
          <PlaylistViewerContainer id={id} key={id} isRoot={props.isRoot} />
        );
      case "app":
        return (
          <AppViewerContainer
            id={id}
            key={id}
            isPreload={props.isPreload}
            fullDurationMs={props.contentItem.fullDurationMs}
            isRoot={props.isRoot}
            itemStartTimestamp={props.itemStartTimestamp}
            onContentFailure={props.onContentFailure}
          />
        );
      case "editor":
        return <AppEditorViewerContainer key={id} />;
      case "link":
        return (
          <LinkViewerContainer
            key={id}
            id={id}
            containerSize={props.containerSize}
            isRoot={props.isRoot}
            isPreload={props.isPreload}
            onContentFailure={props.onContentFailure}
          />
        );
      case "file": {
        const { sizeType, fullDurationMs } = props.contentItem;
        return (
          <FileViewerContainer
            id={id}
            sizeType={sizeType}
            containerSize={props.containerSize}
            isPreload={props.isPreload}
            hasNextItem={props.hasNextItem}
            fullDurationMs={fullDurationMs}
            itemStartTimestamp={props.itemStartTimestamp}
            onContentFailure={props.onContentFailure}
          />
        );
      }
      case "site":
        return (
          <SiteViewerContainer
            id={id}
            key={id}
            isPreload={props.isPreload}
            fullDurationMs={props.contentItem.fullDurationMs}
            onContentFailure={props.onContentFailure}
          />
        );
      default:
        // TODO: A: Comeback for the playback state tuning
        // console.log("`>>>>>>> GenericViewer: NOTHING MAPPED TO SHOW");
        return null;
    }
  }
);
TypedViewerElement.displayName = "TypedViewerElement";
