import React, { useMemo } from "react";
import { VideoPlayer } from "@screencloud/shaka-player";
import {
  videoPlayerManager,
  isInitialized,
  VIDEO_MANAGER_RETRY_BACKOFF_MS,
} from "../../../../videoPlayerManager";
import { generateDomId } from "./utils";
import { SizingType } from "../../../../store/graphqlTypes";
import { Logger } from "../../../../logger/logger";
import useMountedState from "../../../../utils/useMountedState";
import { useRetryCallback } from "../../../../utils/useRetryCallback";

const log = new Logger("ShakaVideoViewer");

interface Props {
  id?: string;
  src: string;
  muted: boolean;
  controls: boolean;
  loop: boolean;
  initialPlaybackPositionMs: number;
  sizeType?: SizingType;
  isPreload: boolean;
  hasNextItem?: boolean;
  mimeType: string;
  filename?: string;
}

const ShakaVideoViewer: React.FunctionComponent<Props> = (props) => {
  const videoContainerRef = React.useRef<HTMLDivElement>(null);
  const videoElementRef = React.useRef<HTMLVideoElement>(null);
  const isMounted = useMountedState();
  const didComponentMount = isMounted();
  const [
    isVideoManagerInitialized,
    setVideoManagerInitialized,
  ] = React.useState<boolean>(false);
  const [player, setPlayer] = React.useState<VideoPlayer>();
  const {
    id,
    src,
    muted,
    controls,
    loop,
    initialPlaybackPositionMs,
    isPreload,
    sizeType,
    mimeType,
  } = props;
  const domNodeId = useMemo(() => {
    return id ? id : generateDomId();
  }, [id]);

  const startPlayVideoPromise = React.useCallback(
    async (player: VideoPlayer): Promise<boolean> => {
      return new Promise<boolean>((resolve) => {
        const playPromise = async (): Promise<void> =>
          player.play().catch(() => {
            resolve(false);
          });
        playPromise();
      });
    },
    []
  );

  const retryGetVideoManagerStatus = useRetryCallback({
    maxAttempts: 10,
    onReachMaxAttempt: () => {
      log.warn("Reach maximum retry for video manager initialization");
    },
    exponentialTimeout: false,
    retryTimeoutMs: VIDEO_MANAGER_RETRY_BACKOFF_MS,
    retryLogicCb: (isInitialized: boolean) => {
      setVideoManagerInitialized(isInitialized);
    },
  });

  React.useEffect(() => {
    if (!isVideoManagerInitialized) {
      retryGetVideoManagerStatus(isInitialized());
    }
  }, [isVideoManagerInitialized, retryGetVideoManagerStatus]);

  React.useEffect(() => {
    const videoRef = videoContainerRef && videoContainerRef.current;
    const initialVideoManager = async () => {
      try {
        if (
          isVideoManagerInitialized &&
          videoRef &&
          !videoPlayerManager.getPlayer(videoRef)
        ) {
          log.debug({ message: "Initializing player", context: { src } });
          const createdPlayer = await videoPlayerManager.addPlayerSafeAsync(
            videoRef,
            {
              src,
              muted,
              controls,
              loop,
              shallCache: true,
            }
          );
          await createdPlayer.load(initialPlaybackPositionMs, mimeType);
          setPlayer(createdPlayer);
        }
      } catch (error) {
        log.warn(`Error during initialize video manager ${error}`);
      }
    };

    initialVideoManager();

    if (videoElementRef && videoElementRef.current) {
      videoElementRef.current.onplay = () => {
        log.debug({ message: "Start playing video", context: { src } });
      };
    }

    return () => {
      setPlayer(undefined);
      if (videoRef && videoPlayerManager.getPlayer(videoRef)) {
        videoPlayerManager.removePlayer(videoRef).then(() => {
          log.debug({ message: "Destroyed player", context: { src } });
        });
      }
    };
  }, [
    controls,
    initialPlaybackPositionMs,
    loop,
    mimeType,
    muted,
    src,
    isVideoManagerInitialized,
  ]);

  React.useEffect(() => {
    if (player && !isPreload && didComponentMount) {
      const startPlayback = async () => {
        const { src } = player.getConfiguration();
        const convertPositionToSecond = initialPlaybackPositionMs / 1000;
        player.seek(convertPositionToSecond);
        try {
          await startPlayVideoPromise(player);
        } catch (error) {
          // Error code from Shaka player can be debugged using link below.
          // https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
          log.warn({
            message: "Error while playing video",
            context: {
              src,
            },
          });
        }
      };

      startPlayback();
    }
  }, [
    player,
    initialPlaybackPositionMs,
    isPreload,
    didComponentMount,
    startPlayVideoPromise,
  ]);

  return (
    <>
      <style id={`video-styles-${domNodeId}`}>
        {`
          #${domNodeId} {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            overflow: hidden;
          }

          #${domNodeId} > video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: ${sizeType === "fill" ? "cover" : "contain"};
          }
        `}
      </style>
      <div id={domNodeId} ref={videoContainerRef} data-testid="video-player">
        <video id={`video-${domNodeId}`} ref={videoElementRef} />
      </div>
    </>
  );
};

export { ShakaVideoViewer };
