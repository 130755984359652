import { getContentContext } from "./contentContext";
import { AppsState } from "../../../../store/apps/types";
import { FilesState } from "../../../../store/files/types";
import { LinksState } from "../../../../store/links/types";
import { ScreenState } from "../../../../store/screen/types";
import { OrganizationState } from "../../../../store/organization/types";
import { getStudioPlayerVersion } from "../../../../utils/helpers";
import { getSourceDetail } from "./sourceDetail";
import { usePreviousValue } from "../../../../utils/usePreviousValue";
import { SitesState } from "../../../../store/sites/types";
import { Logger, LogObjects, LogLevel } from "../../../../logger/logger";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";

export const log = new Logger("singleContentLogs");

export interface SingleContentLogsProps {
  apps: AppsState;
  files: FilesState;
  links: LinksState;
  sites: SitesState;
  screen: ScreenState;
  platform: string;
  organization: OrganizationState;
}

export const useSingleContentLogs = ({
  apps,
  files,
  links,
  sites,
  screen,
  platform,
  organization,
}: SingleContentLogsProps): void => {
  const activeContentItem = screen.activeContentItem;
  const previousItemContent = usePreviousValue(activeContentItem);

  const previousItemContentId =
    !previousItemContent || previousItemContent.type === "void"
      ? undefined
      : previousItemContent.id;
  const activeContentItemId =
    !activeContentItem || activeContentItem.type === "void"
      ? undefined
      : activeContentItem.id;
  const emptyItem = {
    zoneId: "root",
  };

  // check to not log in preview mode
  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.config.contentConfig.isPreview
  );

  if (previousItemContent !== activeContentItem) {
    // log end of previous item
    if (!previousItemContent || previousItemContent.type === "void") {
      log.info({
        message: "Finish empty screen period.",
        context: {
          ...emptyItem,
          isPreview: isPreview,
          playbackType: "single",
        },
      });
    } else if (
      previousItemContent.type !== activeContentItem?.type || // A: I'm wonder why we need to check the type?, id should be all unique?
      previousItemContentId !== activeContentItemId
    ) {
      const finishPlaybackLog: LogObjects = {
        message: "Finish playback",
        context: {
          platform,
          zoneId: "root",
          status: screen.status,
          screenId: screen.id,
          spaceId: screen.spaceId || "unavailable",
          deviceId: screen.deviceId,
          contentId: previousItemContent.id,
          contentType: previousItemContent.type,
          ...getSourceDetail("single-content", previousItemContent),
          playerVersion: getStudioPlayerVersion(),
          ...getContentContext(
            previousItemContent.id,
            previousItemContent.type,
            apps,
            files,
            links,
            sites
          ),
          screenName: screen.name,
          location: screen.screenData?.sc_address,
          orgId: organization.id,
          isPreview: isPreview,
          playbackType: "single",
        },
        proofOfPlayFlag: true,
      };
      log.debug(finishPlaybackLog);
      log.proofOfPlayLog(LogLevel.Info, finishPlaybackLog);
    }

    // log start of new item
    if (!activeContentItem) {
      log.info({
        message: "Screen is empty. No active item assigned.",
        context: {
          ...emptyItem,
          isPreview: isPreview,
          playbackType: "single",
        },
      });
    } else if (
      activeContentItem.type === "void" &&
      activeContentItem.type !== previousItemContent?.type
    ) {
      log.info({
        message: "Screen is empty. No item assigned to screen.",
        context: emptyItem,
      });
    } else if (
      previousItemContent?.type !== activeContentItem.type ||
      previousItemContentId !== activeContentItemId
    ) {
      const startPlaybackLog: LogObjects = {
        message: "Start playback",
        context: {
          platform,
          zoneId: "root",
          status: screen.status,
          screenId: screen.id,
          spaceId: screen.spaceId || "unavailable",
          deviceId: screen.deviceId,
          contentId: activeContentItemId,
          contentType: activeContentItem.type,
          ...getSourceDetail("single-content", activeContentItem),
          playerVersion: getStudioPlayerVersion(),
          ...getContentContext(
            activeContentItemId || "",
            activeContentItem.type,
            apps,
            files,
            links,
            sites
          ),
          screenName: screen.name,
          location: screen.screenData?.sc_address,
          orgId: organization.id,
          isPreview: isPreview,
          playbackType: "single",
        },
        proofOfPlayFlag: true,
      };
      log.debug(startPlaybackLog);
      log.proofOfPlayLog(LogLevel.Info, startPlaybackLog);
    } else {
      log.debug({
        message: "SINGLE-CONTENT-LOG CANNOT RESOLVE ANYTHING",
        context: {
          isPreview: isPreview,
          playbackType: "single",
        },
        proofOfPlayFlag: true,
      });
    }
  }
};
