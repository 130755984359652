import { useCallback, useState } from "react";
import { ContentFailureGenericCb } from "../TimelineViewer/types";

export type OnDocumentFailCallback = (pageIndex: number) => void;

interface FailureRecord {
  consecutiveFails: number;
  lastFailedPageIndex: number | undefined;
}

export const useDocumentFailCb = (
  maxConsecutiveFailures: number,
  reportDocumentFailureCb: ContentFailureGenericCb
): OnDocumentFailCallback => {
  const [lastFailure, setLastFailure] = useState<FailureRecord>({
    consecutiveFails: 0,
    lastFailedPageIndex: undefined,
  });

  const onFail = useCallback(
    (pageIndex: number): void => {
      const isConsecutiveFailure =
        lastFailure.lastFailedPageIndex !== undefined &&
        pageIndex - lastFailure.lastFailedPageIndex === 1;

      if (isConsecutiveFailure) {
        if (lastFailure.consecutiveFails >= maxConsecutiveFailures) {
          reportDocumentFailureCb();
        } else {
          setLastFailure({
            consecutiveFails: lastFailure.consecutiveFails + 1,
            lastFailedPageIndex: pageIndex,
          });
        }
      } else {
        setLastFailure({
          consecutiveFails: 1,
          lastFailedPageIndex: pageIndex,
        });
      }
    },
    [
      reportDocumentFailureCb,
      lastFailure.consecutiveFails,
      lastFailure.lastFailedPageIndex,
      maxConsecutiveFailures,
    ]
  );

  return onFail;
};
