import fetchIntercept from "fetch-intercept";
import { graphqlData } from "./graphql.fixture";
import { Logger } from "../logger/logger";

const log = new Logger("httpinterceptor");

function IsPerfTestEnabled(): boolean {
  const urlParams = new window.URLSearchParams(window.location.search);
  if (urlParams.has("sp_perftest")) {
    console.log("Performance test enabled");
    return true;
  }

  return false;
}

const perfCheckEnabled = IsPerfTestEnabled();

let unregister: () => void;

// This http interceptor returns a predefined graphql data for now.
export function registerHttpInterceptor(): void {
  if (!perfCheckEnabled) return;

  unregister = fetchIntercept.register({
    request: (url: string, config) => {
      log.debug({ message: `${url}`, context: { config: config } });
      return [url, config];
    },

    requestError: (error) => {
      // Called when an error occured during another 'request' interceptor call
      log.error({
        message: "Request Error",
        context: { error: JSON.stringify(error) },
      });
      return Promise.reject(error);
    },

    response: (response) => {
      if (perfCheckEnabled) {
        const newResponse = new Response(JSON.stringify(graphqlData));
        log.info({
          message: "Ignoring error and overriding response to:",
          context: {
            response: JSON.stringify(graphqlData),
          },
        });
        return newResponse;
      }

      return response;
    },

    responseError: (error) => {
      if (perfCheckEnabled) {
        const newResponse = new Response(JSON.stringify(graphqlData));
        log.debug({
          message: "Ignoring error and overriding response to:",
          context: {
            response: JSON.stringify(graphqlData),
          },
        });
        return Promise.resolve(newResponse);
      }
      return Promise.reject(error);
    },
  });
}

export function unregisterHttpInterceptor(): void {
  unregister?.();
}
