import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TIME_SYNC_INTERVAL } from "../../../../constants";
import { setConfig } from "../../../../store/config/actions";
import { useRetry } from "../../../../utils/useRetry";
import { useTimeOffset } from "./getTimeOffset";
import { Logger } from "../../../../logger/logger";

const log = new Logger("TimeSynchroniserContainer");

export interface TimeSynchroniserContainerProps {
  timeServerUrl: string;
  isEnabled?: boolean;
}
export const TimeSynchroniserContainer: FunctionComponent<TimeSynchroniserContainerProps> = ({
  timeServerUrl,
  isEnabled = true,
}: TimeSynchroniserContainerProps) => {
  const dispatch = useDispatch();
  const { hasError, getTimeOffset, timeOffset } = useTimeOffset(timeServerUrl);

  // use exponential back off if getTimeOffset fails
  useRetry(hasError, getTimeOffset);

  useEffect(() => {
    if (isEnabled) {
      getTimeOffset();
    }
  }, [getTimeOffset, isEnabled]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isEnabled) {
      intervalId = setInterval(() => {
        getTimeOffset();
      }, TIME_SYNC_INTERVAL);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isEnabled, getTimeOffset]);

  useEffect(() => {
    if (isEnabled && timeOffset !== undefined) {
      log.info(`Setting new time offset value: ${timeOffset}ms.`);
      dispatch(setConfig({ timeOffset }));
    } else if (!isEnabled) {
      log.info(`Setting new time offset value: 0ms.`);
      dispatch(setConfig({ timeOffset: 0 }));
    }
  }, [dispatch, timeOffset, isEnabled]);

  return <></>;
};
