import LokiTransport from "sc-winston-loki-beta";
import { createLogger } from "winston";
import { Maybe } from "../queries";
import { actualUtcNow, TimeOptions } from "../utils/timeManager";
import { LogLevel, LogOutput } from "./logger";
import { Context } from "@datadog/browser-core";
import { captureException } from "@sentry/minimal";
import { FEATURE_FLAGS_ENUM } from "../featureFlags";

const MAX_QUEUES_LOG = parseInt(
  process.env.REACT_APP_EVENT_QUEUES_LIMIT || "100"
);

const ProofOfPlayLogger = createLogger();

interface Event {
  level: LogLevel;
  message: string;
  context?: Context;
  isAllowToLog?: boolean;
}

type EventQueues = Event[];

export interface GetLogger {
  debug: (message: string, context?: Context) => void;
  info: (message: string, context?: Context) => void;
  warn: (message: string, context?: Context) => void;
  error: (message: string, context?: Context) => void;
}

export interface RemoteLoggerConfig {
  clientToken: string;
  version: string;
  env: string;
  proxyHost?: string;
  platform: string;
  deviceId: string;
  screenId: string;
  orgId: string;
  timeOptions: TimeOptions;
  graphqlToken: string;
  proofOfPlayEndpoint: string;
}

class ProofOfPlay {
  public isInitialized = false;
  private isEnabled = false;
  private timeOptions: TimeOptions = {
    timeOffset: 0,
    timelineControlOffset: 0,
  };

  // using for store the events that are called before initialized
  private eventQueues: EventQueues = [];

  private shouldEnableProofOfPlayLogger = (
    featureFlags: Maybe<string>[]
  ): boolean => {
    return featureFlags.includes(FEATURE_FLAGS_ENUM.PLAYBACK_LOGS);
  };

  public init = (
    config: RemoteLoggerConfig,
    featureFlags: Maybe<string>[]
  ): void => {
    // ProofOfPlay initialization

    this.isEnabled = this.shouldEnableProofOfPlayLogger(featureFlags);

    if (this.isInitialized) return;
    try {
      ProofOfPlayLogger.add(
        new LokiTransport({
          host: config.proofOfPlayEndpoint,
          json: true,
          clearOnError: true,
          timeout: 5000,
          labels: {
            job: "studio-player",
            version: config.version,
          },
          headers: {
            "X-Scope-OrgID": config.orgId ? config.orgId : "default",
            Authorization: `bearer ${config.graphqlToken}`,
          },
        })
      );
    } catch (error) {
      captureException(error);
      console.warn("ProofOfPlay Initialization Failed");
      return;
    }

    ProofOfPlayLogger.defaultMeta = {
      platform: config.platform,
      deviceId: config.deviceId,
      screenId: config.screenId,
      orgId: config.orgId,
    };

    this.timeOptions = config.timeOptions;
    this.isInitialized = true;

    this.flushEventQueues();
  };

  private log = (
    level: LogLevel,
    message: string,
    context?: Context,
    isAllowToLog?: boolean
  ): void => {
    /**
     * handle when events are sent before initialized
     * need to add an event to the event queues to make it be able to sync later
     */
    if (!this.isInitialized) {
      this.addEventToQueues({
        level,
        message,
        context,
      });
      return;
    }

    this.eventLogger(
      level,
      message,
      {
        ...context,
        timestamp: actualUtcNow(this.timeOptions),
      },
      isAllowToLog
    );
  };

  private addEventToQueues = ({ level, message, context }: Event): void => {
    // need to have a limitation of event stack because it could effect a memory leak
    if (this.eventQueues.length > MAX_QUEUES_LOG) {
      this.eventQueues.shift();
    }

    this.eventQueues.push({
      level,
      message,
      context: {
        ...context,
        timestamp: actualUtcNow(this.timeOptions),
      },
    });
  };

  private flushEventQueues = (): void => {
    /**
     * Even if loki events are added in initial state of logger if isLokiLoggingInitialized is not true
     * loki events are simply ignored in eventLogger method. The reason why is when we are initializing EventQueue
     * we do not have access to FeatureFlags yet, so we have to add all events and handle this edge case
     * in eventLogger because by then we would know the status of "isLokiLoggingInitialized" flag
     * */
    this.eventQueues
      .filter((e) => e.isAllowToLog)
      .forEach((event) => {
        this.eventLogger(
          event.level,
          event.message,
          event.context,
          event?.isAllowToLog
        );
      });
    this.clearEventQueues();
  };

  private clearEventQueues = (): void => {
    this.eventQueues = [];
  };

  private eventLogger = (
    level: LogLevel,
    message: string,
    context?: Context,
    isAllowToLog?: boolean
  ): void => {
    if (!this.isInitialized || !this.isEnabled || !isAllowToLog) {
      return;
    }
    const consumer = "studio";
    switch (level) {
      case LogLevel.Debug:
        this.isEnabled &&
          ProofOfPlayLogger.debug({
            consumer,
            type: "debug",
            message,
            ...context,
          });
        break;
      case LogLevel.Info:
        this.isEnabled &&
          ProofOfPlayLogger.info({
            consumer,
            type: "info",
            message,
            ...context,
          });
        break;
      case LogLevel.Warning:
        this.isEnabled &&
          ProofOfPlayLogger.warn({
            consumer,
            type: "warn",
            message,
            ...context,
          });
        break;
      case LogLevel.Error:
        // case error always send data to proofOfPlay for debugging
        ProofOfPlayLogger.error({
          consumer,
          type: "error",
          message,
          ...context,
        });
        break;
    }
  };

  public getEventQueues = (): EventQueues => {
    return this.eventQueues;
  };

  public getLogger = (): LogOutput => {
    return this.log;
  };
}

const proofOfPlay = new ProofOfPlay();
export default proofOfPlay;
