import React, { FunctionComponent } from "react";
import styles from "./NavBar.module.css";
import { useEmbedMenu } from "../../../../../providers/MenuProvider/MenuProvider";
import Search from "../Search";
import ArrowBackIcon from "../../../../../img/left-arrow.svg";
import { MenuViews } from "../../../../../providers/MenuProvider/types";

interface Props {
  search: string;
  setSearch: (search: string) => void;
}

const NavBar: FunctionComponent<Props> = ({ search, setSearch }: Props) => {
  const {
    channelName,
    setPlaylist,
    selectedPlaylist,
    view,
    returnToLiveMode,
  } = useEmbedMenu();
  const isPlaylistView = view === MenuViews.GRID;

  return (
    <div className={styles.navbar}>
      {isPlaylistView ? (
        <div
          className={styles.back}
          onClick={() => {
            setSearch("");
            setPlaylist("");
          }}
        >
          <img
            src={ArrowBackIcon}
            className={styles.icon}
            alt="Playlist Images"
          />
          <span>{selectedPlaylist?.name || "All"}</span>
          <button
            className={styles.playAllButton}
            onClick={() => {
              returnToLiveMode();
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Play All
          </button>
        </div>
      ) : (
        <div className={styles.channelName}>{channelName}</div>
      )}
      <Search search={search} setSearch={setSearch} />
    </div>
  );
};

export default NavBar;
