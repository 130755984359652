import { REQUEST_LINK_SUCCESS, RequestLinkSuccessAction } from "./types";
import { normalize } from "normalizr";
import { LinkFragment } from "../../queries";
import { linkNormalization, NormalizedLinkEntities } from "./normalization";

export const requestLinkSuccess = (
  link: LinkFragment
): RequestLinkSuccessAction => {
  const normalizedData = normalize<unknown, NormalizedLinkEntities>(
    link,
    linkNormalization.schema
  );

  return {
    type: REQUEST_LINK_SUCCESS,
    payload: {
      links: normalizedData.entities.links || {},
      files: normalizedData.entities.files || {},
    },
  };
};
