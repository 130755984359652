import { PlaylistAction } from "./playlists/types";
import { ConfigAction } from "./config/types";
import { PlaybackAction } from "./playback/types";
import { ScreenAction } from "./screen/types";
import { AppAction } from "./apps/types";
import { TimelineAction } from "./timelines/types";
import { ChannelAction } from "./channels/types";
import { RehydrateErrorType, PersistState } from "redux-persist";
import { LiveUpdateAction } from "./liveUpdates/types";
import { FileAction } from "./files/types";
import { SpaceAction } from "./spaces/types";
import { LayoutAction } from "./layouts/types";
import { LinkAction } from "./links/types";
import { UtilAction } from "./util/actions";
import { PlayerState } from "./rootReducer";
import { CleanupAction } from "./cleanup/types";
import { schema } from "normalizr";

// Union type to cover all possible actions.
// This lets TS intelligently auto-complete payload types in reducers.
export type PlayerAction =
  | ConfigAction
  | PlaybackAction
  | ScreenAction
  | AppAction
  | TimelineAction
  | UnusedAction
  | ChannelAction
  | PlaylistAction
  | RehydrateAction
  | LiveUpdateAction
  | FileAction
  | SpaceAction
  | LayoutAction
  | LinkAction
  | UtilAction
  | CleanupAction;

// Unused action. This is for specs which want to validate what happens when
// a reducer doesn't respond to an action.
export const UNUSED_ACTION = "UNUSED_ACTION";

export interface UnusedAction {
  type: typeof UNUSED_ACTION;
}

export function unusedAction(): UnusedAction {
  return {
    type: UNUSED_ACTION,
  };
}

// Utility to modify interfaces
// https://stackoverflow.com/questions/41285211/overriding-interface-property-type-defined-in-typescript-d-ts-file
export type Modify<T, R> = Omit<T, keyof R> & R;

// Copied from https://github.com/rt2zz/redux-persist/blob/master/types/types.d.ts#L104
export interface RehydrateAction {
  type: "persist/REHYDRATE";
  key: string;
  payload?: Partial<PlayerState> | null; // could be null if there is an error
  err?: RehydrateErrorType | null;
}

// mimic the interface from redux-persist package
export interface PersistPartial {
  _persist: PersistState;
}

/**
 * Collection of ids by entity type.
 * Is used to represent nested entities traversal result for a given entity.
 */
export interface TraversalResult {
  channels: Set<string>;
  playlists: Set<string>;
  sites: Set<string>;
  links: Set<string>;
  layouts: Set<string>;
  apps: Set<string>;
  files: Set<string>;
  spaces: Set<string>;
  themes: Set<string>;
  contentLists: Set<string>;
}

export interface NormalizationUtilities {
  // data normalization schema
  schema: schema.Entity;

  // Normalized state traversal function that returns sets of ids of all nested entities related to a given entity.
  //  Is used to track all related entities in a normalized state.
  traverseFunction: (
    targetEntityId: string,
    state: PlayerState
  ) => TraversalResult;
}
