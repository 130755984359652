import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import isEmpty from "lodash/isEmpty";

import styles from "./QrCodeViewer.module.css";
import { Scalars } from "../../../../queries";
import {
  generateQR,
  getQrcodeStyleProperty,
  useGetQrCodeUrl,
  QRcodePosition,
} from "../../../../utils/qrCode";
import { Logger } from "../../../../logger/logger";
import {
  STUDIO_VARIABLES,
  useGetStudioVariables,
} from "../../../../utils/useGetStudioVariables";

const log = new Logger("qrcode");

interface QrCodeViewerProps {
  qrcodeConfig?: Scalars["JSON"] | null;
  qrcode?: string; // only for test propose
}

export const QrCodeViewer: FunctionComponent<QrCodeViewerProps> = (
  props: QrCodeViewerProps
) => {
  const { qrcodeConfig } = props;
  const { target_url, client_id, context, persistent } = qrcodeConfig.payload;
  const position = qrcodeConfig.prop?.position ?? QRcodePosition.BOTTOM_RIGHT;

  const getQrClassName = getQrcodeStyleProperty(position);

  /**
   * each current play item (source_id) could be an nested item in a parent
   * eg. a cat image in Channel / Playlist.
   * So to identify that the current playing item is got parent or not here is when it store data in the context.
   */
  const channelParentItem = useGetStudioVariables().getValues([
    STUDIO_VARIABLES._SC_CHANNEL_ID,
  ])[0][STUDIO_VARIABLES._SC_CHANNEL_ID];
  const playlistParentItem = useGetStudioVariables().getValues([
    STUDIO_VARIABLES._SC_PLAYLIST_ID,
  ])[0][STUDIO_VARIABLES._SC_PLAYLIST_ID];

  const resolveTargetURL = useGetStudioVariables().resolveStudioVariablesInTargetURL(
    target_url
  );
  const parentItemType = useMemo(() => {
    if (channelParentItem) {
      return "channel";
    } else if (playlistParentItem) {
      return "playlist";
    } else {
      return "";
    }
  }, [channelParentItem, playlistParentItem]);

  const newContext = useGetStudioVariables().resolve(context ?? {});
  if (!isEmpty(newContext)) {
    newContext.parent_source_type = parentItemType;
  }

  const [qrcode, setQrcode] = useState<string>(props.qrcode ?? "");

  const qrcodeData = {
    targetUrl: resolveTargetURL,
    clientId: client_id,
    context: newContext,
    persistent: persistent || false,
  };

  const qrUrl = useGetQrCodeUrl(qrcodeData);
  const registerQrcode = useCallback(async () => {
    try {
      if (qrUrl) {
        const qrcode = await generateQR(qrUrl);
        setQrcode(qrcode);
      }
    } catch (error) {
      setQrcode("");
      log.error({
        message: "unable to register qrcode:",
        context: {
          error: JSON.stringify(error),
        },
      });
    }
  }, [qrUrl]);

  useEffect(() => {
    registerQrcode();
  }, [registerQrcode]);

  return (
    <div
      className={styles.qrcode}
      style={{ ...getQrClassName }}
      data-testid="qrcode-viewer"
    >
      {qrcode ? (
        <img alt="qrcode" src={qrcode} data-testid="qrcode-image" />
      ) : null}
    </div>
  );
};
