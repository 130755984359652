import React, {
  ReactElement,
  memo,
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
} from "react";
import { DocumentFile, ImageFile } from "../../../../store/files/types";
import { ElementSize } from "../../../../utils/helpers";
import { ImageViewer } from "../ImageViewer/ImageViewer";
import { ContentSizeType } from "../../../../types/content";
import { DEFAULT_DOCUMENT_PAGE_DURATION } from "../../../../constants";
import {
  TimeOptions,
  playbackNowTimestamp,
} from "../../../../utils/timeManager";
import { useTimeOptions } from "../../../../utils/useTimeOptions";
import { Logger } from "../../../../logger/logger";
import { useDocumentFailCb } from "./useDocumentFailCb";
import { ContentFailureGenericCb } from "../TimelineViewer/types";
const log = new Logger("DocumentViewer");

interface DocumentContainerProps {
  file: DocumentFile;
  src: string[];
  sizeType?: ContentSizeType;
  containerSize: ElementSize;
  durationMs: number;
  itemStartTimestamp: number;
  isPreload: boolean;
  onContentFailure: ContentFailureGenericCb;
}

export interface DocumentViewerProps {
  sizeType?: ContentSizeType;
  containerSize: ElementSize;
  src: string[];
  imageFiles: ImageFile[];
  durationMs: number;
  itemStartTimestamp: number;
  timeOptions: TimeOptions;
  isPreload: boolean;
  onContentFailure: ContentFailureGenericCb;
}

function getNextPageIndex(
  pagesAmount: number,
  activePageIndex: number
): number | undefined {
  const result = activePageIndex >= pagesAmount - 1 ? 0 : activePageIndex + 1;
  if (result === activePageIndex) {
    return undefined;
  }
  return result;
}

export const DocumentContainer: FunctionComponent<DocumentContainerProps> = (
  props: DocumentContainerProps
): ReactElement<DocumentContainerProps> | null => {
  // todo: there is not much sense in this container. Remove it completely and render the viewer directly from <FileViewer />

  const file = props.file;
  const timeOptions = useTimeOptions();

  const imageFiles: ImageFile[] = (file as DocumentFile).images || [];

  if (!imageFiles || imageFiles.length === 0) {
    return <p>Sorry, we can&apos;t find the image url you are looking for.</p>;
  }

  return (
    <DocumentViewer
      src={props.src}
      imageFiles={imageFiles}
      key={file.id}
      sizeType={props.sizeType}
      containerSize={props.containerSize}
      durationMs={props.durationMs}
      itemStartTimestamp={props.itemStartTimestamp}
      timeOptions={timeOptions}
      isPreload={props.isPreload}
      onContentFailure={props.onContentFailure}
    />
  );
};

export const DocumentViewer = memo(
  (props: DocumentViewerProps): ReactElement<DocumentViewerProps> => {
    const {
      src,
      imageFiles,
      durationMs,
      timeOptions,
      isPreload,
      itemStartTimestamp,
    } = props;
    const itemStartTimestampValue =
      itemStartTimestamp !== Infinity && itemStartTimestamp !== -Infinity
        ? itemStartTimestamp
        : 0;

    const pageDuration =
      durationMs === Infinity
        ? DEFAULT_DOCUMENT_PAGE_DURATION
        : Math.round(durationMs / imageFiles.length);

    const getCurrentIndex = useCallback<() => number>(() => {
      const now = playbackNowTimestamp(timeOptions);
      if (isPreload || now - itemStartTimestamp < 0) {
        return 0;
      }

      const value = Math.floor(
        ((now - itemStartTimestampValue) / pageDuration) % imageFiles.length
      );
      return value;
    }, [
      timeOptions,
      isPreload,
      itemStartTimestamp,
      itemStartTimestampValue,
      pageDuration,
      imageFiles.length,
    ]);
    const [currentIndex, setCurrentIndex] = useState(getCurrentIndex());
    const preloadPageIndex = getNextPageIndex(imageFiles.length, currentIndex);

    const getPageEnd = useCallback<() => number>(() => {
      const now = playbackNowTimestamp(timeOptions);
      if (now < itemStartTimestampValue) {
        return itemStartTimestampValue;
      }
      return (
        itemStartTimestampValue +
        Math.ceil((now - itemStartTimestampValue) / pageDuration) * pageDuration
      );
    }, [timeOptions, itemStartTimestampValue, pageDuration]);

    const [pageEndTimestamp, setPageEndTimestamp] = useState(getPageEnd());

    const onFail = useDocumentFailCb(3, props.onContentFailure);

    const onFailCurrent = useCallback(() => {
      return onFail(currentIndex);
    }, [currentIndex, onFail]);

    const onFailPreload = useCallback(() => {
      if (preloadPageIndex !== undefined) {
        return onFail(preloadPageIndex);
      }
    }, [preloadPageIndex, onFail]);

    useEffect(() => {
      setCurrentIndex(getCurrentIndex());
    }, [getCurrentIndex]);

    useEffect(() => {
      setPageEndTimestamp(getPageEnd());
    }, [getPageEnd]);

    useEffect(() => {
      // 10ms is added to make sure time passes over the next page start time
      let pageTimeoutMs =
        pageEndTimestamp - playbackNowTimestamp(timeOptions) + 10;

      if (pageTimeoutMs < 0) {
        if (pageTimeoutMs < -500) {
          log.warn(
            `Playback drift: Document page duration timeout is less than 0 (${pageTimeoutMs}ms.`
          );
        }
        pageTimeoutMs = 0;
      }

      const pageDurationTimeout = window.setTimeout(() => {
        const newIndex = getCurrentIndex();
        const newPageEnd = getPageEnd();

        if (newPageEnd === pageEndTimestamp && !isPreload) {
          log.warn(
            `Document viewer timing error. Page end time has not changed.`
          );
        }

        setCurrentIndex(newIndex);
        setPageEndTimestamp(newPageEnd);
      }, pageTimeoutMs);

      return (): void => {
        window.clearTimeout(pageDurationTimeout);
      };
    }, [
      pageEndTimestamp,
      timeOptions,
      getPageEnd,
      getCurrentIndex,
      currentIndex,
      isPreload,
    ]);

    return (
      <>
        {imageFiles[currentIndex] ? (
          <ImageViewer
            src={src[currentIndex]}
            name={imageFiles[currentIndex].name}
            urlKey={imageFiles[currentIndex].urlKey}
            mimetype={imageFiles[currentIndex].mimetype}
            sizeType={props.sizeType}
            containerSize={props.containerSize}
            isPreload={isPreload}
            onContentFailure={onFailCurrent}
          />
        ) : null}
        {preloadPageIndex !== undefined && imageFiles[preloadPageIndex] ? (
          <ImageViewer
            src={src[currentIndex]}
            name={imageFiles[preloadPageIndex].name}
            urlKey={imageFiles[preloadPageIndex].urlKey}
            mimetype={imageFiles[preloadPageIndex].mimetype}
            sizeType={props.sizeType}
            containerSize={props.containerSize}
            isPreload={true}
            onContentFailure={onFailPreload}
          />
        ) : null}
      </>
    );
  }
);
DocumentViewer.displayName = "DocumentViewer";
