import { PlayerManager } from "@screencloud/shaka-player";

let _videoPlayerManager: PlayerManager;
let _isInitialized = false;
const VIDEO_MANAGER_RETRY_BACKOFF_MS = 300;

declare global {
  interface Window {
    persie: {
      playerManager?: PlayerManager;
    };
  }
}

const getInstance = (): PlayerManager => {
  if (!_videoPlayerManager) {
    _videoPlayerManager = new PlayerManager({
      installPolyfill: false,
    });
    // This is needed to be able to debug video player
    window.persie = {
      playerManager: _videoPlayerManager,
    };
  }
  return _videoPlayerManager;
};

const initializeVideoPlayer = async (): Promise<void> => {
  try {
    await _videoPlayerManager.init();
    if (_videoPlayerManager.initializing) {
      await _videoPlayerManager.initializing;
      _isInitialized = true;
    }
  } catch (err) {
    throw err;
  }
};

const isInitialized = (): boolean => _isInitialized;

const videoPlayerManager = getInstance();
export {
  videoPlayerManager,
  initializeVideoPlayer,
  isInitialized,
  VIDEO_MANAGER_RETRY_BACKOFF_MS,
};
