import { HOUR_DURATION_MS } from "../constants";
import { TokenType } from "../types/appToken";
export const VIEWER_TOKEN_REFRESH_PERIOD = HOUR_DURATION_MS * 6; // 6 hours

// Check if last successful fetch of appViewerToken
// has been more than VIEWER_TOKEN_REFRESH_PERIOD
export const shouldRefetchAppViewerToken = (
  lastAppViewerTokenSuccess: number
): boolean => {
  if (lastAppViewerTokenSuccess + VIEWER_TOKEN_REFRESH_PERIOD < Date.now()) {
    return true;
  }
  return false;
};

export const createAppTokenQuery = (
  scope: "viewer" | "management",
  spaceId: string | undefined,
  screenId: string | undefined
) => {
  return {
    useCache: false,
    skipCache: true,
    variables: {
      input:
        scope === "viewer"
          ? {
              spaceId: spaceId || "",
              screenId: screenId || "",
            }
          : {
              spaceId: spaceId || "",
            },
    },
  };
};

export const checkTokenErrors = (
  token: string | null | undefined,
  tokenType: TokenType
): string | undefined => {
  let errorMessage = undefined;
  if (token === undefined) {
    errorMessage = `token is undefined - token type: ${tokenType}`;
  } else if (token === null) {
    errorMessage = `token is null - token type: ${tokenType}`;
  } else if (token.length === 0 || (token.match(/\./g) || []).length !== 2) {
    // token should be in 3 parts period separated and not an empty string
    errorMessage = `token is not in the correct format - token type: ${tokenType}`;
  }
  return errorMessage;
};
