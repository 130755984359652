import { ContentItemLeaf } from "../../store/contentLists/types";
import { BreakpointWithItems } from "../../store/playback/types";
import {
  TimelineContinuousStartItem,
  TimelineItem,
  TimelineItemContent,
} from "../../store/timelines/types";
import { isTimelineItemVoid } from "../../utils/contentItemTypeGuards";

/**
 * Produces timeline item, makes sure all the necessary props are applied.
 * This method should be used any time you need to produce a timeline item, don't construct timeline items manually.
 */
export function produceTimelineItem(
  contentListItem: ContentItemLeaf,
  startTimestampMs: number,
  isInfinite: boolean,
  showDurationMs: number,
  breakpointId: number
): TimelineItemContent {
  const isFullscreen = contentListItem.rules?.some((rule) => rule.full_screen);
  const isPrimaryAudio = contentListItem.rules?.some(
    (rule) => rule.primary_audio
  );

  return {
    id: contentListItem.id,
    type: contentListItem.type,
    preloadDurationMs: contentListItem.preloadDurationMs,
    fullDurationMs: contentListItem.durationMs,
    listId: contentListItem.listId,
    transition: contentListItem.transition,
    sizeType: contentListItem.sizeType,
    isFullscreen,
    isPrimaryAudio,
    startTimestampMs,
    isInfinite,
    showDurationMs,
    breakpointId,
  };
}

export const getTotalLoopItems = (
  isFullLoopTimelineItemsRequireMerge: boolean,
  bpFullDuration: number,
  startSegmentDurationMs: number,
  validItemsTotalDurationMs: number,
  totalItems: number
): number => {
  const bpFulldurationAfterStartSegment =
    bpFullDuration - startSegmentDurationMs;
  const maxFullLoopRepeat =
    bpFulldurationAfterStartSegment > 0
      ? Math.floor(bpFulldurationAfterStartSegment / validItemsTotalDurationMs)
      : 0;

  // the full loop that have start and end with the same content, require merge
  // equation to get the generated loop item count with merge tail+head during join
  // so the equation is (loopItemsCount * n) - (n - 1)
  // Ex. [1,2,3,1,1,2,3,1,1,2,3,1] => [1,2,3,1,2,3,1,2,3,1]
  // maxFullLoopRepeat = 3, validItemCount = 4
  return isFullLoopTimelineItemsRequireMerge && maxFullLoopRepeat > 0
    ? maxFullLoopRepeat * totalItems - (maxFullLoopRepeat - 1)
    : maxFullLoopRepeat * totalItems;
};

/** To join two timeline together with automatic merge duplicate item, or both are void */
export function joinTimeline(
  first: TimelineItem[] = [],
  second: TimelineItem[] = []
): TimelineItem[] {
  const lastItemInFirst = first[first.length - 1];
  const firstItemInSecond = second[0];
  if (
    lastItemInFirst &&
    firstItemInSecond &&
    ((!isTimelineItemVoid(lastItemInFirst) &&
      !isTimelineItemVoid(firstItemInSecond) &&
      lastItemInFirst.id === firstItemInSecond.id) ||
      (isTimelineItemVoid(lastItemInFirst) &&
        isTimelineItemVoid(firstItemInSecond)))
  ) {
    // update last show item duration to cover the one that duplicate and will be removed
    // bear in mind that "showDurationMs" can greater than original item's "fullDurationMs" for this case
    lastItemInFirst.showDurationMs =
      lastItemInFirst.showDurationMs + firstItemInSecond.showDurationMs;
    // remove the duplicate one
    second.splice(0, 1);
  }
  first.push(...second);
  return first;
}

export const isFirstAndLastItemDuplicate = (items: TimelineItem[]) => {
  if (items.length >= 2) {
    const firstItem = items[0];
    const lastItem = items[items.length - 1];
    // Check is the timeline loop items contain duplicate item for the first and last item
    if (firstItem.type === "void" && lastItem.type === "void") {
      return true;
    } else if (
      !isTimelineItemVoid(firstItem) &&
      !isTimelineItemVoid(lastItem) &&
      firstItem.id === lastItem.id
    ) {
      return true;
    }
  }
  return false;
};

/** Return the start playback item index with offset from the recent played item */
export const getBreakpointStartItem = (
  currentBpWithItems: BreakpointWithItems,
  recentItem: TimelineItem
): TimelineContinuousStartItem => {
  const currentBpTotalItemsCount = currentBpWithItems.items.length;
  // default to first item with 0 and 0 offset = no start offset play required
  let startItemIdx = -1;
  let startTimeOffsetMs = 0;

  for (let idx = 0; idx < currentBpTotalItemsCount; idx++) {
    // search for recent played item
    if (
      currentBpWithItems.items[idx].id &&
      currentBpWithItems.items[idx].id === recentItem.id
    ) {
      if (recentItem.showDurationMs < recentItem.fullDurationMs) {
        // it has been play some part of the duration
        startItemIdx = idx;
        startTimeOffsetMs += recentItem.showDurationMs;
      } else {
        // it has already played to the full duration for the last play item
        // so find the proper next item idx to continue play
        if (idx < currentBpTotalItemsCount - 1) {
          // include the found item's duration to the offset to start show with next item
          startTimeOffsetMs += currentBpWithItems.items[idx].durationMs;
          startItemIdx = idx + 1;
        } else {
          // next item is the first item so reset everything to 0
          startItemIdx = 0;
          startTimeOffsetMs = 0;
        }
      }
      break;
    }
    // not found case, keep looking forward
    startTimeOffsetMs += currentBpWithItems.items[idx].durationMs;
  }

  // if not found item then reset all to 0
  if (startItemIdx === -1) {
    startTimeOffsetMs = 0;
    startItemIdx = 0;
  }

  return { startItemIdx, startTimeOffsetMs };
};

/** Convert the list of contentItem leaf to timelineItem */
export const contentListToTimelineItems = (
  items: ContentItemLeaf[],
  breakpointId: number,
  autoMergDuplication = true
): TimelineItem[] => {
  const totalItems = items.length;
  const timelineItems: TimelineItem[] = new Array(totalItems);
  let lastItem: TimelineItemContent | undefined;

  for (let k = 0; k < totalItems; k++) {
    const item = items[k];
    if (lastItem && lastItem.id === item.id && autoMergDuplication) {
      lastItem.fullDurationMs += item.durationMs;
    } else {
      const startTimestampMs = -1; // default to -1 and will be calculate and update later
      const isInfinite = false;
      const showDurationMs = -1, // default to -1 and will be calculate and update later
        timelineItem = produceTimelineItem(
          item,
          startTimestampMs,
          isInfinite,
          showDurationMs,
          breakpointId
        );

      timelineItems[k] = timelineItem;
      lastItem = timelineItem;
    }
  }
  return timelineItems;
};
