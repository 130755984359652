import { APIError } from "graphql-hooks";
import { GraphQLError, TokenRetryError } from "../types/appToken";

/**
 * Converts the GraphQL Error object to a more meaningful one for sending to datadog/sentry
 * @param error - the error coming from the GraphQL client
 * @param retryCount - the current attempt
 */
export const generateAppTokenError = (
  error: APIError<unknown>,
  retryCount: number
): TokenRetryError => {
  const { fetchError, httpError, graphQLErrors } = error;

  let err: TokenRetryError = {
    source: "unknown",
    message: "unknown error",
    retryCount,
  };

  if (fetchError) {
    err = {
      source: "fetch",
      message: fetchError.message,
      retryCount,
    };
    if (fetchError.message === "Failed to fetch" && fetchError.stack) {
      // Crop the stack message after new line of failed to fetch
      const errorIndex =
        fetchError.stack.indexOf("\n") > -1
          ? fetchError.stack.indexOf("\n")
          : fetchError.stack.lastIndexOf("Failed to fetch");
      const stackMessage = fetchError.stack.substring(0, errorIndex);
      err = {
        ...err,
        stackMessage: stackMessage,
      };
    }
  } else if (httpError) {
    let errorMessage;
    try {
      errorMessage =
        httpError.body &&
        JSON.parse(httpError.body) &&
        JSON.parse(httpError.body).message;
    } catch (err) {
      console.log(`Unable to parse httpError.body`);
    }

    err = {
      source: "http",
      message: httpError.statusText || errorMessage,
      status: httpError.status,
      retryCount,
    };
  } else if (graphQLErrors) {
    if (graphQLErrors.length > 0) {
      // we are only interested in the first error
      const gqlErr = graphQLErrors[0] as GraphQLError;
      err = {
        source: "graphql",
        message: gqlErr.message,
        retryCount,
      };
    }
  }
  return err;
};
