import { Dispatch, useEffect, useState } from "react";
import { TimelineItem } from "../../../../../store/timelines/types";
import {
  playbackNowTimestamp,
  TimeOptions,
} from "../../../../../utils/timeManager";
import { PlayerAction } from "../../../../../store/storeTypes";
import { setActiveItemTransitioningOut } from "../../../../../store/playback/actions";

/**
 * Take care of setting a flag that indicates if an active item should start its exit transition
 */
export function useSetIsActiveItemTransitioningOut(
  timelineId: string,
  activeItem: TimelineItem | undefined,
  nextItemStartTimestamp: number | undefined,
  timeOptions: TimeOptions,
  dispatch: Dispatch<PlayerAction>
): void {
  const [isItemTransitioningOut, setIsItemTransitioningOut] = useState(false);

  useEffect(() => {
    if (activeItem && typeof nextItemStartTimestamp === "number") {
      const itemTransitionDuration: number =
        activeItem.transition?.duration || 0;
      const timeUntilTransitionOutStart = getTimeUntilTransitionOutStart(
        timeOptions,
        nextItemStartTimestamp,
        itemTransitionDuration
      );

      if (timeUntilTransitionOutStart !== null) {
        if (timeUntilTransitionOutStart <= 0) {
          setIsItemTransitioningOut(true);
        } else {
          setIsItemTransitioningOut(false);

          const timeout = window.setTimeout(() => {
            setIsItemTransitioningOut(true);
          }, timeUntilTransitionOutStart);

          return () => {
            window.clearTimeout(timeout);
          };
        }
      } else {
        setIsItemTransitioningOut(false);
      }
    } else {
      setIsItemTransitioningOut(false);
    }
  }, [activeItem, nextItemStartTimestamp, timeOptions]);

  useEffect(() => {
    dispatch(setActiveItemTransitioningOut(isItemTransitioningOut, timelineId));
  }, [isItemTransitioningOut, timelineId, dispatch]);
}

function getTimeUntilItemEnd(
  timeOptions: TimeOptions,
  nextItemStartTimestamp: number
): number {
  const now = playbackNowTimestamp(timeOptions);

  return nextItemStartTimestamp - now >= 0 ? nextItemStartTimestamp - now : 0;
}

function getTimeUntilTransitionOutStart(
  timeOptions: TimeOptions,
  nextItemStartTimestamp: number,
  transitionDuration: number
): number | null {
  if (transitionDuration <= 0) {
    return null;
  }

  const timeUntilItemEnd = getTimeUntilItemEnd(
    timeOptions,
    nextItemStartTimestamp
  );

  if (timeUntilItemEnd <= 0) {
    return null;
  }

  const result = timeUntilItemEnd - transitionDuration;

  return result <= 0 ? 0 : result;
}
