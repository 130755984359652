import {
  APIError,
  FetchData,
  UseClientRequestOptions,
  UseClientRequestResult,
} from "graphql-hooks";
import { useEffect, useRef, useState } from "react";
import {
  CreateAppManagementTokenMutation,
  CreateAppManagementTokenMutationVariables,
  CreateAppViewerTokenMutation,
  CreateAppViewerTokenMutationVariables,
} from "../queries/types";
import {
  RetryOptions,
  TokenRetryError,
  TokenRetryResponse,
  TokenType,
} from "../types/appToken";
import { generateAppTokenError } from "./generateAppTokenError";
import { getErrorStatusCode } from "./getErrorStatusCode";
import { Logger } from "../logger/logger";
import { useSelector } from "react-redux";
import { PlayerState } from "../store/rootReducer";
import { MINUTE_DURATION_MS } from "../constants";
import {
  shouldRefetchAppViewerToken,
  createAppTokenQuery,
  checkTokenErrors,
} from "./appTokenHelper";

const VIEWER_TOKEN_RETRY_BASE_TIMEOUT = 1000; // 1000 ms
const MAX_BACKOFF_PERIOD = 1000 * 60 * 30; // 30 mins
const HTTP_UNAUTHORISED = 401;
export const TOKEN_REFRESH_INTERVAL = MINUTE_DURATION_MS * 5; // 5 mins

const log = new Logger("useGetAppTokenWithRetry");

/**
 * This hook allows you to request app viewer and management tokens with retry
 * Should be used to get initial token and set 5 min check for refresh
 * Will exponentially backoff from the retryTimeoutMs to the maxRetryTimeoutMs
 * @param scope - whether you are requesting viewer or management token
 * @param spaceId
 * @param screenId
 * @param useCreateTokenQuery - graphql-hooks query the gets the token
 * @param successCallback - function to call when token is received
 * @param failureCallback - function to call when failure retrieving token
 * @param retryExhaustionCallback - function to call if max retries reached
 * @param options - object of RetryOptions
 */
export const useGetAppTokenWithRetry = (
  scope: "viewer" | "management",
  spaceId: string | undefined,
  screenId: string | undefined,
  useCreateTokenQuery: (
    options?:
      | UseClientRequestOptions<
          | CreateAppViewerTokenMutationVariables
          | CreateAppManagementTokenMutationVariables
        >
      | undefined
  ) => [
    FetchData<
      CreateAppViewerTokenMutation | CreateAppManagementTokenMutation,
      | CreateAppViewerTokenMutationVariables
      | CreateAppManagementTokenMutationVariables,
      unknown
    >,
    UseClientRequestResult<CreateAppViewerTokenMutation, unknown>
  ],
  successCallback: (data: TokenRetryResponse) => unknown,
  failureCallback: (error: TokenRetryError) => unknown,
  retryExhaustionCallback: (error: TokenRetryError) => unknown,
  options?: RetryOptions
): void => {
  // reference for the retry count - we don't want to have this cause a rerender in a useeffect
  const retryCountRef = useRef<number>(0);
  const maxRetryAttempts: number | undefined = options?.maxAttempts;
  const retryTimeoutMs: number =
    options?.retryTimeoutMs || VIEWER_TOKEN_RETRY_BASE_TIMEOUT;
  const maxRetryTimeoutMs: number =
    options?.maxRetryTimeoutMs || MAX_BACKOFF_PERIOD;
  const [hasSuccessfulInitialFetch, setHasSuccessfulInitialFetch] = useState<
    boolean
  >(false);

  const query = createAppTokenQuery(scope, spaceId, screenId);
  const [fetchToken, { data, error }] = useCreateTokenQuery(query);

  const lastAppViewerTokenSuccess = useSelector<
    PlayerState,
    number | undefined
  >((state) => state.config.lastAppViewerTokenSuccess);

  useEffect(() => {
    let refreshInterval: number | null = null;
    // Ensure we have an appViewerToken from first load of player
    if (lastAppViewerTokenSuccess && hasSuccessfulInitialFetch) {
      // Check every 5 mins if we should refetch the appViewerToken
      refreshInterval = window.setInterval(() => {
        if (shouldRefetchAppViewerToken(lastAppViewerTokenSuccess)) {
          fetchToken();
        }
      }, TOKEN_REFRESH_INTERVAL);

      return (): void => {
        if (refreshInterval !== null) {
          window.clearInterval(refreshInterval);
        }
      };
    }
  }, [lastAppViewerTokenSuccess, hasSuccessfulInitialFetch, fetchToken]);

  useEffect(() => {
    /* KICK OFF
    
      kicks off attempting to fetch the token but only after we have
      a solid spaceId */
    if (spaceId) {
      fetchToken();
    }
  }, [spaceId, fetchToken]);

  useEffect(() => {
    let token = null;
    let tokenType = TokenType.Viewer;
    /* SUCCESS
    send token string to the callback */
    if (
      (data && data.createSignedAppViewerJwt) ||
      ((data as CreateAppManagementTokenMutation) &&
        (data as CreateAppManagementTokenMutation).createSignedAppManagementJwt)
    ) {
      // determine the token to return
      if (
        Object.prototype.hasOwnProperty.call(data, "createSignedAppViewerJwt")
      ) {
        token = data.createSignedAppViewerJwt?.signedAppViewerToken;
      } else if (
        Object.prototype.hasOwnProperty.call(
          data,
          "createSignedAppManagementJwt"
        )
      ) {
        tokenType = TokenType.Management;
        token = (data as CreateAppManagementTokenMutation)
          .createSignedAppManagementJwt?.signedAppManagementToken;
      }

      const tokenError = checkTokenErrors(token, tokenType);
      if (tokenError) log.error(tokenError);

      const responseData = {
        token: token ? token : null,
        retryCount: retryCountRef.current,
      };
      successCallback(responseData);
      retryCountRef.current = 0;
      setHasSuccessfulInitialFetch(true);
    }
  }, [data, successCallback]);

  useEffect(() => {
    /* RETRY Exhaustion
    send error response to the callback */
    if (maxRetryAttempts && retryCountRef.current >= maxRetryAttempts) {
      if (error) {
        const responseError = generateAppTokenError(
          error,
          retryCountRef.current
        );
        retryExhaustionCallback(responseError);
      }
    }
  }, [error, retryExhaustionCallback, maxRetryAttempts]);

  useEffect(() => {
    /* RETRY
    send error response to the callback */
    if (error && (error.fetchError || error.httpError || error.graphQLErrors)) {
      // send error to failure callback
      const responseError = generateAppTokenError(error, retryCountRef.current);
      if (
        (maxRetryAttempts && retryCountRef.current < maxRetryAttempts) ||
        (maxRetryTimeoutMs && !maxRetryAttempts)
      ) {
        failureCallback(responseError);
      }
    }
  }, [error, failureCallback, maxRetryAttempts, maxRetryTimeoutMs]);

  useEffect(() => {
    /* RETRY
    retry getting the token */
    if (
      !hasSuccessfulInitialFetch && // only retry if we didn't get a success on first load
      error &&
      (error.fetchError || error?.httpError || error?.graphQLErrors)
    ) {
      let willRetry = true;
      let includesCode = false;

      // look at whether we should stop retrying based on the options
      const code = getErrorStatusCode(error as APIError);

      if (code && code === HTTP_UNAUTHORISED) {
        includesCode = true;
      }

      if (maxRetryAttempts && retryCountRef.current > maxRetryAttempts) {
        willRetry = false;
      }
      if (willRetry) {
        const timeout = 2 ** retryCountRef.current * retryTimeoutMs;

        const retryTimeout = window.setTimeout(
          () => {
            retryCountRef.current = retryCountRef.current + 1;
            fetchToken();
          },
          timeout < maxRetryTimeoutMs && !includesCode
            ? timeout
            : maxRetryTimeoutMs
        );
        return (): void => {
          window.clearTimeout(retryTimeout);
        };
      }
    }
  }, [
    error,
    fetchToken,
    retryTimeoutMs,
    maxRetryTimeoutMs,
    maxRetryAttempts,
    hasSuccessfulInitialFetch,
  ]);
};
