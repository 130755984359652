import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore,
  Store,
  StoreEnhancer,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  emptyInitialState,
  PlayerState,
  rootReducer,
  getPersistedRootReducer,
} from "./rootReducer";
import { Persistor, persistStore } from "redux-persist";
import { PersistPartial, PlayerAction, unusedAction } from "./storeTypes";

export const createPlayerStore = (
  isOfflineStorageEnabled: boolean,
  graphqlTokenScreenId?: string //
): {
  persistor?: Persistor;
  store: Store<PlayerState, AnyAction>;
} => {
  const isLocalDev = process.env.NODE_ENV === "development";

  let enhancer: StoreEnhancer;

  if (isLocalDev) {
    // Middleware you want to use in local development, e.g. Redux Devtools.
    enhancer = composeWithDevTools(applyMiddleware());
  } else {
    enhancer = compose(
      // Middleware you want to use in production
      applyMiddleware()
    );
  }

  const persistedReducer = getPersistedRootReducer(graphqlTokenScreenId);
  const persistedInitialState = persistedReducer(undefined, unusedAction());

  const store = isOfflineStorageEnabled
    ? createStore<PlayerState & PersistPartial, PlayerAction, unknown, unknown>(
        persistedReducer,
        persistedInitialState,
        enhancer
      )
    : createStore<PlayerState, PlayerAction, unknown, unknown>(
        rootReducer,
        emptyInitialState,
        enhancer
      );

  const persistor = isOfflineStorageEnabled ? persistStore(store) : undefined;

  return {
    persistor,
    store,
  };
};
