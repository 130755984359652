import { PlayerAction } from "../storeTypes";
import {
  ConfigState,
  REQUEST_APP_VIEWER_TOKEN_SUCCESS,
  SET_CONFIG,
} from "./types";

/**
 * Config = Data passed in on startup.
 *
 * Initial state for Config should be provided by getConfig() in setup.ts. Not here.
 */
const initialState: ConfigState = {
  graphqlEndpoint: "",
  graphqlToken: "",
  imgixDomain: "",
  linkCloudServiceUrl: "",
  orientation: "landscape",
  timeOffset: 0,
  contentConfig: {
    type: "screen",
    id: "",
    isPreview: false,
  },
  region: "eu",
  mediaOrigin: "",
  lokiEndpoint: "",
  version: "",
  rumSampleRate: 100,
};

export function configReducer(
  state: ConfigState = initialState,
  action: PlayerAction
): ConfigState {
  switch (action.type) {
    case SET_CONFIG: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REQUEST_APP_VIEWER_TOKEN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
