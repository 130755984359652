import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TimelinePlaybackState } from "../store/playback/types";
import { PlayerState } from "../store/rootReducer";
import { TimelinesState } from "../store/timelines/types";

export const useAudioSettings = (contentIds: string[]) => {
  const [primaryAudioContentId, setPrimaryAudioContentId] = useState<
    string | undefined
  >(undefined);
  const timeline = useSelector<PlayerState, TimelinesState | undefined>(
    (state) => state.timelines
  );
  const playbackState = useSelector<
    PlayerState,
    { [key: string]: TimelinePlaybackState } | undefined
  >((state) => state.playback.timelines);

  useEffect(() => {
    if (playbackState && timeline) {
      const contentId = contentIds.find((contentId: string) => {
        if (!playbackState[contentId]) return false;

        const state = playbackState[contentId];
        const item =
          state.activeIndex !== undefined
            ? timeline.byId[contentId].items[state.activeIndex]
            : undefined;
        return item?.isPrimaryAudio || false;
      });
      setPrimaryAudioContentId(contentId);
    }
  }, [contentIds, timeline, playbackState]);

  return {
    primaryAudioContentId,
  };
};
