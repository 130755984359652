/**
 * Object for triggering warnings when there are too many calls to redux persist in certain period of time
 */
export class ReduxPersistCounter {
  private reduxPersistTimestamps: ReduxPersistLog[] = [];
  private duration: number;
  private triggerNumber: number;
  private warningCallback: WarningCallback;

  constructor(config: ReduxPersistCounterConfig) {
    this.duration =
      config.baseTrackingTimePeriodMs > 0
        ? config.baseTrackingTimePeriodMs
        : 5000;
    this.triggerNumber = config.triggerNumber > 0 ? config.triggerNumber : 5;
    this.warningCallback = config.onTriggerWarning;
  }

  // adds reduxPersistTimestamps and returns the number of reduxPersistTimestamps added
  public add(): void {
    this.retain();
    this.reduxPersistTimestamps.push({ timestamp: Date.now() });

    if (this.reduxPersistTimestamps.length >= this.triggerNumber) {
      this.reduxPersistTimestamps = [];
      this.warningCallback(this.reduxPersistTimestamps);
    }
  }

  private retain(): void {
    const timestampCuttingPoint = Date.now() - this.duration;
    this.reduxPersistTimestamps = this.reduxPersistTimestamps.filter(
      ({ timestamp }) => timestamp >= timestampCuttingPoint
    );
  }
}

interface ReduxPersistLog {
  timestamp: number;
}

interface ReduxPersistCounterConfig {
  baseTrackingTimePeriodMs: number; // period of time in which counting is performed
  triggerNumber: number; // minimum number of calls within the baseTrackingTimePeriod to trigger warning
  onTriggerWarning: WarningCallback;
}

type WarningCallback = (callLog: ReduxPersistLog[]) => void;
