import {
  ConfigState,
  RequestAppViewerTokenSuccessAction,
  REQUEST_APP_VIEWER_TOKEN_SUCCESS,
  SetConfigAction,
  SET_CONFIG,
} from "./types";

export function setConfig(payload: Partial<ConfigState>): SetConfigAction {
  return {
    type: SET_CONFIG,
    payload,
  };
}

export function requestAppViewerTokenSuccess(
  payload: string
): RequestAppViewerTokenSuccessAction {
  return {
    type: REQUEST_APP_VIEWER_TOKEN_SUCCESS,
    payload: {
      appViewerToken: payload,
      lastAppViewerTokenSuccess: Date.now(),
    },
  };
}
