/** To generate timeline items from loop items with options */
import {
  TimelineContinuousStartItem as BreakpointStartItem,
  TimelineItem,
  TimelineItemContent,
  TimelineItemsForSegment,
  TimelineItemsWithOptionsInputs,
} from "../../store/timelines/types";
import { isTimelineItemVoid } from "../../utils/contentItemTypeGuards";
import {
  GenerateTimelineItemsInputs,
  TimelineItemsWithPlaytimeTracking,
} from "./getBreakpointTimeline";
import {
  getBreakpointStartItem,
  getTotalLoopItems,
  isFirstAndLastItemDuplicate,
} from "./utils";
import { Logger } from "../../logger/logger";

const log = new Logger("TimelineSegmentGenerator");

export const generateTimelineItemsBySegment = (
  inputs: GenerateTimelineItemsInputs
): TimelineItemsWithPlaytimeTracking => {
  const {
    bpWithItems,
    breakpointTimelineItems,
    lastItemFromPreviousBp,
    bpFullDuration,
  } = inputs;
  let playTimeTrackingMs = inputs.playTimeTrackingMs;
  const totalItems = bpWithItems.items.length;
  const validItemsTotalDurationMs = bpWithItems.totalDurationMs;
  const isFullLoopTimelineItemsRequireMerge = isFirstAndLastItemDuplicate(
    breakpointTimelineItems
  );

  // -------------------- "start Segment" --------------------------
  // default to start at first item with 0 offset
  let startItem: BreakpointStartItem = {
    startItemIdx: 0,
    startTimeOffsetMs: 0,
  };

  // [continuous playback], find the last play item from previous break point to continue
  // apply continuous playback only non void content
  if (lastItemFromPreviousBp && !isTimelineItemVoid(lastItemFromPreviousBp)) {
    startItem = getBreakpointStartItem(bpWithItems, lastItemFromPreviousBp);
  }

  // if start item is idx=0 with offset=0 then no start segment required, it will include in the loop
  const isNotRequiredStartSegment =
    startItem.startItemIdx === 0 && startItem.startTimeOffsetMs === 0;
  const continueDuration =
    validItemsTotalDurationMs - startItem.startTimeOffsetMs;
  const startSegmentDuration = isNotRequiredStartSegment
    ? 0
    : bpFullDuration < continueDuration
    ? bpFullDuration
    : continueDuration;

  const startSegment = getTimelineSegmentItemsWithOptions({
    timelineLoopItems: breakpointTimelineItems,
    startTimeOffsetMs: startItem.startTimeOffsetMs,
    playTimeTrackingMs: playTimeTrackingMs,
    maxTargetDurationMs: startSegmentDuration,
  });
  playTimeTrackingMs = startSegment.playTimeTrackingMs;

  // -------------------- "Full Loop Segment" --------------------------
  const bpFulldurationAfterStartSegment =
    bpFullDuration - startSegment.durationMs;
  const maxFullLoopRepeat =
    bpFulldurationAfterStartSegment > 0
      ? Math.floor(bpFulldurationAfterStartSegment / validItemsTotalDurationMs)
      : 0;

  // the full loop that have start and end with the same content, require merge
  // equation to get the generated loop item count with merge tail+head during join
  // so the equation is (loopItemsCount * n) - (n - 1)
  const totalLoopItemsGen = maxFullLoopRepeat * totalItems;
  const totalLoopItems = getTotalLoopItems(
    isFullLoopTimelineItemsRequireMerge,
    bpFullDuration,
    startSegment.durationMs,
    validItemsTotalDurationMs,
    totalItems
  );
  const loopSegmentTimelineItems: TimelineItem[] = new Array(totalLoopItems);

  let loopCount = 0;
  let genCount = 0;
  let lastItem: TimelineItem | undefined;

  if (window.isNaN(totalLoopItemsGen) || totalLoopItemsGen === Infinity) {
    // additional infinite loop protection
    throw new Error(
      `totalLoopItemsGen is not a number. Value: ${totalLoopItemsGen}`
    );
  }
  while (loopCount < totalLoopItemsGen) {
    // use modulo to progress through index items
    const itemIdx = loopCount % totalItems;
    const timelineItem = Object.assign({}, breakpointTimelineItems[itemIdx]);

    // sanitize items by merge adjacent duplicate items
    if (
      isFullLoopTimelineItemsRequireMerge &&
      itemIdx === 0 &&
      lastItem !== undefined
    ) {
      // extend last item instead of adding new one
      lastItem.showDurationMs += timelineItem.fullDurationMs;
    } else {
      timelineItem.startTimestampMs = playTimeTrackingMs;
      timelineItem.showDurationMs = timelineItem.fullDurationMs;
      loopSegmentTimelineItems[genCount] = timelineItem;
      lastItem = timelineItem;
      genCount++;
    }
    // in full loop it is ok to always using fullDurationMs rather than showDurationMs
    playTimeTrackingMs += timelineItem.fullDurationMs;
    loopCount++;
  }

  // -------------------- "endSegment" --------------------------
  const endSegmentDuration =
    bpFulldurationAfterStartSegment > 0
      ? bpFulldurationAfterStartSegment % validItemsTotalDurationMs
      : 0;

  const endSegment = getTimelineSegmentItemsWithOptions({
    timelineLoopItems: breakpointTimelineItems,
    startTimeOffsetMs: 0, // end segment always start from 0
    playTimeTrackingMs: playTimeTrackingMs,
    maxTargetDurationMs: endSegmentDuration,
  });

  playTimeTrackingMs = endSegment.playTimeTrackingMs;

  // make sure there is no duplicate item between last segement and previous segment:
  const lastItemOfPreviousSegment =
    loopSegmentTimelineItems.length > 0
      ? loopSegmentTimelineItems[loopSegmentTimelineItems.length - 1]
      : startSegment.items.length > 0
      ? startSegment.items[startSegment.items.length - 1]
      : undefined;
  const firstItemOfEndSegment =
    endSegment.items.length > 0 ? endSegment.items[0] : undefined;

  if (
    lastItemOfPreviousSegment &&
    firstItemOfEndSegment &&
    isContinuityBetweenItems(lastItemOfPreviousSegment, firstItemOfEndSegment)
  ) {
    // merge only show duration, not full duration
    lastItemOfPreviousSegment.showDurationMs +=
      firstItemOfEndSegment.showDurationMs;
    endSegment.items.splice(0, 1);
  }

  const totalBpItemsDuration =
    startSegmentDuration +
    maxFullLoopRepeat * validItemsTotalDurationMs +
    endSegmentDuration;

  if (totalBpItemsDuration !== bpFullDuration) {
    log.error({
      message:
        "!!!!!!!!!!!!!! breakpoint full duration vs fill items duration are mismatch !!!!!!!!!!!!",
      context: {
        bpWithItemsId: bpWithItems.id,
        breakpointTimestamp: bpWithItems.breakpointTimestamp,
        totalBpItemsDuration: totalBpItemsDuration,
        bpFullDuration: bpFullDuration,
      },
    });
  }

  return {
    items: [
      ...startSegment.items,
      ...loopSegmentTimelineItems,
      ...endSegment.items,
    ],
    playTimeTrackingMs: playTimeTrackingMs,
  };
};

export const isContinuityBetweenItems = (
  itemOne: TimelineItem,
  itemTwo: TimelineItem
): boolean => {
  return (
    (itemOne.type === "void" && itemTwo.type === "void") ||
    (itemTwo.type !== "void" &&
      itemOne.type !== "void" &&
      itemTwo.id === itemOne.id)
  );
};

/** segment is always return items less than full loop items length */
export const getTimelineSegmentItemsWithOptions = (
  inputs: TimelineItemsWithOptionsInputs
): TimelineItemsForSegment => {
  const resultTimelineItems: TimelineItem[] = [];
  const { startTimeOffsetMs, maxTargetDurationMs, timelineLoopItems } = inputs;
  const totalItems = timelineLoopItems.length;
  let playTimeTrackingMs = inputs.playTimeTrackingMs;
  let sumDurationMs = 0;

  // 0 nothing to show
  if (totalItems === 0 || maxTargetDurationMs <= 0)
    return { items: [], durationMs: 0, playTimeTrackingMs };

  // 1 item
  if (totalItems === 1) {
    const timelineItem: TimelineItemContent = Object.assign(
      {},
      timelineLoopItems[0]
    ) as TimelineItemContent;

    if (startTimeOffsetMs >= timelineItem.fullDurationMs) {
      return { items: [], durationMs: 0, playTimeTrackingMs };
    } else {
      timelineItem.startTimestampMs = playTimeTrackingMs;
      timelineItem.showDurationMs =
        timelineItem.fullDurationMs - startTimeOffsetMs;
      playTimeTrackingMs += timelineItem.showDurationMs;
      return {
        items: [timelineItem],
        durationMs: timelineItem.showDurationMs,
        playTimeTrackingMs: playTimeTrackingMs,
      };
    }
  }

  // > 1 items
  // Find start idex from the startTimeOffset
  for (let i = 0; i < totalItems; i++) {
    const firstFoundTimelineItem: TimelineItemContent = Object.assign(
      {},
      timelineLoopItems[i]
    ) as TimelineItemContent;

    // aggregate sum of items' duration
    sumDurationMs += firstFoundTimelineItem.fullDurationMs;

    // find the first item by check is start time offset is inside the aggregate sum duration
    if (startTimeOffsetMs < sumDurationMs) {
      // update first found timeline item
      firstFoundTimelineItem.startTimestampMs = playTimeTrackingMs;
      firstFoundTimelineItem.showDurationMs = sumDurationMs - startTimeOffsetMs;
      firstFoundTimelineItem.showDurationMs =
        firstFoundTimelineItem.showDurationMs <= maxTargetDurationMs
          ? firstFoundTimelineItem.showDurationMs
          : maxTargetDurationMs;
      playTimeTrackingMs += firstFoundTimelineItem.showDurationMs;
      resultTimelineItems.push(firstFoundTimelineItem);

      // reset sumDuration to the be the actual play time of the first found item
      sumDurationMs = firstFoundTimelineItem.showDurationMs;

      // continue generate timeline item from next item after the found item idex
      for (let m = i + 1; m < totalItems; m++) {
        // exit after generate enough duration to the limitation in maxTargetDurationMs
        if (sumDurationMs >= maxTargetDurationMs) break;

        const timelineItem: TimelineItemContent = Object.assign(
          {},
          timelineLoopItems[m]
        ) as TimelineItemContent;
        timelineItem.startTimestampMs = playTimeTrackingMs;
        // calculate the actual show duration againt the limit by maxTargetDurationMs
        const sumDurationsWithPlayFullDurationMs =
          sumDurationMs + timelineItem.fullDurationMs;
        if (sumDurationsWithPlayFullDurationMs <= maxTargetDurationMs) {
          timelineItem.showDurationMs = timelineItem.fullDurationMs;
        } else {
          timelineItem.showDurationMs =
            timelineItem.fullDurationMs -
            (sumDurationsWithPlayFullDurationMs - maxTargetDurationMs);
        }
        playTimeTrackingMs += timelineItem.showDurationMs;
        resultTimelineItems.push(timelineItem);
        sumDurationMs += timelineItem.showDurationMs;
      }
      // break of from firstFoundTimelineItem
      break;
    }
  }

  return {
    items: resultTimelineItems,
    durationMs: sumDurationMs,
    playTimeTrackingMs,
  };
};
