import { NormalizationUtilities } from "../storeTypes";
import { PlayerState } from "../rootReducer";
import { getEmptyTraversalObject } from "../normalizationUtils";
import { spaceSchema } from "../normalizationSchemas";

export const spaceNormalization: NormalizationUtilities = {
  schema: spaceSchema,
  traverseFunction: (spaceId: string, state: PlayerState) => {
    const result = getEmptyTraversalObject();
    result.spaces.add(spaceId);
    return result;
  },
};
