import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { GenericViewer } from "../../../viewers/containers/GenericViewer/GenericViewer";
import { ContentViewItem } from "../../../../types/content";
import { ContentConfig } from "../../../../store/config/types";
import { setTag } from "../../../../utils/bugTracker";
import { OrganizationState } from "../../../../store/organization/types";
import { TimeOptions } from "../../../../utils/timeManager";
import { useTimeOptions } from "../../../../utils/useTimeOptions";
import { setDailyReload } from "../../../../utils/dailyReloadManager";
import { getRootContent } from "../../../../utils/rootContent";
import { MenuViewerContainer } from "../../../viewers/containers/MenuViewer/MenuViewer";
import { useEmbedMenu } from "../../../../providers/MenuProvider/MenuProvider";
import { ScreenState } from "../../../../store/screen/types";
import { useGetAppViewerToken } from "../../../../utils/useGetAppViewerToken";
import { Logger } from "../../../../logger/logger";
import { checkForGraphQLTokenIssues } from "../../../../utils";
import { TokenRetryError } from "../../../../types/appToken";

const log = new Logger("useGetAppViewerToken");

export const PlayerCoreContainer: FunctionComponent = () => {
  const organization = useSelector<PlayerState, OrganizationState>(
    (state) => state.organization
  );
  const contentConfig = useSelector<PlayerState, ContentConfig>(
    (state) => state.config.contentConfig
  );
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);

  const spaceId = useSelector<PlayerState, string | undefined>(
    (state) => state.screen?.spaceId || undefined
  );

  const graphQLToken = useSelector<PlayerState, string>((state) => {
    return state.config.graphqlToken;
  });

  const lastAppViewerTokenSuccess = useSelector<
    PlayerState,
    number | undefined
  >((state) => state.config.lastAppViewerTokenSuccess);

  const { liveMode } = useEmbedMenu();
  const embedMenuVisible = !liveMode;

  const contentItem: ContentViewItem = useMemo<ContentViewItem>(() => {
    return getRootContent(contentConfig);
  }, [contentConfig]);

  const timeOptions: TimeOptions = useTimeOptions();

  useEffect(() => {
    const dailyReload = setDailyReload(timeOptions);
    return () => {
      window.clearTimeout(dailyReload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTag("root_content_type", contentItem.type);
    setTag(
      "root_content_id",
      contentItem.type === "void" ? "undefined" : contentItem.id
    );
  }, [contentItem]);

  useEffect(() => {
    setTag("org_id", organization.id);
  }, [organization.id]);

  useEffect(() => {
    setTag("space_id", spaceId || "");
  }, [spaceId]);

  useEffect(() => {
    setTag("screen_id", screen.id);
  }, [screen.id]);

  const onSuccessCallback = useCallback((retryCount: number) => {
    log.info({
      message: "Request app viewer token [SUCCESS]",
      context: {
        retryCount,
      },
    });
  }, []);

  const onFailureCallback = useCallback(
    (error: TokenRetryError) => {
      const tokenIssues = checkForGraphQLTokenIssues("Error", graphQLToken);

      log.error({
        message: `Request app viewer token [FAIL] - ${error.source}`,
        context: {
          errorMessage: error.message,
          retryCount: error.retryCount,
          errorCode: error.status,
          errorSource: error.source,
          tokenIssues,
          lastAppViewerTokenSuccess: lastAppViewerTokenSuccess,
        },
      });
    },
    [graphQLToken, lastAppViewerTokenSuccess]
  );

  useGetAppViewerToken(
    spaceId,
    screen.id,
    onSuccessCallback,
    onFailureCallback
  );

  if (embedMenuVisible) {
    return <MenuViewerContainer />;
  }

  return (
    <GenericViewer
      isRoot
      contentItem={contentItem} //ScreenViewerContainer key
    />
  );
};
