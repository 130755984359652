if (!Object.getOwnPropertyDescriptors) {
  Object.getOwnPropertyDescriptors = function <T>(
    obj: T
  ): { [P in keyof T]: TypedPropertyDescriptor<T[P]> } & {
    [x: string]: PropertyDescriptor;
  } {
    const descriptors: { [key: string]: PropertyDescriptor } = {};
    for (const key of Object.getOwnPropertyNames(obj)) {
      descriptors[key] = Object.getOwnPropertyDescriptor(obj, key)!;
    }
    for (const sym of Object.getOwnPropertySymbols(obj)) {
      descriptors[sym.toString()] = Object.getOwnPropertyDescriptor(obj, sym)!;
    }
    return descriptors as { [P in keyof T]: TypedPropertyDescriptor<T[P]> } & {
      [x: string]: PropertyDescriptor;
    };
  };
}

if (!Object.entries) {
  Object.entries = function (obj: any) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    return resArray;
  };
}

export {};
