import React, {
  memo,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Logger } from "../../../../logger/logger";
import { useRetryCallback } from "../../../../utils/useRetryCallback";
import { useIsMounted } from "../../../../utils/useIsMounted";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { ScreenState } from "../../../../store/screen/types";
import { AudioSettings } from "../../../../providers/AudioSettingsProvider/types";
import { AudioSettingsContext } from "../../../../providers/AudioSettingsProvider/AudioSettingsProvider";
import { ContentFailureGenericCb } from "../TimelineViewer/types";

const log = new Logger("audioViewer");

interface AudioViewerProps {
  src: string;
  isPreload: boolean;
  initialPlaybackPositionMs: number; // where should the audio start it's playback
  loop?: boolean;
  fileName: string;
  mimetype: string;
  onContentFailure: ContentFailureGenericCb;
}

export const AudioViewer = memo((props: AudioViewerProps): ReactElement<
  AudioViewerProps
> | null => {
  const {
    isPreload,
    loop,
    src,
    initialPlaybackPositionMs,
    mimetype,
    onContentFailure,
  } = props;
  const audioElementRef = useRef<HTMLAudioElement>(null);
  const pendingPlayPromise = useRef<Promise<void> | undefined>(undefined);
  const [remountKey, setRemountKey] = useState(new Date().getTime());
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const isMounted = useIsMounted();
  const audioSettings = useContext<AudioSettings>(AudioSettingsContext);

  const errorRetryLogic = useCallback(
    (error?: MediaError) => {
      log.error({
        message: `Audio element error.`,
        context: {
          src,
          errorMessage: error?.message,
          errorCode: error?.code,
        },
      });

      setRemountKey(new Date().getTime());
    },
    [src]
  );

  const retryFunction = useRetryCallback({
    maxAttempts: 3,
    onReachMaxAttempt: onContentFailure,
    exponentialTimeout: false,
    retryTimeoutMs: 1000,
    retryLogicCb: errorRetryLogic,
  });

  const play = useCallback(() => {
    if (
      audioElementRef.current &&
      audioElementRef.current.paused &&
      pendingPlayPromise.current === undefined
    ) {
      pendingPlayPromise.current = audioElementRef.current.play();
      pendingPlayPromise.current
        ?.then(() => {
          if (!isMounted()) {
            return;
          }
          pendingPlayPromise.current = undefined;
          if (!audioElementRef.current) {
            return;
          }
          log.info({
            message: `Play Audio ${audioElementRef.current.src}`,
            context: {
              audioId: audioElementRef.current.id,
              name: audioElementRef.current.title,
              contentType: "audio",
              isPreload: isPreload,
            },
            proofOfPlayFlag: true,
          }); // This promise resolve is added just to get a warning when play fails (i.e. autoplay is blocked)
        })
        .catch((error) => {
          pendingPlayPromise.current = undefined;

          if (!isMounted()) {
            return;
          }

          retryFunction(error);
        });
    }
    // eslint disabled cause isPreload is used only for logging, not for business logic. This callback is aimed to stay unchanged throughout the component lifecycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryFunction]);

  const pause = useCallback(() => {
    const performPause = () => {
      if (audioElementRef.current && !audioElementRef.current.paused) {
        return audioElementRef.current.pause();
      }
    };

    if (pendingPlayPromise.current) {
      // check if there is pending play promise to avoid media exception "play interrupted by a call to pause"
      pendingPlayPromise.current.then(() => {
        performPause();
      });
    } else {
      performPause();
    }
  }, []);

  useEffect(() => {
    const audioElement = audioElementRef.current;

    if (!isPreload && audioElement) {
      const initialPlaybackPositionSec = initialPlaybackPositionMs / 1000;
      audioElement.currentTime = initialPlaybackPositionSec;

      play();
    } else if (isPreload) {
      pause();
    }
  }, [isPreload, initialPlaybackPositionMs, play, pause]);

  const onError = useCallback(
    (event: SyntheticEvent<HTMLAudioElement, Event>) => {
      const error = (event.target as { error?: MediaError })?.error;

      retryFunction(error);
    },
    [retryFunction]
  );

  return (
    <audio
      muted={audioSettings.shouldMuteMedia || screen.isMuted}
      ref={audioElementRef}
      controls
      loop={loop ?? true}
      title={props.fileName}
      onError={onError}
      data-testid="audio-viewer"
      key={remountKey}
    >
      <source src={src} type={mimetype} />
    </audio>
  );
});
AudioViewer.displayName = "AudioViewer";
