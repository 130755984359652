import { GraphQLClient } from "graphql-hooks";
import { ConfigState } from "./store/config/types";
import { FileProcessingClientInterface } from "./types/filesClient";
import { Logger } from "./logger/logger";
import { checkForGraphQLTokenIssues } from "./utils/index";
import { FileProcessingClientWrapped } from "./utils/FileProcessingClientWrapped";

/**
 * Adding logger to log if we try to initialize the GraphQL client
 * with an undefined, expired or wrongly formatted Studio GraphQL token
 */
const log = new Logger("setup");

/**
 * Instantiate a new GraphQL client to be used across the Player
 *
 * https://github.com/nearform/graphql-hooks
 * Substantially lighter-weight than Apollo
 * Avoids breaks due to Apollo forcing __typename everywhere.
 */
export const createGraphQLClient = (
  config: Pick<ConfigState, "graphqlEndpoint" | "graphqlToken">
): GraphQLClient => {
  // temporarily check and log if any issues with the incoming JWT
  const tokenIssues = checkForGraphQLTokenIssues(
    "Create GraphQL Client Error",
    config.graphqlToken
  );
  if (tokenIssues && process.env.NODE_ENV !== "test") {
    log.error(tokenIssues);
  }

  return new GraphQLClient({
    url: config.graphqlEndpoint,
    cache: undefined,
    logErrors: true,
    headers: {
      Authorization: `bearer ${config.graphqlToken}`,
    },
  });
};

export const createFileProcessingClient = (
  config: Pick<
    ConfigState,
    "imgixDomain" | "mediaOrigin" | "secureMediaServiceUrl"
  >
): FileProcessingClientInterface => {
  return new FileProcessingClientWrapped({
    imgixDomain: config.imgixDomain,
    mediaOrigin: config.mediaOrigin,
    secureMediaServiceUrl: config.secureMediaServiceUrl,
  });
};
