export interface IVideoPool {
  requestInstance: () => HTMLVideoElement;
  returnInstance: (videoElement: HTMLVideoElement) => void;
  getLength: () => number;
}

/**
 * This class maintains a pool of html video elements to reuse them instead of creating new video elements for
 * each video.
 */
export class VideoPool implements IVideoPool {
  private static instance: VideoPool;

  private static pool: Array<HTMLVideoElement> = [];

  constructor() {
    if (VideoPool.instance) {
      return VideoPool.instance;
    } else {
      VideoPool.instance = this;
      return this;
    }
  }

  public requestInstance = (): HTMLVideoElement => {
    if (VideoPool.pool.length > 0) {
      // reuse video element
      return VideoPool.pool.pop() as HTMLVideoElement;
    }
    // create new video element
    return window.document.createElement("video");
  };

  public returnInstance = (videoElement: HTMLVideoElement): void => {
    VideoPool.pool.push(videoElement);
  };

  public getLength = (): number => VideoPool.pool.length;
}
