import React from "react";
import { useEmbedMenu } from "../../../../../providers/MenuProvider/MenuProvider";
import {
  BaseMenuContentItem,
  MenuContentItem,
} from "../../../../../providers/MenuProvider/types";
import ItemTile from "./ItemTile";

export const ItemTileList = ({
  items,
  onClick,
  currentIndex,
  styles,
}: {
  items: MenuContentItem[];
  onClick: (item: BaseMenuContentItem) => void;
  currentIndex: number;
  styles: {
    [key: string]: string;
  };
}) => {
  const { items: allItems } = useEmbedMenu();
  return (
    <>
      {items.map((item, index) => {
        const active =
          allItems.findIndex((element) => element === item) === currentIndex;
        return item.type !== "void" ? (
          <div
            key={item.id + index}
            onClick={() => onClick(item)}
            className={styles.itemTileWrapper}
          >
            <ItemTile item={item} active={active} />
            <div
              className={`${styles.itemLabel} ${active ? styles.active : ""}`}
            >
              <span>{item.name}</span>
            </div>
          </div>
        ) : (
          <div />
        );
      })}
    </>
  );
};
