import {
  TimelineItem,
  TimelineItemContent,
  TimelineItemContentBase,
  TimelineItemVoid,
} from "../store/timelines/types";
import {
  ContentItemGeneric,
  ContentItemParent,
} from "../store/contentLists/types";

export function isTimelineItemContent(
  item: TimelineItem | ContentItemGeneric
): item is TimelineItemContent {
  const targetItem = item as TimelineItemContent;
  return (
    typeof targetItem.type === "string" &&
    typeof targetItem.id === "string" &&
    typeof targetItem.startTimestampMs === "number" &&
    typeof targetItem.isInfinite === "boolean"
  );
}

export function isTimelineItemVoid(
  item: TimelineItem | ContentItemGeneric
): item is TimelineItemVoid {
  return !(item as TimelineItemContentBase).id && item.type === "void";
}

export function isContentItemGeneric(
  item: TimelineItem | ContentItemGeneric
): item is ContentItemGeneric {
  return (
    !isTimelineItemContent(item) &&
    !isTimelineItemVoid(item) &&
    typeof item.type === "string" &&
    typeof item.id === "string"
  );
}

export function isContentItemParent(
  item: TimelineItem | ContentItemGeneric
): item is ContentItemParent {
  return (
    isContentItemGeneric(item) &&
    (item.type === "playlist" || item.type === "channel")
  );
}
