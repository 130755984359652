import { ContentSizeType, LeafContentType } from "../../types/content";
import { GqlZoneItemTransition } from "../graphqlTypes";

export const UPDATE_TIMELINE = "UPDATE_TIMELINE";
export const REPLACE_TIMELINE = "REPLACE_TIMELINE";
export const CONTENT_FAILURE = "CONTENT_FAILURE";

export type TimelineAction =
  | UpdateTimelineAction
  | ReplaceTimelineAction
  | ContentFailureAction;

export type UpdateTimelineAction = {
  type: typeof UPDATE_TIMELINE;
  payload: {
    items: TimelineItem[];
    id: string;
    cleanupAmount?: number;
    targetTimestampMs: number; // the moment when the update happened
    nextFragmentRequestTimestamp: number;
  };
};

export type ReplaceTimelineAction = {
  type: typeof REPLACE_TIMELINE;
  payload: {
    items: TimelineItem[];
    id: string;
    targetTimestampMs: number; // the moment when the update happened
    nextFragmentRequestTimestamp: number;
  };
};

export type ContentFailureAction = {
  type: typeof CONTENT_FAILURE;
  payload: {
    timelineId: string;
    itemIndex: number;
    replacement: TimelineItem[];
  };
};

export interface TimelinesState {
  byId: {
    [id: string]: Timeline;
  };
  type: TimelineType;
}

export interface Timeline {
  id: string;
  items: TimelineItem[];
}

export type TimelineItem = TimelineItemVoid | TimelineItemContent;

interface TimelineItemBase {
  id?: string;
  startTimestampMs: number;
  isInfinite: boolean; // will be deprecated once V2 out
  fullDurationMs: number; // full duration declared for an item in content json props or media metadata
  transition?: GqlZoneItemTransition;
  showDurationMs: number; // duration to show on screen, this should be the same number of nextItem.startTimestampMs - currentItem.startTimestampMs
  breakpointId: number; // every item below to one of breakpoint which contain array of items, this allow recovery playback to check next available item is valid or not
  isFullscreen?: boolean;
  isPrimaryAudio?: boolean;
}

export interface TimelineItemContentBase extends TimelineItemBase {
  id: string;
  type: LeafContentType;
  listId: string; // required for matching timeline item with original content json item
  preloadDurationMs?: number; // undefined => Don't preload.
  isReplacement?: boolean; // indicates if this item is a replacement of a failed item
  hasFailed?: boolean; // indicates if this content has failed to be displayed
  hasNoReplacement?: boolean; // indicates if a failed item can not be replaced
  parentId?: string;
  parentListId?: string;
  parentType?: string;
}

/**
 * Represents a period of emptiness on the screen
 */
export interface TimelineItemVoid extends TimelineItemBase {
  type: "void";
}

export type TimelineItemContent =
  | TimelineItemFile
  | TimelineItemApp
  | TimelineItemLink
  | TimelineItemSite;

export type TimelineType = "local" | "test";

export interface TimelineItemFile extends TimelineItemContentBase {
  type: "file";
  sizeType: ContentSizeType;
}

export interface TimelineItemApp extends TimelineItemContentBase {
  type: "app";
}

export interface TimelineItemLink extends TimelineItemContentBase {
  type: "link";
}

export interface TimelineItemSite extends TimelineItemContentBase {
  type: "site";
}

export type TimelineContinuousStartItem = {
  startItemIdx: number;
  startTimeOffsetMs: number;
};

export type TimelineItemsForSegment = {
  items: TimelineItem[];
  durationMs: number;
  playTimeTrackingMs: number;
};

export enum TimelinePlayback {
  TIMELINE_PLAYBACK_SYNC = "sync",
  TIMELINE_PLAYBACK_UNSYNC = "unsync",
  TIMELINE_PLAYBACK_DEVICE_SYSTEM_TIME = "deviceSystemTime",
}

export type TimelinePlaybackMode = {
  type: TimelinePlayback;
};

export interface TimelineItemsWithOptionsInputs {
  timelineLoopItems: TimelineItem[];
  startTimeOffsetMs: number;
  playTimeTrackingMs: number;
  maxTargetDurationMs: number;
}
