import { NormalizationUtilities, TraversalResult } from "../storeTypes";
import { fileNormalization } from "../files/normalization";
import { PlayerState } from "../rootReducer";
import {
  getEmptyTraversalObject,
  mergeTraversalResult,
} from "../normalizationUtils";
import { FileFragment } from "../../queries";
import { NormalizedLinkFragment } from "./types";
import { linkSchema } from "../normalizationSchemas";

export const linkNormalization: NormalizationUtilities = {
  schema: linkSchema,
  traverseFunction: (linkId: string, state: PlayerState): TraversalResult => {
    const targetLink = state.links.byId[linkId];

    if (!targetLink) {
      return getEmptyTraversalObject();
    }

    let result = getEmptyTraversalObject();

    if (targetLink.fileId) {
      result = mergeTraversalResult(
        result,
        fileNormalization.traverseFunction(targetLink.fileId, state)
      );
    }

    result.links.add(linkId);

    return result;
  },
};

export type NormalizedLinkEntities = {
  files: { [key: string]: FileFragment } | null;
  links: { [key: string]: NormalizedLinkFragment } | null;
};
