import React, { useContext } from "react";
/**
 * Purpose: provide seamless transition between 2 content items
 */
import { FunctionComponent } from "react";
import { GqlZoneItemTransition } from "../../../../../store/graphqlTypes";
import { TimelineSlotTransition } from "../TimelineSlotTransition/TimelineSlotTransition";
import {
  e2eTestSlotIdentifier,
  useIsNextItem,
  useShouldRenderForPreloading,
  useTimelineContentVisible,
} from "../utils";
import classnames from "classnames";
import styles from "./TimelineSlot.module.css";
import { timelineSlotPrefix } from "../../../../../test-utils/testIds";
import { isCrossTransition } from "../TimelineSlotTransition/transitionUtils";
import ClientApiContext from "../../../../../ClientApiContext";
import { isLocalDev } from "../../../../../utils/devUtils";
export interface TimelineSlotProps {
  index: number;
  transition: GqlZoneItemTransition | undefined;
  isPreload?: boolean;
  isActiveItemTransitioningOut: boolean;
  forceBackground?: boolean;
  forceVisible?: boolean;
  targetTimelineId: string | undefined;
  indexInTimeline: number | undefined;
  isFullscreenActive?: boolean;
}

/**
 * Is designed to display content and make transitions between items.
 */
export const TimelineSlot: FunctionComponent<TimelineSlotProps> = (props) => {
  const {
    children,
    transition,
    index,
    isActiveItemTransitioningOut,
    forceBackground,
    targetTimelineId,
    indexInTimeline,
    forceVisible,
    isFullscreenActive,
  } = props;
  const clientApiContext = useContext(ClientApiContext);
  const isTizenPlayer = clientApiContext?.playbackConfig?.isTizenPlayer;
  const isNextItem = useIsNextItem(targetTimelineId, indexInTimeline);

  const isCross = transition
    ? isCrossTransition(transition.type, transition.direction)
    : false;

  const shouldRenderForPreloading = useShouldRenderForPreloading(
    targetTimelineId
  );

  const isContentVisible = useTimelineContentVisible({
    isNextItem,
    shouldRenderForPreloading,
    isCrossTransition: isCross,
    isActiveItemTransitioningOut,
  });

  /**
   * Only Tizen need to specific preload css since the style visibility doesn't work properly on tizen
   * it need to use scale to preventing the overlapping video
   */
  const tizenVideoClass = {
    [styles.activeViewer]: isContentVisible && isTizenPlayer,
    [styles.tizenVideoPreload]: !isContentVisible && isTizenPlayer,
    [styles.preloadDevPreview]: !isContentVisible && isLocalDev(),
  };
  const contentClassnames = classnames(styles.base, {
    [styles.visible]: isContentVisible,
    ...tizenVideoClass,
  });

  return (
    <div
      id={isFullscreenActive ? "fullscreen-zone" : undefined}
      className={styles.slotContainer}
    >
      <TimelineSlotTransition
        transition={transition}
        isPreload={props.isPreload ?? false}
        isActiveItemTransitioningOut={isActiveItemTransitioningOut}
        forceBackground={forceBackground}
      >
        <div
          className={contentClassnames}
          id={e2eTestSlotIdentifier(index)}
          aria-label={e2eTestSlotIdentifier(index)}
          data-testid={`${timelineSlotPrefix}${index}`}
        >
          {shouldRenderForPreloading || isContentVisible || forceVisible
            ? children
            : null}
        </div>
      </TimelineSlotTransition>
    </div>
  );
};
