import { FullscreenContent } from "./useFullscreenContent";
import { TimelinesPlaybackState } from "../../../../../../store/playback/types";
import { useIsItemTransitioningOut } from "../../../TimelineViewer/hooks/useIsItemTransitioningOut";

/**
 * Determines if an active content item inside a fullscreen zone is transitioning out
 */
export function useActiveFullscreenItemTransitioningOut(
  fullscreenContent: FullscreenContent,
  timelinesPlaybackState: TimelinesPlaybackState
): boolean {
  const targetFullscreenContent =
    fullscreenContent.active || fullscreenContent.preload;
  const activeItemTimelinePlaybackState = targetFullscreenContent
    ? timelinesPlaybackState[targetFullscreenContent.timelineId]
    : undefined;
  return useIsItemTransitioningOut(activeItemTimelinePlaybackState);
}
