import {
  BreakpointWithItems,
  ListPlaybackControl,
} from "../../store/playback/types";
import { TimelineItem, TimelineItemVoid } from "../../store/timelines/types";
import { contentListToTimelineItems, joinTimeline } from "./utils";
import { generateTimelineItemsBySegment } from "./timelineSegmentGenerator";
import { generateTimelineItemsWithPlaybackControls } from "./timelineWithPlaybackControlGenerator";

export interface TimelineItemsWithPlaytimeTracking {
  items: TimelineItem[];
  playTimeTrackingMs: number;
  plControls?: { [list_id: string]: ListPlaybackControl };
}

export interface GenerateTimelineItemsInputs {
  bpWithItems: BreakpointWithItems;
  breakpointTimelineItems: TimelineItem[];
  lastItemFromPreviousBp: TimelineItem | undefined;
  playTimeTrackingMs: number;
  bpFullDuration: number;
  playbackListControls?: { [list_id: string]: ListPlaybackControl };
}

export const getBreakpointTimeline = (
  breakpointsWithItems: BreakpointWithItems[]
): TimelineItem[] => {
  let finalBpTimelines: TimelineItem[] = [];
  let lastItemFromPreviousBp: TimelineItem | undefined = undefined;
  let playTimeTrackingMs = 0; // for tracking of play timing and turn into startTimestamp for each item
  const totalBreakpoints: number = breakpointsWithItems.length;

  for (let i = 0; i < totalBreakpoints - 1; i++) {
    let bpTimelines: TimelineItem[] = [];
    const bpWithItems = breakpointsWithItems[i];
    const nextBp = breakpointsWithItems[i + 1];
    const bpFullDuration =
      nextBp.breakpointTimestamp - bpWithItems.breakpointTimestamp;
    const totalItems = bpWithItems.items.length;
    const validItemsTotalDurationMs = bpWithItems.totalDurationMs;
    const bplistPlaybackControls = bpWithItems.listPlaybackControls;

    playTimeTrackingMs =
      playTimeTrackingMs === 0
        ? bpWithItems.breakpointTimestamp
        : playTimeTrackingMs;

    if (validItemsTotalDurationMs > 0 && totalItems > 0) {
      if (totalItems === 1) {
        // = 1 item in the breakpoint then set it's duration to the breakpoint fullDuration
        const SingleTimelineItem: TimelineItem[] = contentListToTimelineItems(
          bpWithItems.items,
          bpWithItems.id
        );
        const timelineItem = Object.assign({}, SingleTimelineItem[0]);
        timelineItem.startTimestampMs = playTimeTrackingMs;
        timelineItem.showDurationMs = bpFullDuration;
        playTimeTrackingMs += bpFullDuration;
        bpTimelines = [timelineItem];
        lastItemFromPreviousBp = timelineItem;
      } else {
        // > 1 items

        // Check list playback control and apply generate timeline logic here
        if (bplistPlaybackControls) {
          const timelineItems = generateTimelineItemsWithPlaybackControls({
            bpWithItems,
            breakpointTimelineItems: [],
            lastItemFromPreviousBp,
            playTimeTrackingMs,
            bpFullDuration,
            playbackListControls: bplistPlaybackControls,
          });
          bpTimelines.push(...timelineItems.items);
          playTimeTrackingMs = timelineItems.playTimeTrackingMs;
        } else {
          const breakpointTimelineItems: TimelineItem[] = contentListToTimelineItems(
            bpWithItems.items,
            bpWithItems.id
          );
          const timelineItems = generateTimelineItemsBySegment({
            bpWithItems,
            breakpointTimelineItems,
            lastItemFromPreviousBp,
            playTimeTrackingMs,
            bpFullDuration,
          });
          bpTimelines.push(...timelineItems.items);
          playTimeTrackingMs = timelineItems.playTimeTrackingMs;
        }

        lastItemFromPreviousBp = bpTimelines[bpTimelines.length - 1];
      }
    } else {
      // Void item here
      const blankTimelineItem: TimelineItemVoid = {
        breakpointId: i,
        fullDurationMs: bpFullDuration,
        isInfinite: false,
        showDurationMs: bpFullDuration,
        startTimestampMs: playTimeTrackingMs,
        type: "void",
      };
      playTimeTrackingMs += bpFullDuration;
      bpTimelines = [blankTimelineItem];
      lastItemFromPreviousBp = blankTimelineItem;
    }

    // join each breakpoint final timelines
    // Check and merge the last item from previous breakpoints and the first one for
    // the current breakpoint. Merge if it is the same one.
    finalBpTimelines = joinTimeline(finalBpTimelines, bpTimelines);
  }

  finalBpTimelines = finalBpTimelines.filter((item) => !!item);
  return finalBpTimelines;
};
